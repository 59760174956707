import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import MainViewLink from 'src/apps/common/components/MainViewLink';
import * as browserEvents from 'src/sketch-platform/utils/browserEvents';
import * as DOMUtils from 'src/sketch-platform/utils/DOMUtils';
import routes from 'src/apps/common/routes';

class MyPage extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  menuRef: React.RefObject<HTMLDivElement>;
  constructor(props, context) {
    super(props, context);

    this._handleMenuClick = this._handleMenuClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.menuRef = React.createRef();

    const browserInfo = context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      this.checkMenuOverflow = _.throttle(this.checkMenuOverflow.bind(this), 300, { leading: true, trailing: true });
    }
  }

  componentDidMount() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.addEventListener('resize', this.checkMenuOverflow);
      this.checkMenuOverflow();
    }
  }

  componentWillUnmount() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.removeEventListener('resize', this.checkMenuOverflow);
    }
  }

  checkMenuOverflow() {
    if (this.menuRef.current) {
      const el = this.menuRef.current;
      el.classList.remove('overflow');
      if (DOMUtils.getWindowRect().height < el.offsetTop + el.offsetHeight) {
        el.classList.add('overflow');
      }
    }
  }

  _handleMenuClick(category) {
    if (category) this.sendToGtm(category);
  }

  sendToGtm(category) {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnHeaderMyPageClick(category);
  }

  render() {
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');
    return (
      <div ref={this.menuRef} className="user-menu-cont">
        <ul className="user-overview" role="list">
          {userInfo.status !== 'NON_REGISTERED_MEMBER' ? (
            <>
              <li className="external-link">
                {/*
                 // @ts-expect-error TS2322 */}
                <MainViewLink
                  href="/my_wowow"
                  target="_blank"
                  onClick={() => this._handleMenuClick('ご契約内容の確認')}
                >
                  ご契約内容の確認
                </MainViewLink>
              </li>
              <li>
                {/*
                 // @ts-expect-error TS2322 */}
                <MainViewLink to={routes.settings} onClick={() => this._handleMenuClick('設定')}>
                  設定
                </MainViewLink>
              </li>
              <li>
                {/*
                 // @ts-expect-error TS2322 */}
                <MainViewLink to={routes.deviceManagement} onClick={() => this._handleMenuClick('視聴デバイスの管理')}>
                  視聴デバイスの管理
                </MainViewLink>
              </li>
              <li>
                {/*
                 // @ts-expect-error TS2322 */}
                <MainViewLink to={routes.downloadDevice} onClick={() => this._handleMenuClick('ダウンロードの管理')}>
                  ダウンロードの管理
                </MainViewLink>
              </li>
            </>
          ) : null}
          <li className="external-link">
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink
              href="https://support.wowow.co.jp/"
              target="_blank"
              onClick={() => this._handleMenuClick('ヘルプセンター')}
            >
              ヘルプセンター
            </MainViewLink>
          </li>
          <li>
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink href="/check" onClick={() => this._handleMenuClick('お試し再生')}>
              お試し再生
            </MainViewLink>
          </li>
        </ul>
        {userInfo.status !== 'NON_REGISTERED_MEMBER' ? (
          <ul>
            <li>
              {/*
               // @ts-expect-error TS2322 */}
              <MainViewLink href="/logout" onClick={() => this._handleMenuClick('ログアウト')}>
                ログアウト
              </MainViewLink>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}
export default MyPage;
