import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

class GenericRouteHandler extends Component {
  static get propTypes() {
    return {
      routeHandler: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
      watchPartyApp: PropTypes.object,
      playerApp: PropTypes.object,
      fsApp: PropTypes.object,
      appsFlyerApp: PropTypes.object,
    };
  }

  static getPrefetchPaths = function(models, options, props) {
    let paths = [];
    if (typeof _.get(props, 'model.templateComponent.getPrefetchPaths') === 'function') {
      paths = _.concat(paths, props.model.templateComponent.getPrefetchPaths(models, options, props));
    }
    if (typeof _.get(props, 'model.layoutComponent.getPrefetchPaths') === 'function') {
      paths = _.concat(paths, props.model.layoutComponent.getPrefetchPaths(models, options, props));
    }
    return paths;
  };

  constructor(props, context) {
    super(props, context);
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      routeHandler: this.props.routeHandler,
      // @ts-ignore TS2339
      gtmApp: this.props.gtmApp,
      // @ts-ignore TS2339
      watchPartyApp: this.props.watchPartyApp,
      // @ts-ignore TS2339
      playerApp: this.props.playerApp,
      // @ts-ignore TS2339
      fsApp: this.props.fsApp,
      // @ts-ignore TS2339
      appsFlyerApp: this.props.appsFlyerApp,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (this.props.fsApp) {
      // @ts-ignore TS2339
      this.props.fsApp.init();
    }
    // @ts-ignore TS2339
    if (this.props.appsFlyerApp && !this.props.appsFlyerApp.initialized) {
      // @ts-ignore TS2339
      this.props.appsFlyerApp.init();
    }
    setTimeout(() => {
      // 本来ここではなく各コンポーネントで初期化されるべきだが漏れがあると嫌なので
      // 初期化されていない場合はここでおこなう
      // didmountでやらない場合もあるので、web apiに投げることで回避する。
      // @ts-ignore TS2339
      if (!this.props.gtmApp.initialized) {
        // @ts-ignore TS2339
        this.props.gtmApp.pageView();
      }
    }, 0);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.props.fsApp) {
      // @ts-ignore TS2339
      this.props.fsApp.reset();
    }
  }

  render() {
    // @ts-ignore TS2339
    const { model } = this.props;
    // @ts-expect-error TS2339
    let element = this.props.children;
    if (model.templateComponent) {
      element = React.createElement(
        model.templateComponent,
        Object.assign({}, _.get(this.props, 'model.models.templateProps', {}), this.props),
        element,
      );
    }
    if (model.layoutComponent) {
      element = React.createElement(
        model.layoutComponent,
        Object.assign({}, _.get(this.props, 'model.models.layoutProps', {}), this.props),
        element,
      );
    }
    return element;
  }
}

export default GenericRouteHandler;
