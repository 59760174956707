import React, { Component, createRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import PresTrackedElement from '../../../../common/components/PresTrackedElement';
import PopEventCard from '../PopEventCard';
import Rating from '../../../../common/components/Rating';
import routes from '../../../../common/routes';
import MainViewLink from '../../../../common/components/MainViewLink';
import EpgEvent from './EpgEvent';
import BackgroundImage from '../../../../common/components/BackgroundImage';
import Tags from './Tags';
import Meta from '../Meta';
import MyListButton from '../MyListButton';
import CardContext, { withCardContext } from '../../../../common/context/CardContext';
import SetDate from '../SetDate';

const EventCardSeparate = props => {
  if (props.separate) {
    return <div className="text-wrapper">{props.children}</div>;
  }
  return props.children;
};
const _TextArea = props => {
  const context = useContext(CardContext);
  const item = _.get(props, 'item', {});
  const mbListLayout = _.get(props, 'mbListLayout', {});
  let text;

  if (mbListLayout == 'default') {
    return null;
  }

  // @ts-ignore TS2339
  switch (context.textArea) {
    case 'hidden':
      text = null;
      break;
    case 'header':
      text = item.header;
      break;
    case 'description':
      text = item.shortMessage || item.description;
      break;
    case 'delivery_start_at':
      // @ts-ignore TS2322
      text = item.startAt ? <SetDate date={item.startAt} format={'short'} /> : null;
      break;
  }
  if (
    // @ts-ignore TS2339
    (context.textArea == 'hidden' && mbListLayout == 'box') ||
    // @ts-ignore TS2339
    (context.textArea == 'delivery_start_at' && mbListLayout == 'table')
  ) {
    text = item.shortMessage || item.description;
  }
  if (text) {
    return <div className="synopsis">{text}</div>;
  }
  return null;
};
const TextArea = withCardContext(_TextArea);

class EventCard extends Component<any> {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.eventId) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    EpgEvent.getPaths(models, options, {}).map(_path => {
      path = path.concat(_path);
    });
    return [path];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.eventId) return;
    // @ts-ignore TS2339
    return ['event', props.eventId];
  };

  static get propTypes() {
    return {
      size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']).isRequired,
      sliderItemId: PropTypes.string,
      getRowHasPopOpen: PropTypes.func,
      isDoubleWide: PropTypes.bool,
      itemTabbable: PropTypes.bool,
      model: PropTypes.object.isRequired,
      rankNum: PropTypes.number,
      rowNum: PropTypes.number,
      eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      popType: PropTypes.string.isRequired,
      showSynopsis: PropTypes.bool,
      noisOnAirMode: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      size: '1x1',
      ellipsized: false,
      rankNum: 0,
      rowNum: 0,
      popType: 'none',
      showSynopsis: false,
      noisOnAirMode: false,
    };
  }

  static get contextTypes() {
    return {
      titleType: PropTypes.string,
      textArea: PropTypes.string,
      tips: PropTypes.string,
      getModelData: PropTypes.func,
      history: PropTypes.object,
      listContext: PropTypes.string,
      routeHandler: PropTypes.object,
      rowNum: PropTypes.number,
      artType: PropTypes.string,
      viewType: PropTypes.string,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      // @ts-ignore TS2339
      this.item = props.itemData;
      // @ts-ignore TS2339
      this.item = EpgEvent.additionalData(this.item);
    } else if (rootPath) {
      // @ts-ignore TS2339
      this.item = props.model.getSync(rootPath);
    }
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.titleCardRef = React.createRef();
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    if (!this.props.itemData) this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleMouseOver(e) {
    // タッチデバイスはpopさせない
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) {
      return;
    } else if (this.props.popType === 'none') {
      return;
      // @ts-ignore TS2339
    } else if (this.state.deleteMode) {
      return;
    }

    // @ts-ignore TS2339
    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    if (this.props.getRowHasPopOpen) {
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    // @ts-ignore TS2339
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = false;
    // @ts-ignore TS2339
    this.mouseOverTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
      this.setState({ hasPop: true, displayTitle: false });
    }, timeout);
  }

  handleMouseOut(e) {
    if (this.props.popType === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }

    // @ts-ignore TS2339
    if (!this.state.hasPop) return;

    // @ts-ignore TS2339
    const delay = this.alreadyPopOpened ? 150 : 1;
    if (this.props.onPopLeave) {
      this.props.onPopLeave(this, delay);
    }

    // @ts-ignore TS2339
    this.mouseOutTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      // @ts-ignore TS2339
      this.popUnmounting = true;
      this.setState({ hasPop: false });
    }, delay);
    // @ts-ignore TS2339
    this.displayTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
      this.setState({ displayTitle: true });
    }, 500);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) {
      // TODO: 読み込み中
      return null;
    }

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    const key = `event-card-${this.props.rowNum}-${this.props.rankNum}-${this.props.eventId}`;
    const tabIndex = this.props.itemTabbable ? 0 : -1;

    // @ts-expect-error TS2339
    const canViewPoster = this.context.artType === 'poster';
    const getImage = () => {
      // @ts-ignore TS2339
      if (this.item.thumbnailUrl) {
        // @ts-ignore TS2339
        return this.item.thumbnailUrl;
      }
      // @ts-ignore TS2339
      return this.item.linearChannelMeta.thumbnailUrl;
    };

    const simulcast = _.find(
      // @ts-expect-error TS2339
      this.context.getModelData('simulcast'),
      // @ts-ignore TS2339
      item => item.refId == this.item.linearChannelMeta.refId,
    );

    let titleArtwork = (
      <BackgroundImage
        // @ts-ignore TS2322
        className={classnames('artwork', {
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true,
          'artwork-tall': canViewPoster,
          noImage: !getImage(),
        })}
        url={getImage()}
      />
    );

    let tag = null;
    let cardBadge;
    // @ts-ignore TS2339
    if (this.item.isSuspend) {
      tag = null;
      // @ts-ignore TS2339
    } else if (this.item.isMask) {
      tag = '同時配信 対象外';
      // @ts-ignore TS2339
    } else if (this.item.isFirst) {
      tag = '初回';
    }

    if (tag) {
      cardBadge = <div className="card-badge">{tag}</div>;
    }

    // @ts-ignore TS2339
    let title = this.item.name;

    if (
      // @ts-expect-error TS2339
      this.context.titleType == 'name' ||
      // @ts-expect-error TS2339
      this.context.titleType === 'full_name' ||
      // @ts-expect-error TS2339
      this.context.titleType === 'hidden' ||
      this.props.titleType == 'name' ||
      this.props.titleType === 'full_name' ||
      this.props.titleType === 'hidden'
    ) {
      // @ts-ignore TS2339
      title = this.item.name || this.item.shortName;
      // @ts-ignore TS2339
    } else if (this.item.isSuspend) {
      // @ts-ignore TS2339
      title = this.item.shortName || this.item.name;
    } else {
      // @ts-ignore TS2339
      title = this.item.shortName || this.item.name;
    }

    const getLink = child => {
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        return <span>{child}</span>;
      }
      // @ts-ignore TS2339
      if (this.item.isOnAir) {
        // @ts-ignore TS2339
        if (this.item.linearChannelMeta.metaId) {
          const simulcast = _.find(
            // @ts-expect-error TS2339
            this.context.getModelData('simulcast'),
            // @ts-ignore TS2339
            item => item.refId == this.item.linearChannelMeta.refId,
          );
          return (
            // @ts-expect-error TS2322
            <MainViewLink
              // @ts-ignore TS2322
              to={routes.simulcast}
              params={{ channelName: simulcast.name }}
              tabIndex={-1}
              onClick={this.handleCardClick}
            >
              {child}
            </MainViewLink>
          );
        }
        // @ts-ignore TS2339
      } else if (this.item.uniqueId) {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        return (
          // @ts-expect-error TS2322
          <MainViewLink
            // @ts-ignore TS2322
            to={routes.simulcastDetail}
            // @ts-ignore TS2339
            params={{ channelName: simulcast.name, uniqueId: this.item.uniqueId }}
            tabIndex={-1}
            onClick={this.handleCardClick}
          >
            {child}
          </MainViewLink>
        );
      }
      return <span>{child}</span>;
    };

    const getTimeLabel = () => {
      if (
        // @ts-expect-error TS2339
        this.context.listContext == 'timeline' ||
        // @ts-expect-error TS2339
        this.context.listContext == 'fromnow' ||
        // @ts-expect-error TS2339
        this.context.listContext == 'schedule'
      ) {
        return (
          // @ts-expect-error TS2339
          <div className={classnames('title-card-time', { line: !!this.context.listContext })}>
            {/*
             // @ts-ignore TS2322 */}
            <SetDate date={this.item.startAt} format={'short'} />
          </div>
        );
      }
      return (
        <div className={classnames('title-card-time')}>
          {/*
           // @ts-ignore TS2322 */}
          <SetDate date={this.item.startAt} format={'short'} /> - <SetDate date={this.item.endAt} format={'time'} />
        </div>
      );
    };
    const getTips = () => {
      let channelName, time;
      if (!this.props.channelNameHidden) {
        if (this.props.listType) {
          // @ts-ignore TS2339
          if (this.item.linearChannelMeta.metaId) {
            const simulcast = _.find(
              // @ts-expect-error TS2339
              this.context.getModelData('simulcast'),
              // @ts-ignore TS2339
              item => item.refId == this.item.linearChannelMeta.refId,
            );
            channelName = (
              // @ts-expect-error TS2322
              <MainViewLink
                // @ts-ignore TS2322
                to={routes.simulcast}
                params={{ channelName: simulcast.name }}
                tabIndex={-1}
                className="title-card-chImg"
              >
                <div className={simulcast.name} />
              </MainViewLink>
            );
          }
        } else {
          channelName = (
            // @ts-ignore TS2339
            <div className={classnames('title-card-tips__channel')}>{getLink(this.item.linearChannelMeta.name)}</div>
          );
        }
      }
      // @ts-ignore TS2339
      if ((!!this.context.listContext && this.context.listContext !== 'nowonair') || !this.item.isOnAir) {
        time = (
          // @ts-expect-error TS2339
          <div className={classnames('title-card-tips__time', { line: !!this.context.listContext })}>
            {/*
             // @ts-ignore TS2322 */}
            <SetDate date={this.item.startAt} format={'short'} />
          </div>
        );
      } else {
        time = (
          <div className={classnames('title-card-tips__time')}>
            {/*
             // @ts-ignore TS2322 */}
            <SetDate date={this.item.startAt} format={'short'} /> - <SetDate date={this.item.endAt} format={'time'} />
          </div>
        );
      }
      return (
        <div className="title-card-tips">
          {channelName}
          {time}
        </div>
      );
    };

    let detailNode = null;
    // @ts-ignore TS2339
    if (!_.isEmpty(this.item) && this.state.hasPop && this.props.popType !== 'none') {
      let myListButton;
      if (this.props.showPopCardMylistButton) {
        const myListProps = {
          model: this.props.model,
          btnType: 'short',
          classes: { 'btn-very-small': true },
          btnStyle: false,
        };

        // @ts-ignore TS2339
        myListProps.item = this.item;
        // @ts-ignore TS2322
        myListButton = <MyListButton {...myListProps} onChange={this.handleChangeMylisted} />;
      }

      detailNode = (
        <PopEventCard
          model={this.props.model}
          // @ts-ignore TS2339
          key={`item_${this.item.id}`}
          classes={classnames(this.props.popClasses)}
          popType={this.props.popType}
          // @ts-ignore TS2339
          titleCardImage={this.props.isTallPanel === true ? this.item.tallThumbnailUrl : getImage()}
          // @ts-ignore TS2339
          titleModel={this.item}
          // @ts-ignore TS2322
          artKind={this.context.artKind}
          // @ts-ignore TS2322
          ellipsized={true}
        />
      );
    }

    let titleDisplaySetting = this.props.titleDisplaySetting;
    if (titleDisplaySetting === 'default') {
      titleDisplaySetting = 'under_card';
      // @ts-ignore TS2339
    } else if (this.item && titleDisplaySetting === 'media_only' && this.item.type !== 'media') {
      titleDisplaySetting = 'none';
    }
    const onCard = titleDisplaySetting === 'on_card' || titleDisplaySetting === 'media_only';
    // @ts-ignore TS2322
    let textAreaBox = <TextArea item={this.item} mbListLayout={this.props.mbListLayout} />;

    // mbListLayoutがgridの場合はpalette.titleTypeを見る
    // table,boxはタイトルは必須
    let titleContent = (
      <div className={classnames('title-card-title', { ellipsized: this.props.ellipsized })}>{getLink(title)}</div>
    );
    let titleShow = true;
    if (title && !onCard) {
      if (this.props.mbListLayout == 'grid') {
        // @ts-expect-error TS2339
        if (this.props.titleType == 'hidden' || this.context.titleType == 'hidden' || this.props.titleHidden === true) {
          titleShow = false;
        } else {
          titleShow = true;
        }
      } else {
        if (this.props.mbListLayout == 'table') {
          titleShow = true;
        } else if (
          this.props.titleType !== 'hidden' ||
          // @ts-expect-error TS2339
          this.context.titleType !== 'hidden' ||
          this.props.titleHidden === false
        ) {
          titleShow = true;
        } else if (this.props.viewType == 'list') {
          titleShow = true;
        } else {
          titleShow = false;
        }
      }
      if (titleDisplaySetting == 'none') {
        titleShow = false;
      }
    } else {
      if (this.props.mbListLayout == 'table') {
        titleShow = true;
        // @ts-expect-error TS2339
      } else if (this.props.titleType !== 'hidden' || this.context.titleType !== 'hidden') {
        titleShow = true;
      } else if (this.props.viewType == 'list') {
        titleShow = true;
      } else {
        titleShow = false;
      }
    }

    let cardContent = (
      <React.Fragment>
        <div
          className={classnames('sliderRefocus', {
            // @ts-ignore TS2339
            hasPop: this.state.hasPop === true,
            lockup: true,
            'title-card-tall-panel': this.props.isTallPanel === true,
            'title-card-tall-artwork': canViewPoster,
          })}
          // @ts-ignore TS2339
          aria-label={this.item.name}
          // @ts-ignore TS2339
          ref={this.titleCardRef}
          role={'link'}
          tabIndex={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) ? tabIndex : null
          }
          key={key}
          id={key}
          onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClick : null}
          onKeyDown={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)
              ? this.handleKeyDown
              : null
          }
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
        >
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {!this.props.listType ? cardBadge : null}
            {titleArtwork}
            <div className="video-preload-title">
              {/*
               // @ts-ignore TS2339 */}
              <div className="video-preload-title-label">{this.item.name}</div>
            </div>
          </PresTrackedElement>
          <TransitionGroup component={null}>
            {detailNode ? (
              <CSSTransition
                classNames="pop-bounce"
                // @ts-ignore TS2339
                onEnter={this.onPopOpen}
                // @ts-ignore TS2339
                onExit={this.onPopWillClose}
                // @ts-ignore TS2339
                onExited={this.onPopClose}
                timeout={{ exit: 400, enter: 400 }}
              >
                {detailNode}
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          {onCard && titleShow ? null : onCard && title ? (
            <div className="title-card-title">
              <p>{title}</p>
            </div>
          ) : null}
        </div>
        {/*
         // @ts-expect-error TS2339 */}
        <EventCardSeparate separate={this.context.viewType == 'list' || this.props.viewType == 'list'}>
          {this.props.size === '3x2' || this.props.size === '3x1' ? (
            <div className="title-detail-card">
              <div className="title-detail-card-info">
                {/*
                 // @ts-ignore TS2339 */}
                <div className="title">{this.item.shortName}</div>
                {/*
                 // @ts-ignore TS2339 */}
                <div className="synopsis">{this.item.shortMessage}</div>
              </div>
            </div>
          ) : null}
          {/*
           // @ts-expect-error TS2339 */}
          {this.context.listContext &&
          // @ts-expect-error TS2339
          (this.context.listContext == 'timeline' ||
            // @ts-expect-error TS2339
            this.context.listContext == 'fromnow' ||
            // @ts-expect-error TS2339
            this.context.listContext == 'schedule')
            ? getTimeLabel()
            : null}
          {/*
           // @ts-expect-error TS2339 */}
          {this.props.listType && !this.context.listContext ? getTips() : null}
          {titleShow ? titleContent : null}
          {this.props.listType ? (
            <React.Fragment>
              {/*
               // @ts-ignore TS2339 */}
              <Meta metadata={this.item} />
              {/*
               // @ts-ignore TS2339 */}
              <Tags {...this.item} tagHidden={this.props.tagHidden} />
            </React.Fragment>
          ) : null}

          {/*
           // @ts-ignore TS2339 */}
          {(!_.isEmpty(this.item) && this.props.showSynopsis && this.item.shortMessage) ||
          // @ts-expect-error TS2339
          (this.context.listContext &&
            // @ts-expect-error TS2339
            (this.context.listContext == 'timeline' ||
              // @ts-expect-error TS2339
              this.context.listContext == 'fromnow' ||
              // @ts-expect-error TS2339
              this.context.listContext == 'schedule')) ? (
            // @ts-ignore TS2339
            <div className="synopsis shortMessage">{this.item.shortMessage}</div>
          ) : // @ts-ignore TS2339
          (!_.isEmpty(this.item) && this.props.description && this.item.description) ||
            // @ts-expect-error TS2339
            (this.context.listContext &&
              // @ts-expect-error TS2339
              (this.context.listContext == 'timeline' ||
                // @ts-expect-error TS2339
                this.context.listContext == 'fromnow' ||
                // @ts-expect-error TS2339
                this.context.listContext == 'schedule')) ? (
            // @ts-ignore TS2339
            <div className="synopsis">{this.item.description}</div>
          ) : null}

          {!(
            (
              // @ts-expect-error TS2339
              this.context.listContext == 'timeline' ||
              // @ts-ignore
              this.context.listContext == 'fromnow' ||
              // @ts-expect-error TS2339
              this.context.listContext == 'schedule'
            )
          )
            ? textAreaBox
            : null}
        </EventCardSeparate>
      </React.Fragment>
    );
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      let to, linkProps;

      // @ts-ignore TS2339
      if (this.item) {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        // @ts-ignore TS2339
        if (this.item.isOnAir) {
          // @ts-ignore TS2339
          if (this.item.linearChannelMeta.metaId) {
            return (
              // @ts-expect-error TS2322
              <MainViewLink
                // @ts-ignore TS2322
                to={routes.simulcast}
                params={{ channelName: simulcast.name }}
                role="link"
                aria-label="card"
                onClick={this.handleClick}
                className={classnames('card', 'title-card', 'event-card', `size-${this.props.size}`, {
                  // @ts-expect-error TS2339
                  list: this.context.viewType === 'list',
                  // @ts-expect-error TS2339
                  keyart: this.context.artType === 'keyart',
                  // @ts-expect-error TS2339
                  poster: this.context.artType === 'poster',
                  // @ts-expect-error TS2339
                  square: this.context.artType === 'square',
                  'on-card':
                    onCard &&
                    !(
                      (
                      // @ts-expect-error TS2339
                        (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
                        // @ts-expect-error TS2339
                        (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
                      )
                    ),
                  // @ts-ignore TS2339
                  isOnAir: this.item.isOnAir && !this.props.noisOnAirMode,
                })}
              >
                {cardContent}
              </MainViewLink>
            );
          }
          // @ts-ignore TS2339
        } else if (this.item.uniqueId) {
          return (
            // @ts-expect-error TS2322
            <MainViewLink
              // @ts-ignore TS2322
              to={routes.simulcastDetail}
              // @ts-ignore TS2339
              params={{ channelName: simulcast.name, uniqueId: this.item.uniqueId }}
              role="link"
              aria-label="card"
              onClick={this.handleClick}
              className={classnames('card', 'event-card', 'title-card', `size-${this.props.size}`, {
                // @ts-expect-error TS2339
                list: this.context.viewType === 'list',
                // @ts-expect-error TS2339
                keyart: this.context.artType === 'keyart',
                // @ts-expect-error TS2339
                poster: this.context.artType === 'poster',
                // @ts-expect-error TS2339
                square: this.context.artType === 'square',
                'on-card':
                  onCard &&
                  !(
                    (
                      // @ts-expect-error TS2339
                      (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
                      // @ts-expect-error TS2339
                      (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
                    )
                  ),
                // @ts-ignore TS2339
                isOnAir: this.item.isOnAir && !this.props.noisOnAirMode,
              })}
            >
              {cardContent}
            </MainViewLink>
          );
        }
      }
    } else {
      return (
        <div
          className={classnames('card', 'event-card', 'title-card', `size-${this.props.size}`, {
            nohover: browserInfo.isIOS || browserInfo.isAndroid,
            // @ts-expect-error TS2339
            list: this.context.viewType === 'list',
            // @ts-expect-error TS2339
            keyart: this.context.artType === 'keyart',
            // @ts-expect-error TS2339
            poster: this.context.artType === 'poster',
            // @ts-expect-error TS2339
            square: this.context.artType === 'square',
            'on-card':
              onCard &&
              !(
                (
                  // @ts-expect-error TS2339
                  (this.context.titleType === 'hidden' || this.props.titleType === 'hidden') &&
                  // @ts-expect-error TS2339
                  (this.context.textArea === 'hidden' || this.props.textArea === 'hidden')
                )
              ),
            // @ts-ignore
            isOnAir: this.item.isOnAir && !this.props.noisOnAirMode,
          })}
        >
          {cardContent}
        </div>
      );
    }
  }

  handleClick(e) {
    e.preventDefault();
    // @ts-ignore TS2339
    if (this.item) {
      let to,
        params = {};
      // @ts-ignore TS2339
      if (this.item.isOnAir) {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        to = routes.simulcast;
        params = { channelName: simulcast.name };
      } else {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        to = routes.simulcastDetail;
        // @ts-ignore TS2339
        params = { channelName: simulcast.name, uniqueId: this.item.uniqueId };
      }
      const path = to.makePath(params);
      const routesMatchFlag =
        // @ts-expect-error TS2339
        !!this.context.routeHandlers &&
        // @ts-expect-error TS2339
        _.some(this.context.routeHandlers, handler => handler.route._regex === to._regex);
      if (routesMatchFlag) {
        // @ts-ignore TS2339
        history.push(path);
      } else {
        window.location.href = path;
      }
      // @ts-ignore TS2339
      if (this.props.onCardClick) this.props.onCardClick(this.item);
    }
  }

  handleCardClick() {
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        this.item = EpgEvent.additionalData(this.item);
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }
}

const root = withCardContext(EventCard);
// @ts-ignore TS2339
root.getPaths = EventCard.getPaths;
export default root;
