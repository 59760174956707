import _ from 'src/domain/libs/util';
import activeProfile from '../utils/activeProfile';

export const AF_EVENTS = {
  LOGIN: 'af_login',
  SUBSCRIBE: 'af_subscribe',
  PLAY_START: 'play_start',
};

const streamingType = (content, isLinearChannelMeta, schemaId, isAd) => {
  // refIdが存在しないケースがあるのでどちらも取得でき  ない時だけ設定しない
  if (!_.get(content, 'refId') && !_.get(content, 'name')) return null;
  if (isAd) return null;

  if (isLinearChannelMeta) return '同時配信';
  switch (schemaId) {
    case 3:
      return 'アーカイブ';
    case 4:
      return 'Live';
    default:
      return null;
  }
};

const purchaseType = (content = {}) => {
  if (_.get(content, 'rental') && _.get(content, 'subscription')) {
    return '併用';
  } else if (_.get(content, 'rental')) {
    return 'TVOD';
  } else if (_.get(content, 'subscription')) {
    return 'SVOD';
  }
  return null;
};

class AppsFlyerApp {
  private options: any;
  private profile: any;
  private _initialized: boolean;

  constructor(options = {}) {
    this.options = options;
    this.profile = activeProfile(this.options.models);
    this._initialized = false;
    this.setCustomerUserId = this.setCustomerUserId.bind(this);
  }

  /**
   * Set the customer user ID
   * @param {string} userId - Unique User ID
   */
  setCustomerUserId(userId: string) {
    if (typeof window == 'undefined' || !window.AF || !userId) return;
    if (this.options.browserInfo && this.options.browserInfo.isCarMonitor) return;

    window.AF('pba', 'setCustomerUserId', userId);
  }

  /**
   * Send a custom event to AppsFlyer
   * @param {string} eventName - Name of the event
   * @param {Object} eventValues - Event parameters
   */
  _sendEvent(eventName, eventValues = {}) {
    if (typeof window == 'undefined' || !window.AF) return;
    if (this.options.browserInfo && this.options.browserInfo.isCarMonitor) return;

    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: eventName,
      eventValue: eventValues,
    });
  }

  get memberStatus() {
    switch (_.get(this.profile, 'userStatus')) {
      case 0:
      case 4:
        return '未加入';
      case 1:
        return '無料トライアル';
      case 2:
        if (this.profile.isBs) {
          return 'CASあり配信加入';
        } else {
          return '有料加入';
        }
      case 3:
        return '放送加入';
      default:
        return '未ログイン';
    }
  }

  get initialized() {
    return this._initialized;
  }

  init() {
    if (!_.get(this.options, 'config.analytics.appsFlyer.webDevKey') || this._initialized) return;
    if (this.options.browserInfo && this.options.browserInfo.isCarMonitor) return;
    if (typeof window == 'undefined' || !window.AF) return;

    this._initialized = true;

    this.setCustomerUserId(_.get(this.profile, 'refId', null));
  }

  sendEventOnLogin() {
    this._sendEvent(AF_EVENTS.LOGIN);
  }

  sendEventOnSubscribe(params = {}) {
    //加入成功時 (web加入のみとするためIAPは対象外)
    if (
      !_.get(params, 'subscription') ||
      _.get(params, 'subscription.isAppleIAP') ||
      _.get(params, 'subscription.isAmazonIAP')
    )
      return;
    const eventValues = Object.assign(
      {},
      {
        subscription_id: _.get(params, 'subscription.id', null),
        course_id: _.get(params, 'subscription.course.id', null),
        course_name: _.get(params, 'subscription.course.name', null),
      },
    );
    this._sendEvent(AF_EVENTS.SUBSCRIBE, eventValues);
  }

  sendEventOnContentPage(eventName = null, params = {}) {
    if (!_.includes(AF_EVENTS, eventName)) return;
    const eventValues = Object.assign(
      {},
      {
        relation_program_code: _.get(params, 'relationProgram.refId', null),
        relation_program_name: _.get(params, 'relationProgram.name', null),
        program_code: _.get(params, 'program.refId', null),
        program_name: _.get(params, 'program.name', null),
        content_id: _.get(params, 'content.refId', null),
        content_name: _.get(params, 'content.name', null),
        genre_01: _.get(params, 'genres[0].name', null), // 一つだけでOK
        genre_02: _.get(params, 'middleGenres[0].name', null), // 一つだけでOK
        streaming_type: streamingType(
          _.get(params, 'content'),
          // @ts-ignore TS2339
          !_.isEmpty(params.channel),
          _.get(params, 'schemaId'),
          !!_.get(params, 'ad.id'),
        ),
        member_status: this.memberStatus,
        purchase_type: purchaseType(_.get(params, 'content')),
      },
    );
    this._sendEvent(eventName, eventValues);
  }
}

export default AppsFlyerApp;
