import React, { Component } from 'react';
import PropTypes from 'prop-types';

const PresTrackedElement = ({ imageKey = '', isDoubleWide = false, videoId = 0, ...props }) => {
  return (
    <div className="ptrack-content" data-ui-tracking-context="">
      {props.children}
    </div>
  );
};

PresTrackedElement.propTypes = {
  imageKey: PropTypes.string,
  isDoubleWide: PropTypes.bool,
  videoId: PropTypes.number,
};

PresTrackedElement.contextTypes = {
  rankNum: PropTypes.number,
  rowNum: PropTypes.number,
};

export default PresTrackedElement;
