import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

class ModalContent extends React.PureComponent {
  static get propTypes() {
    return {
      classes: PropTypes.string,
      enableCloseWithOutsidePointerDown: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      enableCloseWithOutsidePointerDown: true,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      opened: false,
    };
    // @ts-ignore TS2339
    this.iframeOuterRef = React.createRef();
    // @ts-ignore TS2339
    this.iframeInnerRef = React.createRef();
    // @ts-ignore TS2339
    this.modalRef = React.createRef();
    // @ts-ignore TS2339
    this.dropRef = React.createRef();
    // @ts-ignore TS2339
    this.modalContentRef = React.createRef();
    this.close = this.close.bind(this);
    this._handleResizeContent = this._handleResizeContent.bind(this);
  }

  componentDidMount() {
    this.open();
    // @ts-ignore TS2339
    this.iframeOuterRef.current.contentWindow.addEventListener('resize', this._handleResizeContent);
    // @ts-ignore TS2339
    this.iframeInnerRef.current.contentWindow.addEventListener('resize', this._handleResizeContent);
    // @ts-ignore TS2554
    this._handleResizeContent();
  }

  _handleResizeContent(ev) {
    // @ts-ignore TS2339
    const modalContent = this.modalContentRef.current;
    if (modalContent.clientHeight) {
      // @ts-ignore TS2339
      const modal = this.modalRef.current;

      // スマホの場合は回転させた時に位置がズレる場合があるので
      // 一度0を指定することで描画のズレを回避する
      // @ts-expect-error TS2339
      const browserInfo = this.context.getModelData('browserInfo');
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        modal.style.left = 0;
      }

      // @ts-ignore TS2339
      const drop = this.dropRef.current;
      const modalWidth = drop.clientWidth;
      const modalHeight = drop.clientHeight;
      const contentWidth = modalContent.clientWidth;
      const contentHeight = modalContent.clientHeight;
      modal.style.top = `${Math.round((modalHeight - contentHeight) / 2)}px`;
      modal.style.left = `${Math.round((modalWidth - contentWidth) / 2)}px`;
    }
  }

  open() {
    this.setState({ opened: true });
  }

  close() {
    // @ts-ignore TS2339
    if (this.props.outsideClose) {
      // @ts-ignore TS2339
      this.props.outsideClose();
    } else {
      // @ts-ignore TS2339
      this.props.onClose();
    }
  }

  render() {
    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        <iframe className="resize-manager" ref={this.iframeOuterRef} />
        <div
          // @ts-ignore TS2339
          className={classnames('modal-overlay-drop', { opened: this.state.opened })}
          // @ts-ignore TS2339
          ref={this.dropRef}
          // @ts-ignore TS2339
          onClick={this.props.enableCloseWithOutsidePointerDown ? this.close : null}
        ></div>
        {/*
         // @ts-ignore TS2339 */}
        <div className={classnames('modal', { opened: this.state.opened }, this.props.classes)} ref={this.modalRef}>
          {/*
           // @ts-ignore TS2339 */}
          <iframe className="resize-manager" ref={this.iframeInnerRef} />
          {/*
           // @ts-ignore TS2339 */}
          <div className="modal-content" ref={this.modalContentRef}>
            {/*
             // @ts-expect-error TS2339 */}
            {React.Children.map(this.props.children, child => {
              // @ts-ignore TS2769
              return React.cloneElement(child, _.omit(this.props, ['children', 'classes']));
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModalContent;
