import React, { Component } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import _ from 'src/domain/libs/util';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PropTypes from 'prop-types';
import window from 'global/window';

import HtmlContext from '../context/HtmlContext';

const defaultMetatags = {
  title: 'WOWOWオンデマンド - 人気番組が楽しめる動画配信サービス',
  shortTitle: 'WOWOWオンデマンドで見る',
  keywords: 'WOWOWオンデマンド，動画，配信，見逃し，ドラマ，バラエティ，映画，アニメ，ライブ，リニア',
  description:
    'これからは、スマホでもWOWOW。いつでもどこでもWOWOWだからできる厳選した映画、見ごたえのあるオリジナルドラマ、ここでしか味わえない音楽ライブや試合中継が楽しめる。',
};

export default function htmlContext(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `htmlContext. Instead received ${JSON.stringify(WrappedComponent)}`,
  );

  class HtmlContextHOC extends Component {
    static getPrefetchPaths = WrappedComponent.getPrefetchPaths;

    static get contextTypes() {
      return {
        getModelData: PropTypes.func,
        getCookieDough: PropTypes.func,
        store: PropTypes.object,
      };
    }

    constructor(props, context) {
      super(props, context);
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
    }

    componentWillReceiveProps(nextProps) {}

    componentDidUpdate(beforeProps) {}

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;
    }

    render() {
      const htmlProps = {
        lang: 'ja',
      };

      // @ts-expect-error TS2339
      const inapp = this.context.getModelData('inapp', 'inapp');
      // @ts-expect-error TS2339
      const ogpInfo = this.context.getModelData('config', 'ogpInfo');
      return (
        <React.Fragment>
          <HelmetProvider>
            <Helmet
              defaultTitle={defaultMetatags.title}
              titleTemplate={inapp ? '%s' : `%s | ${defaultMetatags.shortTitle}`}
              meta={[
                { name: 'keywords', content: defaultMetatags.keywords },
                { name: 'description', content: defaultMetatags.description },

                { property: 'og:type', content: _.get(ogpInfo, 'type.article') },
                { property: 'og:description', content: _.get(ogpInfo, 'description.general') },
                { property: 'og:image', content: _.get(ogpInfo, 'imageUrl.general') },
                { property: 'og:site_name', content: _.get(ogpInfo, 'siteName.general') },
                { property: 'og:title', content: _.get(ogpInfo, 'title.general') },
                { property: 'og:url', content: _.get(window, 'location.href') },
                { property: 'og:locale', content: 'ja_JP' },
                { property: 'fb:app_id', content: '' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:site', content: '@' },
              ]}
            >
              <html {...htmlProps} />
              <body dir="ltr" />
            </Helmet>
          </HelmetProvider>
          <HtmlContext.Provider
            value={{
              title: val => (val ? `${val} | ${defaultMetatags.shortTitle}` : defaultMetatags.shortTitle),
              keywords: val => (val ? `${val},${defaultMetatags.keywords}` : defaultMetatags.keywords),
              description: val => (val ? `${val}${defaultMetatags.description}` : defaultMetatags.description),
            }}
          >
            {React.createElement(WrappedComponent, this.props)}
          </HtmlContext.Provider>
        </React.Fragment>
      );
    }
  }

  // @ts-ignore TS2339
  HtmlContextHOC.displayName = 'HtmlContext';

  return hoistStatics(HtmlContextHOC, WrappedComponent);
}
