import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import window from 'global/window';
import _ from 'src/domain/libs/util';
import url from 'url';

import MainViewLink from '../../MainViewLink';
import HeaderSearchBox from '../common/SearchBox';
import SystemNotifications from '../../SystemNotifications';
import LinearChannelList from '../common/LinearChannelList';
import Attention from './Attention';
import routes, { getHomeRoute } from '../../../routes';
import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../../sketch-platform/utils/DOMUtils';
import { CLICK_AREA, CUSTOM_EVENTS, MENU_CATEGORY } from '../../../../../common/GtmApp';
import GenreTab from './GenreTab';
import FeatureList from './FeatureList';
import LiveList from './LiveList';
import LiveCard from '../../../../bluerose/components/browse/LiveCard';
import activeProfile, { isFree } from '../../../../../utils/activeProfile';
import SecondaryNavigation from './SecondaryNavigation';
import {
  ACTIVE_MENU,
  GlobalMenuGenre,
  InfoData,
  LINEAR_MENU,
  MainMenu,
  MYMENU_MENU,
  OpenMenu,
} from 'src/apps/common/components/GlobalMenu';
import MyPage from '../common/MyPage';
import WodLogo from '../common/WodLogo';

type HeaderProps = {
  isJoinCanvas?: boolean;
  isAccountLayout?: boolean;
  hideLogoLink?: boolean;
  handleClickCallback?: (cb: (e: any) => any) => void;
  globalMenuGenres: GlobalMenuGenre[];
  mainMenus: MainMenu[];
  infoData: InfoData[];
};

type HeaderState = {
  searchBoxFocused: boolean;
  searchInputFocused: boolean;
  openMenu?: OpenMenu;
  headerHovered: boolean;
  [key: string]: any;
};

export default class Header extends Component<HeaderProps, HeaderState> {
  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func.isRequired,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.array,
      gtmApp: PropTypes.object,
    };
  }

  static getPaths = function(models, options, props = {}) {
    const dropdownGenreRootPath = this.getDropdownGenreRootPath(models, options, props);
    const livescheduleRootPath = this.getliveScheduleRootPath(models, options, props);
    let paths = [
      // @ts-ignore TS2339
      ...LiveCard.getPaths(models, options, props).map(path => {
        return livescheduleRootPath.concat([{ from: 0, to: 19 }]).concat(path);
      }),
    ];

    if (!_.isEmpty(dropdownGenreRootPath)) {
      paths = paths.concat([
        dropdownGenreRootPath.concat([{ from: 0, to: 49 }, ['id', 'refId', 'name', 'canvas', 'type']]),
      ]);
    }
    return paths;
  };

  static getDropdownGenreRootPath = function(models, options, props = {}) {
    let generalKey;
    if (models.browserInfo.data.isIOS || models.browserInfo.data.isAndroid) {
      generalKey = _.get(models, 'config.data.attribute_set_keys.menu.sp_dropdown_genre');
    } else {
      generalKey = _.get(models, 'config.data.attribute_set_keys.menu.dropdown_genre');
    }
    if (generalKey) {
      return ['attribute_sets', generalKey];
    }
    return [];
  };

  static getliveScheduleRootPath = function(models, options, props = {}) {
    return ['liveschedule'];
  };

  naviData: any[];
  constructor(props: HeaderProps, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    // @ts-ignore TS2339
    if (this.model) this.model.batch(100);
    this.handleSearchBoxFocus = this.handleSearchBoxFocus.bind(this);
    this.handleSearchInputFocus = this.handleSearchInputFocus.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this.menuClose = this.menuClose.bind(this);
    this.checkMediaQuery = this.checkMediaQuery.bind(this);
    this.handleResize = _.throttle(this.handleResize.bind(this), 300, { leading: true, trailing: true });
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.scrollToActiveMenu = this.scrollToActiveMenu.bind(this);
    this.sumWidth = this.sumWidth.bind(this);
    this.getActiveMenuIndex = this.getActiveMenuIndex.bind(this);
    this.canScroll = this.canScroll.bind(this);
    this.getMenusWidth = this.getMenusWidth.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleHomeGenreScroll = this.handleHomeGenreScroll.bind(this);
    this.updateShowGenresStateOnCurrentRoute = this.updateShowGenresStateOnCurrentRoute.bind(this);

    this.getMenuWrapperStyle = this.getMenuWrapperStyle.bind(this);
    this.getCurrentCanvasIdKey = this.getCurrentCanvasIdKey.bind(this);
    this.handleOpenMenuClick = this.handleOpenMenuClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleMainMenuMouseEnter = this.handleMainMenuMouseEnter.bind(this);
    this.handleMainMenuMouseLeave = this.handleMainMenuMouseLeave.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleDropDownGenreClick = this.handleDropDownGenreClick.bind(this);
    this.handleTabGenreClick = this.handleTabGenreClick.bind(this);
    // @ts-ignore TS2339
    this.rootRef = props.rootRef || React.createRef();
    // @ts-ignore TS2339
    this.genreListRef = React.createRef();
    // @ts-ignore TS2339
    this.featureMenuRef = React.createRef();
    // @ts-ignore TS2339
    this.liveRef = React.createRef();

    this.state = {
      searchBoxFocused: false,
      searchInputFocused: false,
      headerHovered: false,
      toggleOpen: false,
      showSecondaryNavigationLinks: true,
      showHamburgerButton: false,
      showGenres: false,
      hasShadow: false,
      scrollTop: 0,
    };
    // @ts-ignore TS2339
    this._isMounted = false;

    // @ts-ignore TS2339
    const rawDropdownGenreData = this.model.getSync(
      // @ts-ignore TS2339
      this.constructor.getDropdownGenreRootPath(context.models, {}, props),
    );
    if (rawDropdownGenreData) {
      // @ts-ignore TS2339
      this.dropdownGenres = _.values(
        _.omitBy(rawDropdownGenreData, (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
    }
    // @ts-ignore TS2339
    const liveScheduleRootPath = this.constructor.getliveScheduleRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const rawLiveScheduleData = this.model.getSync(liveScheduleRootPath);
    if (rawLiveScheduleData) {
      // @ts-ignore TS2339
      this.liveschedule = _.omitBy(rawLiveScheduleData, (val, key) => {
        return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
      });
    }

    // @ts-ignore TS2339
    this.naviData = [];
    if (props.handleClickCallback) {
      props.handleClickCallback(e => {
        if (
          // @ts-ignore TS2339
          this.state.openMenu &&
          !this.closest(e.target, '.openMenu-wrapper') &&
          !this.closest(e.target, '.open-menu')
        ) {
          this.menuClose();
        }
      });
    }
  }

  closest(node, selector) {
    // IEや一部ブラウザでは.closet()が実装されていないので自前も用意
    return (
      node.closest ||
      function(_selector) {
        do {
          // nodeとselectorがマッチしたら返す
          if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
            return node;
          }
          // マッチしなかったら親要素を代入
          node = node.parentElement || node.parentNode;
        } while (node !== null && node.nodeType === 1);

        return null;
      }
    ).call(node, selector);
  }

  handleSearchBoxFocus(searchBoxFocused) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ searchBoxFocused: searchBoxFocused, toggleOpen: false });
  }

  handleSearchInputFocus(focused) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ searchInputFocused: focused });
  }

  handleMouseEnter(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ headerHovered: true });
  }

  handleMouseLeave(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ headerHovered: false });
  }

  handleMainMenuMouseEnter(openMenu) {
    // @ts-ignore TS2339
    if (this.state.openMenu && this.state.openMenu !== openMenu) return;
    // @ts-ignore TS2339
    if (this.state.openMenu == 'searchKeyword') this.isMouseOnSearch = true;

    // @ts-ignore TS2339
    if (this.showMenuTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.showMenuTimeoutId);
      // @ts-ignore TS2339
      delete this.showMenuTimeoutId;
    }
  }

  handleMainMenuMouseLeave(e) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) return;
    // 検索ボックスはホバーがはずれても入力にフォーカスが当たっていれば消さない
    // @ts-ignore TS2339
    if (this.state.openMenu == 'searchKeyword') {
      // @ts-ignore TS2339
      this.isMouseOnSearch = false;
      // @ts-ignore TS2339
      if (this.state.searchInputFocused) return;
    }

    // @ts-ignore TS2339
    if (!this.showMenuTimeoutId) {
      // @ts-ignore TS2339
      this.showMenuTimeoutId = setTimeout(() => {
        this.setState({ openMenu: '', menuWrapperStyle: {} });
      }, 200);
    }
  }

  handleHistoryChange(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    const body = document.getElementsByTagName('body')[0];
    if ((browserInfo.isIOS || browserInfo.isAndroid) && body.classList.contains('no_scroll')) {
      body.style.top = '';
      body.classList.remove('no_scroll');
      // @ts-ignore TS2339
      window.scrollTo(0, this.state.scrollTop);
    }
    const newState = { toggleOpen: false };
    if (e.pathname != '/search') {
      // @ts-ignore TS2339
      newState.openMenu = '';
      // @ts-ignore TS2339
      newState.menuWrapperStyle = {};
    }
    this.setState(newState);
  }

  handleResize() {
    this.checkMediaQuery();
    this.scrollToActiveMenu();
  }

  handleOrientationChange() {
    this.scrollToActiveMenu();
  }

  handleScroll() {
    //スクロール時々にシャドウを出す
    if (!document) return;
    // @ts-ignore TS2339
    if (!this.props.fixed) return;
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);

    if (scrollTop > 0) {
      // @ts-ignore TS2339
      if (this.state.hasShadow) return;
      this.setState({ hasShadow: true });
    } else {
      // @ts-ignore TS2339
      if (!this.state.hasShadow) return;
      this.setState({ hasShadow: false });
    }
  }
  handleHomeGenreScroll() {
    //スクロール時にホームの場合かつジャンルがコンテンツ部分にあればスクロールしたあとヘッダーに移動する
    if (!document) return;
    let topGenre = document.getElementById('topGenre');
    if (!topGenre) return;

    let topGenreRect = topGenre.getBoundingClientRect();
    let topGenreTop = topGenreRect.top;

    if (topGenreTop < 0) {
      // @ts-ignore TS2339
      if (this.state.showGenres) return;
      this.setState({ showGenres: true });
    } else {
      // @ts-ignore TS2339
      if (!this.state.showGenres) return;
      this.setState({ showGenres: false });
    }
  }

  checkMediaQuery() {
    if (window.matchMedia('screen and (min-width: 1024px)').matches) {
      this.setState({ showSecondaryNavigationLinks: true, showHamburgerButton: false, dropMenu: false });
    } else if (window.matchMedia('screen and (min-width: 768px)').matches) {
      this.setState({
        showSecondaryNavigationLinks: false,
        showHamburgerButton: false,
        dropMenu: false,
      });
    } else {
      this.setState({ showSecondaryNavigationLinks: false, showHamburgerButton: false, dropMenu: true });
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    this.fetchData(this.props);
    // @ts-expect-error TS2339
    this.context.history.listen(this.handleHistoryChange);
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.addEventListener('resize', this.handleResize);
      browserEvents.addEventListener('scroll', this.handleScroll);
      browserEvents.addEventListener('scroll', this.handleHomeGenreScroll);
      this.handleResize();
    } else {
      browserEvents.addEventListener('orientationchange', this.handleOrientationChange);
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (!!this.genreListRef.current) {
      const activeMenuIndex = this.getActiveMenuIndex();
      this.scrollToActiveMenu();
      this.setState({
        // @ts-ignore TS2339
        genreListLength: this.genreListRef.current.children ? this.genreListRef.current.children.length : -1,
        activeMenuIndex,
      });
    }
    this.updateShowGenresStateOnCurrentRoute();
  }

  componentDidUpdate(_, prevState) {
    this.updateShowGenresStateOnCurrentRoute();
    if (!this.canScroll()) return;
    const activeMenuIndex = this.getActiveMenuIndex();
    // @ts-ignore TS2339
    const genreListLength = this.genreListRef.current.children.length;
    if (
      (prevState.genreListLength !== genreListLength || prevState.activeMenuIndex != activeMenuIndex) &&
      // @ts-ignore TS2339
      !!this.genreListRef.current
    ) {
      // メニューにないページに遷移した場合はスクロールしないが、stateの更新は行う
      if (activeMenuIndex > -1) this.scrollToActiveMenu();
      this.setState({ activeMenuIndex, genreListLength });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.removeEventListener('resize', this.handleResize);
    } else {
      browserEvents.removeEventListener('orientationchange', this.handleOrientationChange);
    }
  }

  menuClose() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    this.setState({ openMenu: '', headerHovered: false, menuWrapperStyle: {} });

    const body = document.getElementsByTagName('body')[0];
    if ((browserInfo.isIOS || browserInfo.isAndroid) && body.classList.contains('no_scroll')) {
      body.style.top = '';
      body.classList.remove('no_scroll');
      // @ts-ignore TS2339
      window.scrollTo(0, this.state.scrollTop);
    }
  }

  updateShowGenresStateOnCurrentRoute() {
    // @ts-expect-error TS2339
    const { routeHandler } = this.context;
    if (routeHandler.path === '/') {
      // @ts-ignore TS2554
      const profile = activeProfile(this.context.models);
      // @ts-ignore TS2339
      if (profile && !isFree(profile) && !this.state.showGenres) {
        this.setState({ showGenres: true });
      }
    } else {
      // @ts-ignore TS2339
      if (this.state.showGenres) {
        this.setState({ showGenres: false });
      }
    }
  }
  handleFocus(openMenu) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (browserInfo.isIOS || browserInfo.isAndroid || this.state.openMenu == openMenu) return null;
    const targetClassName = openMenu + '-open';
    const target = document.getElementsByClassName(targetClassName)[0];

    // @ts-ignore TS2339
    if (openMenu !== this.state.openMenu) {
      // @ts-ignore TS2339
      if (this.showMenuTimeoutId) {
        // @ts-ignore TS2339
        clearTimeout(this.showMenuTimeoutId);
        // @ts-ignore TS2339
        delete this.showMenuTimeoutId;
      }
      if (target) {
        // @ts-ignore TS2339
        this.openMenuElement = target;
        this.setState(_.assign({ openMenu: openMenu }, this.getMenuWrapperStyle(openMenu)));
      } else {
        this.setState({ openMenu: openMenu });
      }
    } else {
      this.setState({ openMenu: '', menuWrapperStyle: {} });
    }
  }
  handleOpenMenuClick(openMenu = {}) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const { history } = this.context;
    let to;
    let params = {};
    let query = {};
    if (
      // @ts-ignore TS2339
      openMenu.name === 'linear' &&
      // @ts-ignore TS2339
      openMenu.refId &&
      !browserInfo.isIOS &&
      !browserInfo.isAndroid &&
      !browserInfo.isRequestDesktopWebsite
    ) {
      //放送同時配信（PC時のみ）
      // @ts-ignore TS2339
      const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == openMenu.refId);
      to = routes.simulcast;
      params = { channelName: simulcast.name };
      const path = to.makePath(params, query);
      const routesMatchFlag =
        // @ts-expect-error TS2339
        !!this.context.routeHandlers &&
        // @ts-expect-error TS2339
        _.some(this.context.routeHandlers, handler => handler.route._regex === to._regex);
      if (routesMatchFlag) {
        history.push(path);
      } else {
        window.location.href = path;
      }
    } else {
      // @ts-ignore TS2339
      const targetClassName = openMenu.name + '-open';
      const target = document.getElementsByClassName(targetClassName)[0];
      const body = document.getElementsByTagName('body')[0];
      let scrollTop = window.scrollY;
      // @ts-ignore TS2339
      if (this.state.scrollTop !== scrollTop) {
        this.setState({ scrollTop: scrollTop });
      }
      // @ts-ignore TS2339
      if (openMenu.name !== this.state.openMenu) {
        // @ts-ignore TS2339
        if (this.showMenuTimeoutId) {
          // @ts-ignore TS2339
          clearTimeout(this.showMenuTimeoutId);
          // @ts-ignore TS2339
          delete this.showMenuTimeoutId;
        }
        if (target) {
          // @ts-ignore TS2339
          this.openMenuElement = target;
          // @ts-ignore TS2339
          this.setState(_.assign({ openMenu: openMenu.name }, this.getMenuWrapperStyle(openMenu.name)));
        } else {
          // @ts-ignore TS2339
          this.setState({ openMenu: openMenu.name });
        }
        if ((browserInfo.isIOS || browserInfo.isAndroid) && !body.classList.contains('no_scroll')) {
          body.style.top = -scrollTop + 'px';
          body.classList.add('no_scroll');
        }
      } else {
        this.setState({ openMenu: '', menuWrapperStyle: {} });
        if ((browserInfo.isIOS || browserInfo.isAndroid) && body.classList.contains('no_scroll')) {
          body.style.top = '';
          body.classList.remove('no_scroll');
          // @ts-ignore TS2339
          window.scrollTo(0, this.state.scrollTop);
        }
      }
    }
  }

  scrollToActiveMenu() {
    if (typeof window === 'undefined') return;
    const activeMenuIndex = this.getActiveMenuIndex();
    if (activeMenuIndex < 0) return;
    const windowWidth = DOMUtils.getWindowRect().width;
    // @ts-ignore TS2339
    const { width, left } = DOMUtils.getRect(this.genreListRef.current.children[activeMenuIndex]);
    // @ts-ignore TS2339
    this.genreListRef.current.scrollLeft = this.genreListRef.current.scrollLeft + left - windowWidth / 2 + width / 2;
  }

  getMenusWidth() {
    // @ts-ignore TS2339
    return _.map(this.genreListRef.current.children, child => {
      const { width } = DOMUtils.getRect(child);
      const { right, left } = this.getElementMargin(child);
      return width + right + left;
    });
  }

  getElementMargin(element) {
    const styles = window.getComputedStyle(element);
    return ['right', 'left'].reduce(
      (obj, key) => {
        return {
          ...obj,
          [key]: parseFloat(styles.getPropertyValue(`margin-${key}`)) || 0,
        };
      },
      { right: 0, left: 0 },
    );
  }

  sumWidth(widthList) {
    return _.reduce(widthList, (sum, width) => {
      return sum + width;
    });
  }

  getActiveMenuIndex() {
    // @ts-ignore TS2339
    if (!this.genreListRef.current) return -1;

    const activeMenuIndexes = [];
    // @ts-ignore TS2339
    _.forEach(this.genreListRef.current.children, (child, idx) => {
      const actives = child.getElementsByClassName(ACTIVE_MENU);
      if (actives.length > 0) activeMenuIndexes.push(idx);
    });

    // 放送同時配信やマイページのプルダウンが開かれている場合にもactive classがつくがそちらはみない
    // (ここをセンタリングするとホバー時に対象がセンタリングし、ホバーが外れて意図せず閉じる場合があるため)
    // @ts-ignore TS2339
    if (this.state.openMenu) {
      let linearMenuIndex = -1;
      let myMenuIndex = -1;
      // @ts-ignore TS2339
      for (let i = 0; i < this.genreListRef.current.children.length; i++) {
        // @ts-ignore TS2339
        const child = this.genreListRef.current.children[i];
        if (child.getElementsByClassName(LINEAR_MENU).length > 0) linearMenuIndex = i;
        else if (child.getElementsByClassName(MYMENU_MENU).length > 0) myMenuIndex = i;
        if (linearMenuIndex > -1 && myMenuIndex > -1) break;
      }
      const target = _.find(activeMenuIndexes, index => ![linearMenuIndex, myMenuIndex].includes(index));
      return target || -1;
    }
    return _.isEmpty(activeMenuIndexes) ? -1 : _.first(activeMenuIndexes);
  }

  canScroll() {
    // @ts-ignore TS2339
    if (!this.genreListRef.current) return false;
    const windowWidth = DOMUtils.getWindowRect().width;
    const menusWidth = this.getMenusWidth();
    const menuWidthTotal = this.sumWidth(menusWidth);

    return windowWidth < menuWidthTotal;
  }

  getMenuWrapperStyle(openMenu) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    const attentionArea = document.querySelector('.attention-area');
    let attentionAreaH;
    if (attentionArea) {
      // @ts-ignore TS2339
      attentionAreaH = attentionArea.style.paddingTop;
    }

    if (openMenu == 'linear' || openMenu == 'feature' || openMenu == 'live') {
      return { menuWrapperStyle: { marginTop: attentionAreaH } };
      // @ts-ignore TS2339
    } else if (this.state.dropMenu || this.context.spMode || browserInfo.isIOS || browserInfo.isAndroid) {
      if (attentionAreaH) {
        return { menuWrapperStyle: { right: 0, left: 0, marginTop: attentionAreaH } };
      } else {
        return { menuWrapperStyle: { right: 0, left: 0 } };
      }
      // @ts-ignore TS2339
    } else if (this.state.headerDropMenu) {
      if (attentionAreaH) {
        return { menuWrapperStyle: { right: 0, marginTop: attentionAreaH } };
      } else {
        return { menuWrapperStyle: { right: 0 } };
      }
    } else {
      // @ts-ignore TS2339
      if (!this.openMenuElement) return { menuWrapperStyle: {} };
      // @ts-ignore TS2339
      let left = DOMUtils.findPosition(this.openMenuElement).left;
      // @ts-ignore TS2339
      let width = DOMUtils.getRect(this.openMenuElement).width;
      let windowW = document.body.clientWidth;
      let right = windowW - (left + width);

      if (openMenu == 'searchKeyword') {
        if (attentionAreaH) {
          // @ts-ignore TS2339
          if (!this.state.showSecondaryNavigationLinks) {
            return { menuWrapperStyle: { right: 0, left: 0, marginTop: attentionAreaH } };
          } else {
            return { menuWrapperStyle: { right: right, marginTop: attentionAreaH } };
          }
        } else {
          // @ts-ignore TS2339
          if (!this.state.showSecondaryNavigationLinks) {
            return { menuWrapperStyle: { right: 0, left: 0 } };
          } else {
            return { menuWrapperStyle: { right: right } };
          }
        }
      } else {
        if (attentionAreaH) {
          return { menuWrapperStyle: { left: left, marginTop: attentionAreaH } };
        } else {
          return { menuWrapperStyle: { left: left } };
        }
      }
    }
  }

  getCurrentCanvasIdKey() {
    // @ts-expect-error TS2339
    const currentPageComponent = _.last(this.context.routeHandler.components);
    // @ts-ignore TS2339
    if (currentPageComponent && typeof currentPageComponent.getCanvasId === 'function') {
      // @ts-ignore TS2339
      return currentPageComponent.getCanvasId(
        // @ts-expect-error TS2339
        this.context.models,
        {},
        // @ts-expect-error TS2339
        { routeHandler: this.context.routeHandler, ...this.context.routeHandler.params },
      );
    }
    return null;
  }

  handleMenuClick(menuCategory) {
    return () => {
      // @ts-expect-error TS2339
      if (this.context.gtmApp) {
        if (Object.keys(MENU_CATEGORY).some(key => MENU_CATEGORY[key] === menuCategory)) {
          // @ts-expect-error TS2339
          this.context.gtmApp.pushDataLayerOnMenuClick(menuCategory);
        }
      }
    };
  }

  handleDropDownGenreClick(genreName) {
    return () => {
      // @ts-expect-error TS2339
      if (this.context.gtmApp) {
        // @ts-expect-error TS2339
        this.context.gtmApp.pushDataLayerOnMenuGenreClick(CLICK_AREA.MENU_GENRE.HEADER_MENU, genreName);
      }
    };
  }

  handleTabGenreClick(genreName) {
    return () => {
      // @ts-expect-error TS2339
      if (this.context.gtmApp) {
        // @ts-expect-error TS2339
        this.context.gtmApp.pushDataLayerOnMenuGenreClick(CLICK_AREA.MENU_GENRE.OVER_MUST_HEAD, genreName);
      }
    };
  }

  render() {
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');
    // @ts-expect-error TS2339
    const inapp = this.context.getModelData('inapp', 'inapp');
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    //ヘッダードロップダウンのジャンル
    const headerGenreList = [];
    // @ts-ignore TS2339
    if (this.dropdownGenres) {
      // @ts-ignore TS2339
      _.forEach(this.dropdownGenres, (dropdownGenre, index) => {
        if (!dropdownGenre.id) return;
        let headerGenreProps = {
          params: { id: dropdownGenre.id },
        };
        switch (dropdownGenre.type) {
          case 'genre':
            if (dropdownGenre.refId) {
              headerGenreProps = {
                // @ts-ignore TS2322
                to: routes.genreRef,
                params: { id: dropdownGenre.refId },
              };
            } else {
              // @ts-ignore TS2339
              headerGenreProps.to = routes.genre;
            }
            break;
          case 'person':
            // @ts-ignore TS2339
            headerGenreProps.to = routes.person;
            break;
          case 'league':
            // @ts-ignore TS2339
            headerGenreProps.to = routes.league;
            break;
          case 'team':
            // @ts-ignore TS2339
            headerGenreProps.to = routes.team;
            break;
          default:
            // @ts-ignore TS2339
            headerGenreProps.to = routes.attr;
        }

        headerGenreList.push(
          <li key={`header-genre-list-${headerGenreList.length}`}>
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink
              {...headerGenreProps}
              // @ts-ignore TS2322
              onClick={this.handleDropDownGenreClick(dropdownGenre.name)}
              key={`genre-${dropdownGenre.id}`}
            >
              {dropdownGenre.name}
            </MainViewLink>
          </li>,
        );
      });
    }
    //ヘッダーのジャンルチェック
    let headerGenreSelection;
    if (headerGenreList.length > 0) {
      headerGenreSelection = (
        // @ts-ignore TS2339
        <div ref={this.headerGenreRef} className="headerGenre-menu-cont">
          <ul className="headerGenre-overview" role="list">
            {headerGenreList}
          </ul>
        </div>
      );
    }

    const mainMenuLinks = [];
    const mainMenuList = _.clone(this.props.mainMenus);
    _.forEach(mainMenuList, link => {
      if (link.name === 'live') {
        // @ts-ignore TS2339
        if (this.liveschedule && this.liveschedule.length > 0) {
          link.dropdown = true;
          // @ts-ignore TS2339
          link.items = this.liveschedule;
        } else {
          link.dropdown = false;
        }
      } else if (link.name === 'headerGenreMenu') {
        link.hidden = !headerGenreSelection;
      }
    });

    _.forEach(mainMenuList, item => {
      if (_.get(item, 'hidden')) return null;
      if (!item.dropdown) {
        const urlObj = url.parse(item.url);
        // @ts-expect-error TS2339
        const isActive = this.context.routeHandler.path === urlObj.path;
        mainMenuLinks.push(
          <div
            // @ts-ignore TS2339
            key={`main-menu-element-${item.name || item.id}`}
            className={classnames('main-menu-element', { [ACTIVE_MENU]: isActive })}
          >
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink
              // @ts-ignore TS2322
              className={classnames({ [ACTIVE_MENU]: isActive })}
              href={item.url}
              params={{}}
              onClick={this.handleMenuClick(item.title)}
            >
              {item.title}
            </MainViewLink>
          </div>,
        );
      } else {
        if (userInfo.status == 'NON_REGISTERED_MEMBER' && item.name == 'myMenu') return null;
        let isActive = false;
        if (item.name === 'live') {
          // @ts-expect-error TS2339
          isActive = this.context.routeHandler.route._regex === routes.liveSchedule._regex;
        }
        mainMenuLinks.push(
          <div key={`main-menu-element-${item.name}`} className={classnames('main-menu-element', item.name)}>
            <div
              className={item.dropdownMenuName}
              onMouseEnter={e => {
                this.handleMainMenuMouseEnter(item.name);
              }}
              onMouseLeave={this.handleMainMenuMouseLeave}
            >
              {item.url ? (
                // @ts-expect-error TS2322
                <MainViewLink
                  // @ts-ignore TS2322
                  href={item.url}
                  params={{}}
                  className={classnames(`open-menu ${item.name}-open`, { [ACTIVE_MENU]: isActive })}
                  onClick={this.handleMenuClick(item.title)}
                  onFocus={() => {
                    // @ts-ignore TS2339
                    !(browserInfo.isIOS || browserInfo.isAndroid || this.state.openMenu == item.name)
                      ? this.handleFocus(item.name)
                      : null;
                  }}
                  onMouseEnter={() => {
                    // @ts-ignore TS2339
                    !(browserInfo.isIOS || browserInfo.isAndroid || this.state.openMenu == item.name)
                      ? this.handleFocus(item.name)
                      : null;
                  }}
                  onBlur={() => {
                    !(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleMainMenuMouseLeave : null;
                  }} // windowsで先にメニューが閉じられてしまう対策
                >
                  {item.title}
                </MainViewLink>
              ) : (
                <span
                  className={classnames(`open-menu ${item.name}-open`)}
                  tabIndex={0}
                  onFocus={() => {
                    // @ts-ignore TS2339
                    !(browserInfo.isIOS || browserInfo.isAndroid || this.state.openMenu == item.name)
                      ? this.handleFocus(item.name)
                      : null;
                  }}
                  onMouseEnter={() => {
                    // @ts-ignore TS2339
                    !(browserInfo.isIOS || browserInfo.isAndroid || this.state.openMenu == item.name)
                      ? this.handleFocus(item.name)
                      : null;
                  }}
                  onBlur={() => {
                    !(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleMainMenuMouseLeave : null;
                  }} // windowsで先にメニューが閉じられてしまう対策
                  onClick={e => {
                    // @ts-ignore TS2554
                    this.handleMenuClick(item.title)(e);
                    this.handleOpenMenuClick(item);
                  }}
                >
                  {item.title}
                </span>
              )}
              {/*
               // @ts-ignore TS2339 */}
              {this.state.openMenu == item.name ? (
                // @ts-ignore TS2339
                <div className="openMenu-wrapper" style={this.state.menuWrapperStyle}>
                  {item.name == 'linear' ? (
                    <div className="linear-openMenu" key={'linear-openMenu'}>
                      {/*
                       // @ts-ignore TS2339 */}
                      <LinearChannelList model={this.model} />
                    </div>
                  ) : item.name == 'headerGenreMenu' ? (
                    headerGenreSelection
                  ) : item.name == 'myMenu' ? (
                    <MyPage />
                  ) : item.name == 'feature' ? (
                    // @ts-ignore TS2339
                    <div ref={this.featureMenuRef} className="feature-menu-cont">
                      <FeatureList
                        // @ts-ignore TS2339
                        model={this.model}
                        // @ts-ignore TS2322
                        items={item.palette.objects}
                        url={item.url}
                        // @ts-ignore TS2339
                        palette={item.palette}
                      />
                    </div>
                  ) : item.name == 'live' ? (
                    // @ts-ignore TS2339
                    <div ref={this.liveRef} className="live-menu-cont">
                      {/*
                       // @ts-ignore TS2339 */}
                      <LiveList model={this.model} items={item.items} url={item.url} />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>,
        );
      }
    });

    // @ts-ignore TS2339
    let showMainMenuGenreLinks = !this.state.showSecondaryNavigationLinks;
    if (browserInfo.isIOS || browserInfo.isAndroid) showMainMenuGenreLinks = true;

    const genreList = [],
      navList = [];
    let showGenres = false,
      homeShowGenres = false;

    // @ts-expect-error TS2339
    if (this.context.routeHandler.path === '/') {
      showGenres = false;

      // @ts-ignore TS2339
      if (this.state.showGenres) {
        showGenres = true;
        homeShowGenres = true;
      }
    }
    if (mainMenuLinks.length > 0) {
      _.forEach(mainMenuLinks, (link, i) => {
        navList.push(link);
      });
    }
    // @ts-expect-error TS2339
    const shouldCheckIsActive = _.keys(this.context.routeHandler.query || {}).length > 0;
    _.forEach(this.props.globalMenuGenres, (genre, index) => {
      let props = {
        params: { id: genre.id },
      };
      switch (genre.type) {
        case 'genre':
          if (genre.refId) {
            props = {
              // @ts-ignore TS2322
              to: routes.genreRef,
              params: { id: genre.refId },
            };
          } else {
            // @ts-ignore TS2339
            props.to = routes.genre;
          }
          break;
        case 'person':
          // @ts-ignore TS2339
          props.to = routes.person;
          break;
        case 'league':
          // @ts-ignore TS2339
          props.to = routes.league;
          break;
        case 'team':
          // @ts-ignore TS2339
          props.to = routes.team;
          break;
        default:
          // @ts-ignore TS2339
          props.to = routes.attr;
      }
      // @ts-ignore TS2339
      if (shouldCheckIsActive && props.to.makePath(props.params) === this.context.routeHandler.path)
        // @ts-ignore TS2339
        props.className = ACTIVE_MENU;
      // @ts-ignore TS2339
      if (props.to.makePath(props.params) === this.context.routeHandler.path) {
        showGenres = true;
      }
      genreList.push(
        // @ts-expect-error TS2322
        <MainViewLink
          {...props}
          // @ts-ignore TS2322
          className={classnames({
            // @ts-ignore TS2339
            [ACTIVE_MENU]: props.to.makePath(props.params) === this.context.routeHandler.url,
          })}
          onClick={this.handleTabGenreClick(genre.name)}
          key={`genre-${genre.id}`}
        >
          {genre.name}
        </MainViewLink>,
      );
    });

    let genreComponent =
      // @ts-ignore TS2339
      this.props.hideNavigation !== true ? (
        // @ts-ignore TS2339
        <div className="genre-list" ref={this.genreListRef}>
          <GenreTab
            // @ts-ignore TS2322
            center={true}
            genreLinks={genreList}
            navLinks={navList}
            // @ts-ignore TS2339
            showSecondaryNavigationLinks={this.state.showSecondaryNavigationLinks}
            // @ts-ignore TS2339
            ref={this.genreListRef}
            // @ts-ignore TS2339
            showGenres={showGenres}
          />
        </div>
      ) : null;

    return (
      <header
        className={classnames('header', {
          // @ts-ignore TS2339
          fixed: !!this.props.fixed, //固定ヘッダー
          // @ts-ignore TS2339
          'bg-transparent': !!this.props.transparent, //透過ヘッダー
          // @ts-ignore TS2339
          'user-active': this.state.headerHovered, //ヘッダーにホバーした場合
          inapp: inapp, //アプリでのwebview時に消す際のclass
          // @ts-ignore TS2339
          pinned: this.props.pinned,
          'genre-header':
            // @ts-ignore TS2339
            this.props.hideNavigation === true &&
            // @ts-ignore TS2339
            this.props.hideAccountLinks !== false &&
            // @ts-ignore TS2339
            this.props.hideAuthLinks !== false
              ? false
              : // @ts-ignore TS2339
              !!genreComponent && (!!showGenres || !!this.state.showGenres || showMainMenuGenreLinks)
              ? true
              : false, //ジャンルある場合に高さ分出すためのclass
          // @ts-ignore TS2339
          'signup-header': this.props.isSignupLayout, //加入動線のヘッダー
          searchResultHeader:
            (browserInfo.isIOS || browserInfo.isAndroid) &&
            // @ts-expect-error TS2339
            this.context.routeHandler.route._regex === routes.search._regex,
          homeShowGenres: homeShowGenres, //ホームの時にジャンル表示した際のアニメーション
          // @ts-ignore TS2339
          hasShadow: this.state.hasShadow, //スクロールした際の影表示
          // @ts-expect-error TS2339
          homeHeader: this.context.routeHandler.path === '/', //ホームの場合transition入れるため
        })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        // @ts-ignore TS2339
        ref={this.rootRef}
      >
        <div className="header-container">
          {/*
             // @ts-ignore TS2322 */}
          <Attention noticeData={this.noticeData} />
          {inapp ? (
            <div className="main-header">
              {/*
                 // @ts-ignore TS2322 */}
              <SystemNotifications model={this.model} />
            </div>
          ) : (
            <div
              className={classnames('main-header', {
                // @ts-ignore TS2339
                'search-focused': this.state.searchBoxFocused,
              })}
            >
              <WodLogo
                isAccountLayout={this.props.isAccountLayout}
                hideLogoLink={this.props.hideLogoLink}
                isJoinCanvas={this.props.isJoinCanvas}
              />
              {/*
                   // @ts-ignore TS2339 */}
              {this.props.hideNavigation === true &&
              // @ts-ignore TS2339
              this.props.hideAccountLinks !== false &&
              // @ts-ignore TS2339
              this.props.hideAuthLinks !== false ? null : (
                <SecondaryNavigation
                  key="secondary-nav"
                  // @ts-ignore TS2322
                  term={this.props.term}
                  // @ts-ignore TS2339
                  showSecondaryNavigationLinks={this.state.showSecondaryNavigationLinks}
                  // @ts-ignore TS2339
                  hideProfileLinks={this.props.hideProfileLinks}
                  // @ts-ignore TS2339
                  hideNavigation={this.props.hideNavigation}
                  // @ts-ignore TS2339
                  hideAuthLinks={this.props.hideAuthLinks}
                  // @ts-ignore TS2339
                  hideAccountLinks={this.props.hideAccountLinks}
                  // @ts-ignore TS2339
                  pathEvaluator={this.props.pathEvaluator}
                  // @ts-ignore TS2339
                  model={this.model}
                  handleSearchBoxFocus={this.handleSearchBoxFocus}
                  handleSearchInputFocus={this.handleSearchInputFocus}
                  // @ts-ignore TS2339
                  enableIncrementalSearch={this.props.enableIncrementalSearch}
                  // @ts-ignore TS2339
                  showHamburgerButton={this.state.showHamburgerButton}
                  // @ts-ignore TS2339
                  open={this.state.toggleOpen}
                  // @ts-ignore TS2339
                  openMenu={this.state.openMenu}
                  // @ts-ignore TS2339
                  menuWrapperStyle={this.state.menuWrapperStyle}
                  infoData={this.props.infoData}
                  mainMenuLinks={showMainMenuGenreLinks ? null : mainMenuLinks}
                  handleMenuClick={this.handleOpenMenuClick}
                  handleMouseEnter={this.handleMainMenuMouseEnter}
                  handleMouseLeave={this.handleMainMenuMouseLeave}
                />
              )}
              {/*
                   // @ts-ignore TS2322 */}
              <SystemNotifications model={this.model} />
              {(browserInfo.isIOS || browserInfo.isAndroid) &&
              // @ts-expect-error TS2339
              this.context.routeHandler.route._regex === routes.search._regex ? (
                <HeaderSearchBox
                  // @ts-ignore TS2322
                  model={this.props.model}
                  // @ts-ignore TS2339
                  pathEvaluator={this.props.pathEvaluator}
                  handleSearchBoxFocus={this.handleSearchBoxFocus}
                  // @ts-ignore TS2339
                  searchBoxFocused={this.state.searchBoxFocused}
                  // @ts-ignore TS2339
                  enableIncrementalSearch={this.props.enableIncrementalSearch}
                  // @ts-ignore TS2339
                  term={this.props.term}
                  // @ts-ignore TS2339
                  ref={this.searchKeywordMenuRef}
                />
              ) : null}
            </div>
          )}
          {/*
             // @ts-ignore TS2339 */}
          {this.props.hideNavigation === true &&
          // @ts-ignore TS2339
          this.props.hideAccountLinks !== false &&
          // @ts-ignore TS2339
          this.props.hideAuthLinks !== false
            ? null
            : genreComponent}
        </div>
      </header>
    );
  }

  async fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    // @ts-ignore TS2339
    const dropdownGenreRootPath = this.constructor.getDropdownGenreRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const liveScheduleRootPath = this.constructor.getliveScheduleRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.dropdownGenres = _.clone(
          _.values(
            _.omitBy(_.get(res.json, dropdownGenreRootPath), (val, key) => {
              return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
            }),
          ),
        );
        // @ts-ignore TS2339
        this.liveschedule = _.clone(
          _.values(
            _.omitBy(_.get(res.json, liveScheduleRootPath), (val, key) => {
              return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
            }),
          ),
        );
        const newState = {
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}
