import React, { Component } from 'react';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import window from 'global/window';
import Route from '../../../../sketch-platform/ui/routing/Route';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';

const FadeLeft = props => {
  const classNames = classnames('fade-left', {
    scrolling: !props.isLeftEdge && props.canScroll,
  });
  return (
    <div onClick={props.onClick} className={classNames}>
      <i className="fa fa-angle_left"></i>
    </div>
  );
};

const FadeRight = props => {
  const classNames = classnames('fade-right', {
    scrolling: !props.isRightEdge && props.canScroll,
  });
  return (
    <div onClick={props.onClick} className={classNames}>
      <i className="fa fa-angle_right"></i>
    </div>
  );
};

const SLIDE_SPEED = 750;

export default class GalleryTab extends Component {
  static get propTypes() {
    return {
      links: PropTypes.arrayOf(PropTypes.element).isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.galleryTabNaviRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.state = {
      isLeftEdge: true,
      isRightEdge: false,
      canScroll: false,
      animating: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.addEventListener('resize', this.handleResize);
    }

    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.removeEventListener('resize', this.handleResize);
    }
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
  }

  componentDidUpdate(prevProps) {
    // @ts-ignore TS2339
    const { width } = DOMUtils.getRect(this.galleryTabNaviRef.current);
    // @ts-ignore TS2339
    this.offsetLeft = width;
    // @ts-ignore TS2339
    if (this.props.activeTabIndex != prevProps.activeTabIndex) this.scrollToActiveTab();
  }

  handleScroll(e) {
    // @ts-ignore TS2339
    const maxScrollLeft = (e.target.scrollWidth || 0) - this.offsetLeft;
    this.setState({
      isLeftEdge: 0 > e.target.scrollLeft - 5,
      isRightEdge: maxScrollLeft < e.target.scrollLeft + 5,
    });
  }

  handleOrientationChange(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  handleResize(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  getTabsWidth() {
    // @ts-ignore TS2339
    return _.map(this.props.links, (_, i) => {
      // @ts-ignore TS2339
      const { width } = DOMUtils.getRect(this.galleryTabNaviRef.current.children[i]);
      return width;
    });
  }

  canScroll(windowWidth) {
    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
      if (document) {
        if (windowWidth > document.body.clientWidth) {
          let scrollbarWidth = windowWidth - document.body.clientWidth;
          windowWidth = windowWidth - scrollbarWidth;
        }
      }
    }
    const tabsWidth = this.getTabsWidth();
    const tabWidthTotal = this.sumWidth(tabsWidth);

    return windowWidth < tabWidthTotal;
  }

  sumWidth(widthList) {
    return _.reduce(widthList, (sum, width) => {
      return sum + width;
    });
  }

  scrollToActiveTab(windowWidth) {
    if (typeof window === 'undefined') return;

    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
    }
    const tabsWidth = this.getTabsWidth();
    const tabWidthTotal = this.sumWidth(tabsWidth);

    // @ts-ignore TS2339
    if (this.props.center) {
      // @ts-ignore TS2339
      const { width, left } = DOMUtils.getRect(this.galleryTabNaviRef.current.children[this.props.activeTabIndex]);
      // @ts-ignore TS2339
      this.galleryTabNaviRef.current.scrollLeft =
        // @ts-ignore TS2339
        this.galleryTabNaviRef.current.scrollLeft + left - windowWidth / 2 + width / 2;
      return;
    }

    // @ts-ignore TS2339
    if (0 < this.props.activeTabIndex && windowWidth < tabWidthTotal) {
      const tabWidthToLeftOfActiveTabs = _.transform(tabsWidth, (result, width, i) => {
        // @ts-ignore TS2339
        result.push(Math.round(width));
        // @ts-ignore TS2339
        return this.props.activeTabIndex == i;
      });
      // @ts-ignore TS2339
      this.galleryTabNaviRef.current.scrollLeft = this.sumWidth(tabWidthToLeftOfActiveTabs);
    }
  }

  handleButtonPress(type) {
    // @ts-ignore TS2339
    this.onPress = true;
    this.doScroll(type == 'left' ? -10 : 10);
  }

  handleButtonRelease(type) {
    // @ts-ignore TS2339
    this.onPress = false;
  }

  /**日数分 or 7日分の少ない方をスクロール */
  handleButtonClick(type) {
    // @ts-ignore TS2339
    if (this.state.animating) return;
    /**表示されている日付の一番左 */
    let leftEdgeIdx;
    /**表示されている日付の一番右 */
    let rightEdgeIdx;
    // @ts-ignore TS2339
    const children = this.galleryTabNaviRef.current.children;
    // @ts-ignore TS2339
    const parentRect = this.galleryTabNaviRef.current.getBoundingClientRect();
    for (let i = 0; i < children.length; i++) {
      if (children[i].getBoundingClientRect().left > parentRect.left) {
        if (leftEdgeIdx == undefined) leftEdgeIdx = i;
        if (children[i].getBoundingClientRect().right > parentRect.right) {
          rightEdgeIdx = i - 1;
          break;
        }
      }
    }
    const tabsWidth = this.getTabsWidth();
    /**表示されている日数 */
    const displayDate = (rightEdgeIdx || tabsWidth.length - 1) - leftEdgeIdx + 1;
    const BASIC_SCROll_DATE = 7;
    // @ts-ignore TS2339
    const currentScrollLeft = this.galleryTabNaviRef.current.scrollLeft;
    // @ts-ignore TS2339
    const maxScrollLeft = (this.galleryTabNaviRef.current.scrollWidth || 0) - this.offsetLeft;
    /**スクロールする日数 */
    const scrollDate = displayDate > BASIC_SCROll_DATE ? BASIC_SCROll_DATE : displayDate;
    let scrollWidth = 0;
    // それぞれの端のidxの次の数字からn日数分のwidthスクロールする
    for (let i = 0; i < scrollDate; i++) {
      if (type == 'left' && leftEdgeIdx - i - 1 >= 0) {
        scrollWidth += tabsWidth[leftEdgeIdx - i - 1];
      } else if (type == 'right' && rightEdgeIdx + i + 1 < tabsWidth.length) {
        scrollWidth += tabsWidth[rightEdgeIdx + i + 1];
      } else {
        scrollWidth = type == 'left' ? currentScrollLeft : maxScrollLeft - currentScrollLeft;
        break;
      }
    }
    // 絶妙なスクロール位置によってはスクロールされ過ぎてしまう場合があるので
    if (type == 'left') {
      scrollWidth = currentScrollLeft > scrollWidth ? scrollWidth : currentScrollLeft;
    } else {
      scrollWidth = maxScrollLeft - currentScrollLeft > scrollWidth ? scrollWidth : maxScrollLeft - currentScrollLeft;
    }
    // @ts-ignore TS2339
    this.scrollWidth = type == 'left' ? scrollWidth : -scrollWidth;

    let callback;
    this.setState({ animating: true });

    callback = () => {
      // @ts-ignore TS2339
      this.scrollWidth = 0;
      // @ts-ignore TS2339
      this.galleryTabNaviRef.current.scrollLeft += type == 'left' ? -scrollWidth : scrollWidth;
      this.setState({ animating: false });
      // @ts-ignore TS2339
      delete this.animatingEndCallback;
    };
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
    // @ts-ignore TS2339
    this.animatingEndCallback = setTimeout(callback, SLIDE_SPEED);
  }

  doScroll(step) {
    const loop = () => {
      // @ts-ignore TS2339
      this.galleryTabNaviRef.current.scrollLeft = this.galleryTabNaviRef.current.scrollLeft + step;
      // @ts-ignore TS2339
      if (this.onPress) requestAnimationFrame(loop);
    };
    requestAnimationFrame(loop);
  }

  render() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    let sliderContentStyle = {};
    // @ts-ignore TS2339
    if (this.state.animating) {
      let sliderContentTransform = '';
      // @ts-ignore TS2339
      if (this.scrollWidth !== 0) {
        // @ts-ignore TS2339
        sliderContentTransform = `translate(${this.scrollWidth}px, 0px)`;
      }
      sliderContentStyle = {
        MsTransform: sliderContentTransform,
        WebkitTransform: sliderContentTransform,
        transform: sliderContentTransform,
        WebkitTransition: `-webkit-transform ${SLIDE_SPEED}ms ease 0s`,
        OTransition: `-o-transform ${SLIDE_SPEED}ms ease 0s`,
        MozTransition: `transform ${SLIDE_SPEED}ms ease 0s, -moz-transform 0.75s ease 0s`,
        transition: `transform ${SLIDE_SPEED}ms ease 0s, -webkit-transform 0.75s ease 0s, -moz-transform 0.75s ease 0s, -o-transform 0.75s ease 0s`,
        pointerEvents: 'none',
      };
    }

    let tabContent = (
      <React.Fragment>
        {browserInfo.isIOS || browserInfo.isAndroid ? null : (
          <React.Fragment>
            <FadeLeft
              // @ts-ignore TS2339
              isLeftEdge={this.state.isLeftEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('left')}
            />
            <FadeRight
              // @ts-ignore TS2339
              isRightEdge={this.state.isRightEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('right')}
            />
          </React.Fragment>
        )}
        <ul
          // @ts-ignore TS2339
          ref={this.galleryTabNaviRef}
          // @ts-ignore TS2339
          className={classnames('gallery-tab-navi', { 'on-animating': this.state.animating })}
          onScroll={this.handleScroll}
        >
          {/*
           // @ts-ignore TS2339 */}
          {_.map(this.props.links, (linkComponent, i) => {
            return (
              <li
                key={`gallery-tab_${i}`}
                className={classnames({
                  // @ts-ignore TS2339
                  active: this.props.activeTabIndex == i,
                })}
                style={sliderContentStyle}
              >
                {linkComponent}
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );

    // @ts-ignore TS2339
    if (this.props.label) {
      return (
        <div className="gallery-tab-label">
          <div className="gallery-tab-label__label">
            {/*
             // @ts-ignore TS2339 */}
            <span>{this.props.label}</span>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.unit}
          </div>
          <div className="gallery-tab-wrapper">{tabContent}</div>
        </div>
      );
    } else {
      return <div className="gallery-tab-wrapper">{tabContent}</div>;
    }
  }
}
