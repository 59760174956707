import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NotFound extends Component {
  static bundleName = 'errors/NotFound';

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  render() {
    // @ts-expect-error TS2339
    const inapp = this.context.getModelData('inapp', 'inapp');

    return (
      <div className="panel">
        <div className="other-container">
          <h1>アクセスしようとしたページは見つかりません。</h1>
          <div className="not_img no" />
          <p className="introduction-text end-sentence">
            指定されたページは、URLが変更になったか、公開期間が終了するなどして削除されたため表示できません。
            <br />
            また、URLが間違っている可能性もございますので、URLを入力された場合は再度ご確認ください。
          </p>
          {inapp ? null : (
            <a href="/" className="btn btn-gray btn-small">
              トップページに戻る
            </a>
          )}
        </div>
      </div>
    );
  }
}
