import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import classnames from 'classnames';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import MyListButton from './MyListButton';
import ShareButton from './ShareButton';
import BackgroundImage from '../../../common/components/BackgroundImage';
import canAccessBySlug from '../../utils/slug';

import MetaTags from '../player/MetaTags';
import Meta from './Meta';
import SetDate from './SetDate';
import BoxCardBadge from './BoxCardBadge';
import { isDisplayCommunicationArea } from 'src/domain/utils/communicationArea';

export default class BoxCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      rootPath = rootPath.concat(['meta', props.titleId]);
    }
    return [
      rootPath.concat([
        ['id', 'name', 'thumbnailUrl', 'mainVisualType', 'mylisted', 'canMyList', 'type', 'slug', 'schemaId'],
      ]),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.titleId) return;
    // @ts-ignore TS2339
    return ['meta', props.titleId];
  };

  static get propTypes() {
    return {
      titleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      model: PropTypes.object.isRequired,
      onCardClick: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      listContext: PropTypes.string,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.titleLinkRef = React.createRef();

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      this.item = props.itemData;
    } else if (rootPath) {
      this.item = props.model.getSync(rootPath);
    }
    this._handleClick = this._handleClick.bind(this);
    this._handlePointerOver = this._handlePointerOver.bind(this);
    this._handlePointerOut = this._handlePointerOut.bind(this);
    this._handleCardClick = this._handleCardClick.bind(this);

    this.startSlider = this.startSlider.bind(this);
    this.stopSlider = this.stopSlider.bind(this);
    this.onClickPlay = this.onClickPlay.bind(this);
    this.onPlayerVolumeChange = this.onPlayerVolumeChange.bind(this);

    this.state = {
      spMode: false,
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.enterTimerID = null;
    // @ts-ignore TS2339
    this.leaveTimerID = null;
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.checkMediaQuery();
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  set item(item) {
    // @ts-ignore TS2551
    this.__item = item;
    if (_.has(item, 'advertisingId')) {
      // leadEpisode、edgeEpisodeを引く場合
      if (
        item.schemaId === 1 &&
        item.seriesMeta &&
        item.seriesMeta.metaId &&
        ((item.pageTransitionType === 'latest_episode' && !item.edgeEpisode) ||
          (item.pageTransitionType === 'lead_episode' && !item.leadEpisode))
      ) {
        this.fetchAdvertisingSeriesMetaData(this.props);
      }
    }
  }

  get item() {
    // @ts-ignore TS2551
    return this.__item;
  }

  checkMediaQuery() {
    let spMode = false;
    if (window.matchMedia('screen and (min-width: 500px)').matches) {
      spMode = false;
    } else {
      spMode = true;
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ spMode });
    // @ts-ignore TS2339
    else this.state.spMode = spMode;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        if (res) {
          if (!this.item) this.item = {};
          Object.assign(this.item, _.omit(_.get(res.json, rootPath, {}), ['$__path']));
        } else {
          delete this.item;
        }
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  fetchAdvertisingSeriesMetaData(props) {
    const rootPath = ['meta', this.item.seriesMeta.metaId];
    const paths = [rootPath.concat([['leadEpisode', 'edgeEpisode']])];
    const evaluator = props.model.fetch(paths);

    // @ts-ignore TS2339
    if (this._isMounted) this.state.dispose = evaluator.dispose;
    else this.state = Object.assign({ dispose: evaluator.dispose });
    evaluator
      .then(res => {
        if (res) Object.assign(this.item.seriesMeta, _.omit(_.get(res.json, rootPath, {}), ['$__path']));
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  _handleClick() {
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.click();
  }

  _handlePointerOver(e) {
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.classList.add('hover');
  }

  _handlePointerOut(e) {
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.classList.remove('hover');
  }

  startSlider(props = this.props) {
    // @ts-ignore TS2339
    if (props.itemTabbable && props.onAutoplay) props.onAutoplay(true);
  }

  stopSlider(props = this.props) {
    // @ts-ignore TS2339
    if (props.itemTabbable && props.onAutoplay) props.onAutoplay(false);
  }

  onClickPlay(isPlay) {
    if (isPlay) {
      this.stopSlider();
    } else {
      this.startSlider();
    }
  }

  onPlayerVolumeChange(volume, muted) {
    // @ts-ignore TS2339
    if (this.props.onMuted) this.props.onMuted(muted);
  }

  _handleCardClick() {
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  render() {
    let linkParams;
    let billboardUrl = '';
    if (!_.isEmpty(this.item)) {
      linkParams = { id: this.item.id };
      if (canAccessBySlug(this.item)) {
        linkParams.id = this.item.slug || this.item.id;
      }

      if (this.item.thumbnailUrl) {
        billboardUrl = this.item.thumbnailUrl;
        billboardUrl = billboardUrl.replace('[width]', '1400').replace('[height]', '800');
      }
    }

    let linkProps = {
      to: this.item.type === 'media' || this.item.type === 'linear_channel' ? routes.watchNow : routes.title,
      params: linkParams,
    };
    if (this.item.refId) {
      linkProps = {
        to: this.item.type === 'media' ? routes.content : routes.program,
        params: { id: this.item.refId },
      };
      if (this.item.type === 'linear_channel') {
        // @ts-expect-error TS2339
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
        linkProps = {
          to: routes.simulcast,
          params: { channelName: simulcast.name },
        };
      }
    }
    // 一旦落ちないように
    // TODO: 番組詳細へ遷移、onairはwatchに遷移
    if (this.item.uniqueId) {
      linkProps = {
        to: routes.watchNow,
        params: { id: this.item.linearChannelMeta.metaId },
      };
    }

    let seasonTitle =
      this.item.seasonMeta && this.item.seasonMeta.shortName
        ? this.item.seasonMeta.shortName
        : this.item.seasonMeta && this.item.seasonMeta.name
        ? this.item.seasonMeta.name
        : null;
    let seriesTitle =
      this.item.seriesMeta && this.item.seriesMeta.shortName
        ? this.item.seriesMeta.shortName
        : this.item.seriesMeta && this.item.seriesMeta.name
        ? this.item.seriesMeta.name
        : null;
    let title = this.item.shortName || this.item.name;
    let description =
      this.item.cardInfo && this.item.cardInfo.description
        ? this.item.cardInfo.description
        : this.item.header
        ? this.item.header
        : null;
    let deliveryStartDate = null;

    // 広告
    if (_.has(this.item, 'advertisingId')) {
      //上書き => パレット側で上書きされている || creativeにurlがある
      billboardUrl = this.item.thumbnailUrl || _.get(this.item, 'creatives[0].url');
      seasonTitle = null;
      title = this.item.shortName || this.item.name;
      linkProps = {
        // @ts-ignore TS2322
        href: this.item.url,
      };
      // シリーズ
      if (this.item.schemaId === 1) {
        const seriesMeta = this.item.seriesMeta;
        const episodeMeta = this.item.episodeMeta;
        // 遷移先
        description = this.item.cardInfo && this.item.cardInfo.description ? this.item.cardInfo.description : null;
        switch (this.item.pageTransitionType) {
          case 'series':
            if (seriesMeta) {
              if (!billboardUrl) billboardUrl = seriesMeta.thumbnailUrl;
              linkProps = {
                to: routes.title,
                params: { id: seriesMeta.metaId },
              };
              if (seriesMeta.refId) {
                linkProps = {
                  to: routes.program,
                  params: { id: seriesMeta.refId },
                };
              }
            }
            break;
          case 'selected_episode':
            if (episodeMeta) {
              if (!billboardUrl) billboardUrl = episodeMeta.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: episodeMeta.metaId },
              };
              if (episodeMeta.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: episodeMeta.refId },
                };
              }
            }
            break;
          case 'latest_episode':
            const edgeEpisode = seriesMeta.edgeEpisode;
            if (edgeEpisode) {
              if (!billboardUrl) billboardUrl = edgeEpisode.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: edgeEpisode.id },
              };
              if (edgeEpisode.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: edgeEpisode.refId },
                };
              }
            }
            break;
          case 'lead_episode':
            const leadEpisode = seriesMeta.leadEpisode;
            if (leadEpisode) {
              if (!billboardUrl) billboardUrl = leadEpisode.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: leadEpisode.id },
              };
              if (leadEpisode.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: leadEpisode.refId },
                };
              }
            }
            break;
          default:
            // @ts-ignore TS2322
            linkProps = { href: this.item.url };
            break;
        }
      }
      // ライブ
      else if (this.item.schemaId === 2 && this.item.liveMeta) {
        if (this.item.deliveryStartDate) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartDate} format={'short'} txt={'〜'} />;
        }
        if (!billboardUrl) billboardUrl = this.item.liveMeta.thumbnailUrl;
        linkProps = {
          to: routes.watchNow,
          params: { id: this.item.liveMeta.metaId },
        };
        if (this.item.liveMeta.refId) {
          linkProps = {
            to: routes.content,
            params: { id: this.item.liveMeta.refId },
          };
        }
      }
      // リニアチャンネル
      else if (this.item.schemaId === 3 && this.item.linearChannelMeta) {
        description = this.item.cardInfo && this.item.cardInfo.description ? this.item.cardInfo.description : null;

        if (this.item.deliveryStartDate) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartDate} format={'short'} txt={'〜'} />;
        }
        if (!billboardUrl) billboardUrl = this.item.linearChannelMeta.thumbnailUrl;
        linkProps = {
          to: routes.watchNow,
          params: { id: this.item.linearChannelMeta.metaId },
        };
        if (this.item.linearChannelMeta.refId) {
          const simulcast = _.find(
            // @ts-expect-error TS2339
            this.context.getModelData('simulcast'),
            item => item.refId == this.item.linearChannelMeta.refId,
          );
          linkProps = {
            to: routes.simulcast,
            params: { channelName: simulcast.name },
          };
        }
      }
      // パレット
      else if (this.item.schemaId == 4) {
        description = this.item.cardInfo && this.item.cardInfo.description ? this.item.cardInfo.description : null;
        // @ts-ignore TS2322
        linkProps = { href: this.item.url };
      }
    } else {
      // ライブ
      if (this.item.schemaId === 4) {
        if (this.item.deliveryStartAt) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartAt} format={'short'} txt={'〜'} />;
        }
      }
      //リニアチャンネル
      else if (!!this.item.uniqueId) {
        let channelName;
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        if (this.item.linearChannelMeta && this.item.linearChannelMeta.name) {
          if (simulcast) channelName = _.lowerCase(simulcast.name);
          seasonTitle = <img src={`/images/logo_${channelName}_white.svg`} className="channel-logo" />;
        }
        if (this.item.startAt) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.startAt} format={'short'} txt={'〜'} />;
        }
        title = this.item.name || this.item.title;
        description = this.item.shortMessage || this.item.description;

        linkProps = {
          to: routes.simulcastDetail,
          params: { channelName: simulcast.name, uniqueId: this.item.uniqueId },
        };
      }
    }

    let rankingBadge, rankNum;
    // @ts-ignore TS2339
    if (this.context.listContext === 'ranking' && this.props.rankNum < 50) {
      // @ts-ignore TS2339
      rankNum = this.props.rankNum + 1;
      rankingBadge = <div className="ranking-badge">{rankNum}.</div>;
    }
    let seasonTitleDiv;
    if (seasonTitle && title != seasonTitle) {
      seasonTitleDiv = <div className="season-title">{seasonTitle}</div>;
    } else if (seriesTitle && title != seriesTitle) {
      seasonTitleDiv = <div className="season-title">{seriesTitle}</div>;
    }
    return (
      <div className="box-card">
        {this.item ? (
          <div className="box-card-box">
            <div className="box-card-box__title">
              <div
                className={classnames('box-card-box__title-box', {
                  'ranking clearfix': rankingBadge,
                  hasSeasonTitle: seasonTitleDiv,
                  double: rankNum >= 10,
                })}
              >
                {rankingBadge}
                {seasonTitleDiv ? seasonTitleDiv : null}
                {title ? <h3 className="title">{title}</h3> : null}
              </div>
            </div>
            {deliveryStartDate ? <div className="delivery-date">{deliveryStartDate}</div> : null}
            <div className="badge-action-box">
              <div className="badge-box">
                <Meta metadata={this.item} />
                <BoxCardBadge item={this.item} hiddenGenre={false} mbListLayout={'box'} isOnArt={false} />
                <div className="badge-action-box__separate"></div>
              </div>
              <div className="action-box">
                <MyListButton
                  item={this.item}
                  // @ts-ignore TS2339
                  model={this.props.model}
                  btnType={'short'}
                  btnStyle={false}
                  // @ts-ignore TS2339
                  id={this.item.id}
                />
                {/* <ShareButton metadata={this.item} position="right" /> */}
              </div>
            </div>
            {this.item.communicationArea &&
            // @ts-ignore TS2339
            isDisplayCommunicationArea(
              // @ts-ignore TS2339
              this.context.models,
              this.item?.cAreaPublicationTargetUsers,
              this.item?.cAreaPublicationStartAt,
              this.item?.cAreaPublicationEndAt,
            ) ? (
              <div className="communication-area">
                <span>{this.item.communicationArea}</span>
              </div>
            ) : null}
            {description ? <div className="description">{description}</div> : null}
          </div>
        ) : null}
        <div className="box-card-link">
          {/*
           // @ts-ignore TS2339 */}
          <div ref={this.titleLinkRef} className={classnames('billboard-link', { rankingGra: rankingBadge })}>
            <div className="video-preload-title">
              {title ? <div className="video-preload-title-label">{title}</div> : null}
            </div>
            <BackgroundImage
              // @ts-ignore TS2322
              className={classnames('artwork box-card-thumbnail', { noImage: !billboardUrl })}
              url={billboardUrl}
            />
          </div>
        </div>
        <MainViewLink
          onClick={this._handleCardClick}
          // @ts-ignore TS2339
          ref={this.titleLinkRef}
          className="box-card-link-bg"
          {...linkProps}
          // @ts-ignore TS2322
          tabIndex={1}
        ></MainViewLink>
      </div>
    );
  }
}
