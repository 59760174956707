import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import _ from '@domain/libs/util';
import { CardSize } from '@domain/models/palette';
import GalleryContent from './GalleryContent';
import LoadingGallery from '../loader/LoadingGallery';
import HtmlContext from '../../../common/context/HtmlContext';
import { CardContextProvider } from '../../../common/context/CardContext';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import routes from '../../../common/routes';
import LiveCard from './LiveCard';
import AdvertisingCard from './AdvertisingCard';
import { parseUrl } from '../../../../sketch-platform/ui/routing';
import MainViewLink from '../../../common/components/MainViewLink';

export default class PaletteContent extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];
    paths = paths.concat([
      rootPath.concat([
        [
          'id',
          'name',
          'title',
          'description',
          'listContext',
          'mbListLayout',
          'meta',
          'cardTitleDisplaySetting',
          'badgeOnList',
          'popCard',
          'artKind',
          'artType',
          'cardSize',
          'artTypeOnList',
          'titleType',
          'textArea',
          'tips',
          'elementId',
          'genres',
          'middleGenres',
          'defaultContents',
          'keyartUrl',
          'idKey',
          'schemaId',
        ],
      ]),
    ]);
    if (props.palette) {
      const pathProps = {};
      const palette = props.palette;
      const routeHandler = props.routeHandler;
      if (palette.meta) {
        // @ts-ignore TS2339
        pathProps.id = palette.meta;
        // @ts-ignore TS2339
        pathProps.sortOrder = palette.sortOrder || 'po';
        if (palette.listContext === 'relateds') {
          // @ts-ignore TS2339
          pathProps.keyPrefix = 'meta';
          // @ts-ignore TS2339
          pathProps.modelRoot = palette.listContext;
        } else {
          // @ts-ignore TS2339
          pathProps.keyPrefix = palette.listContext;
        }
      } else if (palette.listContext === 'recommended') {
        // @ts-ignore TS2339
        pathProps.keyPrefix = palette.listContext;
        // @ts-ignore TS2339
        pathProps.modelRoot = palette.elementId;
        // @ts-ignore TS2339
        pathProps.genres = palette.genres || -1;
        // @ts-ignore TS2339
        pathProps.id = null;
        // @ts-ignore TS2339
        pathProps.defaultContents = _.get(routeHandler, 'query.dc', palette.defaultContents) || -1;
      } else {
        // @ts-ignore TS2339
        pathProps.id = props.id;
        // @ts-ignore TS2339
        pathProps.keyPrefix = 'palette';
        // @ts-ignore TS2339
        pathProps.modelRoot = 'objects';
      }
      const galleryPaths = GalleryContent.getPaths(
        models,
        options,
        Object.assign({}, GalleryContent.defaultProps, props, pathProps),
      );
      paths = paths.concat(galleryPaths);
    }
    return paths;
  };

  static getRootPath = function(models, options, props) {
    return ['palette', props.id];
  };

  static getPrefetchPaths = function(models, options, props) {
    if (props.showSpaOnly) {
      return [];
    }
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const palette = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      const pathProps = {
        ...props,
        palette,
      };
      return this.getPaths(models, options, pathProps);
    };
  };

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isPrefetchingPaths: PropTypes.bool,
      routeHandler: PropTypes.object,
      pathEvaluator: PropTypes.object,
      title: PropTypes.string,
      description: PropTypes.string,
      headerVisuable: PropTypes.bool,
      useHelmet: PropTypes.bool,
      deliveryStartVisible: PropTypes.bool,
      shouldSkipGtm: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      columnsInRow: PropTypes.number,
      popType: PropTypes.string,
      gtmApp: PropTypes.object,
      cardSize: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      headerVisuable: true,
      showSpaOnly: false,
      useHelmet: true,
      shouldSkipGtm: false,
    };
  }

  static get childContextTypes() {
    return {
      isTallRow: PropTypes.bool,
      titleType: PropTypes.string,
      textArea: PropTypes.string,
      tips: PropTypes.string,
      viewType: PropTypes.string,
      popType: PropTypes.string,
      showBadge: PropTypes.bool,
      artType: PropTypes.string,
      artKind: PropTypes.string,
      cardSize: PropTypes.string,
      listContext: PropTypes.string,
      deleteApp: PropTypes.object,
    };
  }

  getChildContext() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this.palette) {
      return {
        // @ts-ignore TS2339
        titleType: this.palette.titleType || 'name',
        // @ts-ignore TS2339
        textArea: !!this.props.textArea
          ? // @ts-ignore TS2339
            this.props.textArea
          : // @ts-ignore TS2339
          this.palette.textArea
          ? // @ts-ignore TS2339
            this.palette.textArea
          : 'hidden',
        tips:
          // @ts-ignore TS2339
          (browserInfo.isIOS || browserInfo.isAndroid) && this.palette.mbListLayout == 'table'
            ? 'all'
            : // @ts-ignore TS2339
              this.palette.tips || 'hidden',
        // @ts-ignore TS2339
        viewType: (browserInfo.isIOS || browserInfo.isAndroid) && this.props.liveSchedule ? 'list' : 'grid',
        // @ts-ignore TS2339
        popType: this.palette.popCard !== false ? this.context.popType : 'none',
        // @ts-ignore TS2339
        showBadge: this.palette.badgeOnList !== false,
        artType: this.getArtType(),
        cardSize: this.cardSize,
        // @ts-ignore TS2339
        artKind: this.palette.artKind || 'default',
      };
    } else {
      return {};
    }
  }

  // @ts-ignore TS2339
  getArtType(palette = this.palette) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!palette) return 'default';

    let artType = palette.artTypeOnList || 'same_with_canvas';
    if (artType === 'same_with_canvas') artType = palette.artType || 'default';
    if (!(browserInfo.isIOS || browserInfo.isAndroid) && artType === 'keyart') {
      artType = 'default';
    }
    if (
      (browserInfo.isIOS || browserInfo.isAndroid) &&
      palette.mbListLayout == 'table' &&
      (palette.artType == 'keyart' || palette.artType == 'poster')
    ) {
      artType = 'default';
    }
    return artType;
  }

  constructor(props, context) {
    super(props, context);
    this.sendToGtm = this.sendToGtm.bind(this);

    let model = props.pathEvaluator || props.model.pathEvaluator;
    // @ts-ignore TS2339
    this.model = model ? model.batch(100) : props.model;
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.palette = this.model.getSync(rootPath) || {};
    // @ts-ignore TS2339
    this.items = _.values(this.model.getSync(rootPath.concat(['objects']))) || [];
    this.state = {
      dispose: null,
      fetchDataError: null,
      // @ts-ignore TS2339
      generation: this.model.getVersion(rootPath),
      filter: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (!this.props.showSpaOnly && (!this.palette || Object.keys(this.palette).length === 0)) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }

    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this.sendToGtm(_.get(this.palette, 'title'));
    this.fetchData(this.props, this.context);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }
    // @ts-ignore TS2339
    if (this.__$promise && this.__$promise.dispose) {
      // @ts-ignore TS2339
      this.__$promise.dispose();
    }
  }

  set items(items) {
    // @ts-ignore TS2551
    this.__items = items;
    this.overwritePalette();
  }

  get items() {
    // @ts-ignore TS2551
    return this.__items;
  }

  get ignoreIds() {
    // @ts-ignore TS2339
    const ignoreIds = this.props.ignoreIds || [];
    if (this.isFirstAdToPaletteDetail()) {
      ignoreIds.push(this.items[0].id);
    }
    return ignoreIds;
  }

  get cardSize() {
    // @ts-expect-error TS2339
    return this.context.cardSize || 'medium';
  }

  overwritePalette() {
    if (this.isFirstAdToPaletteDetail()) {
      const firstCard = this.items[0];
      // そのパレットにタイトルが登録されていないときはその広告のタイトルをパレットのタイトル表示部分に代わりに利用する
      // @ts-ignore TS2339
      if (!this.palette.title && firstCard.name) {
        // @ts-ignore TS2339
        this.palette.title = firstCard.name;
      }
      // そのパレットに説明が登録されていないときはその広告の説明をパレットの説明表示部分に代わりに利用する
      // @ts-ignore TS2339
      if (!this.palette.description && _.get(firstCard, 'cardInfo.description')) {
        // @ts-ignore TS2339
        this.palette.description = firstCard.cardInfo.description;
      }
    }
  }

  // 先頭に広告があって、その広告のURLがパレットの詳細を示している場合
  isFirstAdToPaletteDetail() {
    // @ts-ignore TS2339
    if (_.get(this.items, '0.advertisingId') && routes[this.palette.listContext]) {
      const firstCard = this.items[0];
      // @ts-ignore TS2339
      const linkProps = AdvertisingCard.getLinkProps(firstCard);
      let url = linkProps.href ? linkProps.href : linkProps.to.makePath(linkProps.params);
      // @ts-expect-error TS2339
      const host = _.get(this.context.getModelData('hosts'), 'host');
      url = MainViewLink.replaceUrl(url, host);
      const { path } = parseUrl(url);
      // @ts-ignore TS2339
      return path === routes[this.palette.listContext].makePath({ id: this.palette.id });
    }
    return false;
  }

  sendToGtm(title) {
    if (!_.get(this.context, 'gtmApp') || _.get(this.props, 'shouldSkipGtm')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView(title, {
      shelf: {
        // @ts-ignore TS2339
        id: _.get(this.palette, 'id'),
        // @ts-ignore TS2339
        name: _.get(this.palette, 'title'),
      },
    });
  }

  fetchData(props, context) {
    const pathProps = { ...props };
    // @ts-ignore TS2339
    if (this.palette) {
      // @ts-ignore TS2339
      pathProps.palette = this.palette;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.palette = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        this.items = _.values(this.model.getSync(rootPath.concat(['objects']))) || [];
        //console.log(this.palette)
        //if (this.palette.listContext !== '"editorial"') {}
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.props.id) return null;
    // @ts-ignore TS2339
    if (!this.palette || Object.keys(this.palette).length === 0) return null;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const routeHandler = this.context.routeHandler;
    // @ts-ignore TS2339
    const cardSize: CardSize = this.cardSize;
    let content;
    // @ts-ignore TS2339
    if (this.state.dispose) {
      content = <LoadingGallery pulsateTitles={true} />;
    } else {
      // this.palette.mbListLayout="grid"
      const galleryContentProps = {};
      // @ts-ignore TS2339
      if (this.palette.meta) {
        // @ts-ignore TS2339
        galleryContentProps.id = this.palette.meta;
        // @ts-ignore TS2339
        galleryContentProps.sortOrder = this.palette.sortOrder || 'po';
        // @ts-ignore TS2339
        if (this.palette.listContext === 'relateds') {
          // @ts-ignore TS2339
          galleryContentProps.keyPrefix = 'meta';
          // @ts-ignore TS2339
          galleryContentProps.modelRoot = this.palette.listContext;
        } else {
          // @ts-ignore TS2339
          galleryContentProps.keyPrefix = this.palette.listContext;
        }
        // @ts-ignore TS2339
      } else if (this.palette.listContext === 'recommended') {
        // @ts-ignore TS2339
        galleryContentProps.keyPrefix = this.palette.listContext;
        // @ts-ignore TS2339
        galleryContentProps.modelRoot = this.palette.elementId;
        // @ts-ignore TS2339
        galleryContentProps.genres = this.palette.genres || 0;
        // @ts-ignore TS2339
        galleryContentProps.defaultContents = _.get(routeHandler, 'query.dc', this.palette.defaultContents) || 0;
      } else {
        // @ts-ignore TS2339
        galleryContentProps.id = this.props.id;
        // @ts-ignore TS2339
        galleryContentProps.keyPrefix = 'palette';
        // @ts-ignore TS2339
        galleryContentProps.modelRoot = 'objects';
      }
      // @ts-ignore TS2339
      galleryContentProps.titleDisplaySetting =
        // @ts-ignore TS2339
        this.palette && this.palette.cardTitleDisplaySetting ? this.palette.cardTitleDisplaySetting : 'default';
      if (this.getArtType() === 'keyart') {
        // @ts-ignore TS2339
        galleryContentProps.itemsInRow = this.context.columnsInRow - 1;
      } else if (this.getArtType() === 'square') {
        // @ts-ignore TS2339
        if (this.context.columnsInRow == 2 || browserInfo.isIOS || browserInfo.isAndroid) {
          //スマホ
          // @ts-ignore TS2339
          if (cardSize === 'very_large' || cardSize === 'large') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow - 1;
          } else if (cardSize === 'small') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow + 1;
          } else if (cardSize === 'very_small') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow + 2;
          }
        // @ts-expect-error TS2339
        } else if (this.context.columnsInRow != 2) {
          // @ts-ignore TS2339
          if (cardSize === 'very_large' || cardSize === 'large') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow;
            // @ts-ignore TS2339
          } else if (cardSize === 'medium') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow + 1;
          } else if (cardSize === 'small') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow + 3;
          } else if (cardSize === 'very_small') {
            // @ts-ignore TS2339
            galleryContentProps.itemsInRow = this.context.columnsInRow + 4;
          }
        }
      } else if (this.getArtType() === 'poster') {
        if (cardSize === 'very_large' || cardSize === 'large') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow;
        } else if (cardSize === 'medium') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow + 1;
        } else if (cardSize === 'small') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow + 2;
        } else if (cardSize === 'very_small') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow + 3;
        }
      } else {
        // @ts-ignore TS2339
        if (cardSize === 'very_large' || cardSize === 'large') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow - 1;
        // @ts-expect-error TS2339
        } else if (this.context.columnsInRow != 2 && (cardSize === 'small' || cardSize === 'very_small')) {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow + 1;
        } else {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow;
        }
      }

      //mb_list_layout
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        if (
          this.getArtType() === 'square' &&
          // @ts-ignore TS2339
          (this.palette.mbListLayout !== 'grid' || this.palette.mbListLayout !== 'default')
        ) {
          // @ts-ignore TS2339
          this.palette.mbListLayout = 'grid';
        }
        // @ts-ignore TS2339
        galleryContentProps.mbListLayout = this.palette.mbListLayout;

        // @ts-ignore TS2339
        if (this.palette.mbListLayout == 'table' || this.palette.mbListLayout == 'box') {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = 1;
        }
        // @ts-ignore TS2339
        if (this.palette.mbListLayout == 'table') {
          // @ts-ignore TS2339
          galleryContentProps.listType = true;
          // @ts-ignore TS2339
          galleryContentProps.listCard = true;
        }
        if (
          this.getArtType() === 'poster' &&
          // @ts-ignore TS2339
          (this.palette.mbListLayout == 'grid' || this.palette.mbListLayout == 'default')
        ) {
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = this.context.columnsInRow + 1;
        }
      }
      // @ts-ignore TS2339
      if (this.props.liveSchedule || this.palette.schemaId == 8) {
        //ライブ配信ページ
        // @ts-ignore TS2339
        galleryContentProps.cardComponent = this.props.cardComponent || LiveCard;

        if (browserInfo.isIOS || browserInfo.isAndroid) {
          // @ts-ignore TS2339
          galleryContentProps.listType = true;
          // @ts-ignore TS2339
          galleryContentProps.itemsInRow = 1;
        }
      }

      let title;
      // @ts-ignore TS2339
      if (this.props.title) {
        // @ts-ignore TS2339
        title = this.props.title;
        // @ts-ignore TS2339
      } else if (this.palette) {
        // @ts-ignore TS2339
        if (this.palette.title) {
          // @ts-ignore TS2339
          title = this.palette.title;
          // 関連作品パレット
          // @ts-ignore TS2339
        } else if (this.palette.schemaId == 14) {
          title = '関連作品';
        }
      } else {
        title = '読み込み中...';
      }
      content = (
        <GalleryContent
          header={
            // @ts-ignore TS2339
            this.props.headerVisuable ? (
              <div className="gallery-header">
                <div className="title">
                  <span className="gallery-title">{title}</span>
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.description || this.palette.description ? (
                    <span className="gallery-description">
                      {/*
                       // @ts-ignore TS2339 */}
                      {this.props.description ? this.props.description : this.palette.description}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null
          }
          // @ts-ignore TS2339
          tabHeader={this.props.tabHeader}
          {...galleryContentProps}
          // @ts-ignore TS2339
          model={this.model}
          // @ts-ignore TS2339
          hideAllWhenNoResults={this.props.hideAllWhenNoResults}
          // @ts-ignore TS2322
          deleteApp={this.props.deleteApp}
          // @ts-ignore TS2339
          titleSmallSize={this.props.titleSmallSize}
          // @ts-ignore TS2339
          deliveryStartVisible={this.props.deliveryStartVisible}
          // @ts-ignore TS2339
          liveSchedule={this.props.liveSchedule}
          ignoreIds={this.ignoreIds}
          // @ts-ignore TS2339
          showMyListBtn={this.props.showMyListBtn}
          // @ts-ignore TS2339
          attributeMyListButtonProps={this.props.attributeMyListButtonProps}
          // @ts-ignore TS2339
          countFavoriteItems={this.props.countFavoriteItems}
        />
      );
    }
    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        {this.props.useHelmet ? (
          <HtmlContext.Consumer>
            {({ title }) => {
              const metas = [];
              const links = [];
              const isUuid = function(key) {
                return key && new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/).test(key);
              };
              // @ts-expect-error TS2339
              const host = this.context.getModelData('hosts', 'host');
              // @ts-ignore TS2339
              const key = this.palette.listContext === 'recommended' ? 'recommended' : 'editorial';
              const params = {};
              // idKeyがUUIDではない場合は意図的に設定されたものとみなし、idKeyでURLを正規化する
              // @ts-ignore TS2339
              if (this.palette.idKey && !isUuid(this.palette.idKey)) {
                // @ts-ignore TS2339
                params.id = this.palette.idKey;
                // @ts-ignore TS2339
              } else if (this.palette.id) {
                // @ts-ignore TS2339
                params.id = this.palette.id;
              }
              // @ts-ignore TS2554
              if (!_.isEmpty(params)) links.push({ rel: 'canonical', href: host + routes[key].makePath(params) });

              // @ts-ignore TS2339
              const paletteTitle = this.props.title ? this.props.title : this.palette.title;
              // @ts-ignore TS2339
              const paletteDescription = this.props.description ? this.props.description : this.palette.description;

              metas.push({ property: 'og:title', content: title(paletteTitle) });
              metas.push({ name: 'description', content: paletteDescription });

              return (
                <HelmetProvider>
                  <Helmet title={paletteTitle} meta={metas} link={links} />
                </HelmetProvider>
              );
            }}
          </HtmlContext.Consumer>
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        {this.palette.keyartUrl ? (
          <div className="mainVisual-keyart">
            {/*
             // @ts-ignore TS2339 */}
            <img src={this.palette.keyartUrl} />
          </div>
        ) : null}
        <CardContextProvider value={this.getChildContext()}>{content}</CardContextProvider>
      </React.Fragment>
    );
  }
}
