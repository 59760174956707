import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import { PAGING_INITIAL_KEY } from '../../../../constants';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';

export default class AttributeMyListButton extends Component {
  static get propTypes() {
    return {
      item: PropTypes.object,
      model: PropTypes.object.isRequired,
      classes: PropTypes.object,
      btnType: PropTypes.string,
      btnStyle: PropTypes.bool,
      btnColorWhite: PropTypes.bool,
      btnColorGray: PropTypes.bool,
      isFavorite: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static isUsable = context => {
    return !!context.getModelData('authContext');
  };

  constructor(props, context) {
    super(props, context);
    this._handleClick = this._handleClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.getTotalCount = this.getTotalCount.bind(this);
    this._handlePointerOver = this._handlePointerOver.bind(this);
    this._handlePointerOut = this._handlePointerOut.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    //this.item = props.item || {};
    this.state = {
      // @ts-ignore TS2339
      isFavorite: this.props.item.isFavorite,
      processing: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentWillReceiveProps() {}

  render() {
    // @ts-ignore TS2339
    if (!this.constructor.isUsable(this.context)) {
      return null;
    }

    let aProps = {};
    // @ts-ignore TS2339
    aProps.onClick = this._handleClick;
    // @ts-ignore TS2339
    aProps.onPointerOver = this._handlePointerOver;
    // @ts-ignore TS2339
    aProps.onPointerOut = this._handlePointerOut;
    // @ts-ignore TS2339
    aProps.onMouseEnter = this.onMouseEnter;
    // @ts-ignore TS2339
    aProps.onMouseLeave = this.onMouseLeave;
    // @ts-ignore TS2339
    let aClassName = this.props.classes;

    let displayText;
    // @ts-ignore TS2339
    if (this.state.processing) {
      // @ts-ignore TS2339
      if (this.state.isFavorite) {
        displayText = '削除中...';
      } else {
        displayText = '追加中...';
      }
    } else {
      // @ts-ignore TS2339
      displayText = 'お気に入り';
    }
    return (
      <div
        className={classnames(
          'push-button-wrapper mylist-button',
          {
            // @ts-ignore TS2339
            'action-btn': !this.props.btnStyle,
            // @ts-ignore TS2339
            btn: this.props.btnStyle,
            // @ts-ignore TS2339
            'grey-color': this.props.btnStyle && this.props.btnColorGray,
            // @ts-ignore TS2339
            'white-color': this.props.btnStyle && this.props.btnColorWhite,
            // @ts-ignore TS2339
            active: this.state.isFavorite,
          },
          aClassName,
        )}
        {...aProps}
      >
        <div
          role="link"
          // @ts-ignore TS2322
          tabIndex="0"
          className={classnames({
            fa: true,
            // @ts-ignore TS2339
            'fa-favorite-delete': this.state.isFavorite,
            // @ts-ignore TS2339
            'fa-favorite-add': !this.state.isFavorite,
          })}
        ></div>
        <span className="push-button-label" role="status" aria-live="assertive">
          {displayText}
        </span>
      </div>
    );
  }

  _handleClick(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }

    // @ts-ignore TS2339
    if (!this.constructor.isUsable(this.context)) {
      return null;
    }

    // @ts-ignore TS2339
    if (this.state.processing) {
      return;
    }
    this.setState({ processing: true });

    // @ts-ignore TS2339
    if (this.props.shouldEventPreventDefault) e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    const type = this.props.item.type || 'attr';
    // @ts-ignore TS2339
    this.sendToGtm(!this.state.isFavorite);
    // @ts-ignore TS2339
    this.props.model
      // @ts-ignore TS2339
      .setValue([type, this.props.id, 'mylisted'], !this.state.isFavorite)
      .then(done => {
        // キャッシュされているお気に入り一覧を削除する
        // @ts-ignore TS2339
        this.props.model.invalidate(['mylistattr']);
        const newState = { isFavorite: done, processing: false };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
        // @ts-ignore TS2339
        if (this.props.onChange) this.props.onChange(done);
      })
      .catch(e => {
        console.error('favoriteError', e);
        const newState = { processing: false };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.onMouseEnter) {
      // @ts-ignore TS2339
      this.props.onMouseEnter(e);
    }
  }
  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.onMouseEnter) {
      // @ts-ignore TS2339
      this.props.onMouseLeave(e);
    }
  }

  _handlePointerOver(e) {
    e.stopPropagation();
  }

  _handlePointerOut(e) {
    e.stopPropagation();
  }

  async sendToGtm(isFavorite) {
    if (!_.get(this.context, 'gtmApp')) return;

    const item = _.get(this.props, 'item');
    let quantity = await this.getTotalCount();
    if (isFavorite) {
      quantity++;
    } else {
      quantity--;
    }

    const attribute_id = _.get(item, 'id');
    const attribute_type = _.get(item, 'type');
    const attribute_name = _.get(item, 'name');

    const eventName = isFavorite ? CONTENT_EVENTS.ADD_TO_WISHLIST_ATTRIBUTE : CONTENT_EVENTS.FAVORITE_DELETE_ATTRIBUTE;
    let clickArea;
    if (eventName === CONTENT_EVENTS.ADD_TO_WISHLIST_ATTRIBUTE) {
      clickArea = CLICK_AREA.ADD_TO_WISHLIST_ATTRIBUTE.ATTRIBUTE_HEAD;
    } else {
      clickArea = CLICK_AREA.FAVORITE_DELETE_ATTRIBUTE.ATTRIBUTE_HEAD;
    }
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      eventName,
      { attribute_id, attribute_type, attribute_name, quantity },
      { clickArea },
    );
  }

  //GTM連携用。非公開などは考慮できないのでAPIで取れた値を出す。
  async getTotalCount() {
    const lengthPath = ['mylistattr', 'cd', PAGING_INITIAL_KEY, 1, 'length'];
    const paths = [lengthPath];
    try {
      // @ts-ignore TS2339
      const res = await this.props.model.fetch(paths);
      const totalCount = _.get(res.json, lengthPath);
      return totalCount;
    } catch (err) {
      // gtm連携できないだけなので握りつぶす
      // console.log(err);
    }
  }
}
