import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import window from 'global/window';

import * as ERROR from '../../../../constants/error';
import tokenDataStore from '../../../../utils/tokenDataStore';
import { PAGING_INITIAL_KEY } from '../../../../constants';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';

export default class MyListButton extends Component {
  static pathKeys = ['id', 'schemaId', 'refId', 'type', 'mylisted', 'canMyList'];

  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    return [path.concat([this.pathKeys])];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id];
  };

  static get propTypes() {
    return {
      item: PropTypes.object,
      model: PropTypes.object.isRequired,
      classes: PropTypes.string,
      btnType: PropTypes.string,
      btnStyle: PropTypes.bool,
      btnColorWhite: PropTypes.bool,
      btnColorGray: PropTypes.bool,
      id: PropTypes.string,
      billboard: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static isUsable = (context, item = {}) => {
    return (
      context.getModelData('authContext') &&
      // @ts-ignore TS2339
      item.id &&
      // @ts-ignore TS2339
      (item.canMyList || item.type === 'media' || item.type === 'group')
    );
  };

  constructor(props, context) {
    super(props, context);
    this._handleClick = this._handleClick.bind(this);
    this._handlePointerOver = this._handlePointerOver.bind(this);
    this._handlePointerOut = this._handlePointerOut.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.getTotalCount = this.getTotalCount.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    // @ts-ignore TS2339
    this.item = props.item || {};
    this.state = {
      processing: false,
      fetchDataError: null,
      dispose: null,
      generation: -1,
    };
    // 必要なキーが存在するか
    // @ts-ignore TS2339
    if (_.find(this.constructor.pathKeys, key => !_.has(this.item, key))) {
      // @ts-ignore TS2339
      this.item = {};
    }
    // キャッシュから復元
    // @ts-ignore TS2339
    if (_.isEmpty(this.item)) {
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(context.models, {}, props);
      // @ts-ignore TS2339
      this.item = props.model.getSync(rootPath, {});
      // @ts-ignore TS2339
      this.state.generation = props.model.getVersion(rootPath);
      // 必要なキーが存在するか
      // @ts-ignore TS2339
      if (_.find(this.constructor.pathKeys, key => !_.has(this.item, key))) {
        // @ts-ignore TS2339
        this.item = {};
      }
    }
    // @ts-ignore TS2339
    this.state.isFavorite = this.item.mylisted;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (_.isEmpty(this.item)) {
      this.fetchData(this.props);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (this.props.id != nextProps.id) {
      // @ts-ignore TS2339
      this.item = nextProps.item || {};
      // 必要なキーが存在するか
      // @ts-ignore TS2339
      if (_.find(this.constructor.pathKeys, key => !_.has(this.item, key))) {
        // @ts-ignore TS2339
        this.item = {};
      }
      const newState = {};
      // キャッシュから復元
      // @ts-ignore TS2339
      if (_.isEmpty(this.item)) {
        // @ts-ignore TS2339
        const rootPath = this.constructor.getRootPath(nextContext.models, {}, nextProps);
        // @ts-ignore TS2339
        this.item = nextProps.model.getSync(rootPath, {});
        // @ts-ignore TS2339
        newState.generation = nextProps.model.getVersion(rootPath);
        // 必要なキーが存在するか
        // @ts-ignore TS2339
        if (_.find(this.constructor.pathKeys, key => !_.has(this.item, key))) {
          // @ts-ignore TS2339
          this.item = {};
        }
      }
      // @ts-ignore TS2339
      newState.isFavorite = this.item.mylisted;
      this.setState(newState);

      // @ts-ignore TS2339
      if (_.isEmpty(this.item)) {
        this.fetchData(nextProps);
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.constructor.isUsable(this.context, this.item)) {
      return null;
    }

    let aProps = {};
    // @ts-ignore TS2339
    aProps.onClick = this._handleClick;
    // @ts-ignore TS2339
    aProps.onPointerOver = this._handlePointerOver;
    // @ts-ignore TS2339
    aProps.onPointerOut = this._handlePointerOut;
    // @ts-ignore TS2339
    aProps.onMouseEnter = this.onMouseEnter;
    // @ts-ignore TS2339
    aProps.onMouseLeave = this.onMouseLeave;
    // @ts-ignore TS2339
    let aClassName = this.props.classes;

    let displayText;
    // @ts-ignore TS2339
    if (this.state.processing) {
      // @ts-ignore TS2339
      if (this.state.isFavorite) {
        displayText = '削除中...';
      } else {
        displayText = '追加中...';
      }
    } else {
      // @ts-ignore TS2339
      if (this.props.btnType == 'short') {
        displayText = 'お気に入り';
      } else {
        // @ts-ignore TS2339
        if (this.state.isFavorite) {
          // @ts-ignore TS2339
          displayText = (this.item.schemaId === 1 ? 'このシリーズを' : '') + 'お気に入りに追加済み';
        } else {
          // @ts-ignore TS2339
          displayText = (this.item.schemaId === 1 ? 'このシリーズを' : '') + 'お気に入りに追加する';
        }
      }
    }

    return (
      <div
        className={classnames(
          'push-button-wrapper mylist-button',
          {
            // @ts-ignore TS2339
            'action-btn': !this.props.btnStyle,
            // @ts-ignore TS2339
            btn: this.props.btnStyle,
            // @ts-ignore TS2339
            'grey-color': this.props.btnStyle && this.props.btnColorGray,
            // @ts-ignore TS2339
            'white-color': this.props.btnStyle && this.props.btnColorWhite,
            // @ts-ignore TS2339
            active: this.state.isFavorite,
          },
          aClassName,
        )}
        {...aProps}
      >
        <div
          role="link"
          // @ts-ignore TS2322
          tabIndex="0"
          className={classnames({
            fa: true,
            // @ts-ignore TS2339
            'fa-favorite-delete': this.state.isFavorite,
            // @ts-ignore TS2339
            'fa-favorite-add': !this.state.isFavorite,
          })}
        ></div>
        <span className="push-button-label" role="status" aria-live="assertive">
          {displayText}
        </span>
      </div>
    );
  }

  _handleClick(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    // @ts-ignore TS2339
    if (!this.constructor.isUsable(this.context, this.item)) {
      return;
    }
    // @ts-ignore TS2339
    if (this.state.processing) {
      return;
    }
    this.setState({ processing: true });

    // @ts-ignore TS2339
    if (this.props.shouldEventPreventDefault) e.preventDefault();
    e.stopPropagation();

    // @ts-ignore TS2339
    this.sendToGtm(!this.state.isFavorite);
    // @ts-ignore TS2339
    this.props.model
      // @ts-ignore TS2339
      .setValue(['meta', this.item.id, 'mylisted'], !this.state.isFavorite)
      .then(done => {
        // キャッシュされているお気に入り一覧を削除する
        // @ts-ignore TS2339
        this.props.model.invalidate(['mylist']);
        const newState = { isFavorite: done, processing: false };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
        // @ts-ignore TS2339
        if (this.props.onChange) this.props.onChange(done);
      })
      .catch(e => {
        console.error('favoriteError', e);
        const newState = { processing: false };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.onMouseEnter) {
      // @ts-ignore TS2339
      this.props.onMouseEnter(e);
    }
  }
  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.onMouseEnter) {
      // @ts-ignore TS2339
      this.props.onMouseLeave(e);
    }
  }

  async sendToGtm(isFavorite) {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    const item = _.get(this.props, 'item') || this.item;
    let quantity = await this.getTotalCount();
    if (isFavorite) {
      quantity++;
    } else {
      quantity--;
    }
    const hasRelationProgram = _.get(item, 'seriesMeta') && _.get(item, 'seasonMeta');
    const isMediaMeta = _.get(item, 'type') === 'media';
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seriesMeta.name'),
        }
      : null;
    const program = isMediaMeta
      ? {
          refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
        }
      : {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
        };
    const content = isMediaMeta
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name') || _.get(item, 'shortName'),
          rental: _.get(item, 'rental'),
          subscription: _.get(item, 'subscription'),
        }
      : {
          rental: _.get(item, 'rental'),
          subscription: _.get(item, 'subscription'),
        };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    const eventName = isFavorite ? CONTENT_EVENTS.ADD_TO_WISHLIST : CONTENT_EVENTS.FAVORITE_DELETE;

    let clickArea;
    if (eventName === CONTENT_EVENTS.ADD_TO_WISHLIST) {
      // @ts-ignore TS2339
      if (this.props.billboard) {
        clickArea = CLICK_AREA.ADD_TO_WISHLIST.MUSTHEAD;
        // @ts-ignore TS2339
      } else if (this.props.sidelist) {
        clickArea = CLICK_AREA.ADD_TO_WISHLIST.IN_PLAYER;
        // @ts-ignore TS2339
      } else if (this.props.metaDetail) {
        clickArea = CLICK_AREA.ADD_TO_WISHLIST.META_DETAIL;
      } else {
        clickArea = CLICK_AREA.ADD_TO_WISHLIST.POP_CARD;
      }
    } else {
      // @ts-ignore TS2339
      if (this.props.billboard) {
        clickArea = CLICK_AREA.FAVORITE_DELETE.MUSTHEAD;
        // @ts-ignore TS2339
      } else if (this.props.sidelist) {
        clickArea = CLICK_AREA.FAVORITE_DELETE.IN_PLAYER;
        // @ts-ignore TS2339
      } else if (this.props.metaDetail) {
        clickArea = CLICK_AREA.FAVORITE_DELETE.META_DETAIL;
      } else {
        clickArea = CLICK_AREA.FAVORITE_DELETE.POP_CARD;
      }
    }
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      eventName,
      { content, program, relationProgram, attributes, genres, middleGenres, schemaId, quantity },
      { clickArea },
    );
  }

  //GTM連携用。非公開などは考慮できないのでAPIで取れた値を出す。
  async getTotalCount() {
    const lengthPath = ['mylist', 'cd', PAGING_INITIAL_KEY, 1, 'length'];
    const paths = [lengthPath];
    try {
      // @ts-ignore TS2339
      const res = await this.props.model.fetch(paths);
      const totalCount = _.get(res.json, lengthPath);
      return totalCount;
    } catch (err) {
      // gtm連携できないだけなので握りつぶす
      // console.log(err);
    }
  }

  _handlePointerOver(e) {
    e.stopPropagation();
  }

  _handlePointerOut(e) {
    e.stopPropagation();
  }

  fetchData(props) {
    if (!props.id) return;
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, null, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        const newState = {
          // @ts-ignore TS2339
          isFavorite: this.item.mylisted,
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
