import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

class Link extends React.PureComponent {
  static get propTypes() {
    return {
      children: PropTypes.any,
      className: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      target: PropTypes.string,
      replace: PropTypes.bool,
      onAfterNavigation: PropTypes.func,
      route: PropTypes.object,
      params: PropTypes.object,
      query: PropTypes.object,
      hash: PropTypes.object,
      preventScrollPositionReset: PropTypes.bool,
      innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    };
  }

  static get contextTypes() {
    return {
      history: PropTypes.object,
      routeHandler: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  isActive(path) {
    // @ts-expect-error TS2339
    const routeHandler = this.context.routeHandler;
    return path === routeHandler.route.makePath(routeHandler.params, routeHandler.query);
  }

  getHref() {
    // @ts-ignore TS2339
    if (this.props.href) {
      // @ts-ignore TS2339
      return this.props.href;
      // @ts-ignore TS2339
    } else if (this.props.route) {
      // @ts-ignore TS2339
      return this.props.route.makePath(
        // @ts-ignore TS2339
        this.props.params,
        // @ts-ignore TS2339
        this.props.query,
        // @ts-ignore TS2339
        this.props.hash,
      );
    }
    return null;
  }

  handleClick(event) {
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(event);
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      // @ts-ignore TS2339
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();

      // @ts-expect-error TS2339
      const { history } = this.context;
      // @ts-ignore TS2339
      const { replace } = this.props;
      const to = this.getHref();

      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }

      // @ts-ignore TS2339
      if (this.props.preventScrollPositionReset) {
        window.scrollTo(0, 0);
      }
      // @ts-ignore TS2339
      if (this.props.onAfterNavigation) {
        // @ts-ignore TS2339
        this.props.onAfterNavigation();
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    const { replace, to, innerRef, ...props } = this.props; // eslint-disable-line no-unused-vars

    const childProps = _.omit(props, [
      'href',
      'route',
      'params',
      'query',
      'hash',
      'onClick',
      'children',
      'className',
      'onAfterNavigation',
    ]);

    const href = this.getHref();
    // @ts-ignore TS2339
    const className = classnames(props.className, {
      // @ts-ignore TS2339
      active: !!href && this.isActive(href) && (!props.className || 0 > props.className.indexOf('active')),
    });
    if (className) {
      // @ts-ignore TS2339
      childProps.className = className;
    }
    if (href) {
      // @ts-ignore TS2339
      childProps.href = href;
    }
    if (innerRef) {
      // @ts-ignore TS2339
      childProps.ref = innerRef;
    }

    // @ts-expect-error TS2339
    return React.createElement('a', _.extend({}, childProps, { onClick: this.handleClick }), props.children);
  }
}

// @ts-ignore TS2339
Link.displayName = 'Link';

export default Link;
