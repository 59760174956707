import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import { thumbnailPublished, isCatchup } from '../../../../common/utils/eventUtil';
import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';
// @ts-ignore TS1192
import reactNl2br from '../../../../../common/reactNl2br';
import Tags from './Tags';
import BackgroundImage from '../../../../common/components/BackgroundImage';
import EpgEventSchedule from './EpgEventSchedule';
import datetime from 'src/domain/libs/datetime';

export default class EpgEvent extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    return [
      path.concat([
        [
          'id',
          'uniqueId',
          'name',
          'description',
          'shortMessage',
          'thumbnail',
          'thumbnailStartAt',
          'thumbnailEndAt',
          'thumbnailUrl',
          'startAt',
          'endAt',
          'duration',
          'casts',
          'staffs',
          'genres',
          'middleGenres',
          'attributes',
          'musics',
          'ritem',
          'rating',
          'suspendFlag',
          'systemGenres',
          'prg-code',
          'prg-branch',
          'ogImage',
          'program/title-orig',
          'programYearFrom',
          'program/country',
          'program/play-time',
          'program/recording/date',
          'program/recording/place',
          'program/genre-code',
          'schedule/broadcast-type-code',
          'schedule/language-code',
          /*'schedule/sound-code', 'schedule/closed-caption',*/ 'schedule/first-run',
          'schedule/ip-simulcast',
          'schedule/language',
          'catchupMeta',
          'linearChannelMeta',
          'permitUserStatuses',
          'links',
          'imultiflag',
          'inewpgmflag',
          'producedCountry',
          'producedYear',
          'copyrights',
          'translators',
        ],
      ]),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['event', props.id];
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      history: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {};
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      dispose: null,
      generation: -1,
      fetchDataError: null,
      loading: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.setState({ loading: true }, () => this.fetchData(this.props));
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      this.setState({ loading: true }, () => this.fetchData(nextProps));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (!this.state.loading && prevState.loading !== this.state.loading) {
      // @ts-ignore TS2339
      if (this.props.onEventLoaded) this.props.onEventLoaded();
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  static additionalData(item, date) {
    if (!item) return item;

    const now = datetime();
    let startTime = datetime(item.startAt, 'YYYY/MM/DD HH:mm:ss');
    let endTime = datetime(item.endAt, 'YYYY/MM/DD HH:mm:ss');
    item.startAt = startTime;
    item.endAt = endTime;
    let thumbnailStartAt = item.thumbnailStartAt ? datetime(item.thumbnailStartAt) : null;
    let thumbnailEndAt = item.thumbnailEndAt ? datetime(item.thumbnailEndAt) : null;
    item.thumbnailStartAt = thumbnailStartAt;
    item.thumbnailEndAt = thumbnailEndAt;
    item.duration = Math.ceil(item.duration / 60);
    item.isOnAir = startTime.isSameOrBefore(now) && endTime.isSameOrAfter(now);
    item.isCatchup = isCatchup(item.catchupMeta);
    item.isEnded = now.isAfter(endTime);
    if (date) item.isFromDay = item.startAt.isBefore(date, 'day');
    item.isMask = item['schedule/ip-simulcast'] == 0;
    item.isSuspend = !!item.suspendFlag;
    // if (item.isSuspend) {
    //   item.name = '放送休止';
    //   item.thumbnailUrl = null;
    //   item.isMask = true;
    // }

    let isMovie = false;
    if (_.get(item, 'program/genre-code') == '01') {
      isMovie = true;
    }
    item.recording = Object.values(_.pick(item, ['program/recording/place', 'program/recording/date']))
      .filter(Boolean)
      .join('／');
    item.programs = [];
    if (isMovie) {
      if (item['program/title-orig']) item.programs.push(`原題／${item['program/title-orig']}`);
      if (item.programYearFrom) item.programs.push(`制作年／${item.programYearFrom}`);
      if (item['program/country']) item.programs.push(`制作国／${item['program/country']}`);
      if (item['program/play-time']) item.programs.push(`内容時間／${Math.ceil(item['program/play-time'] / 60)}分`);
      if (item.attributes) {
        const attributes = item.attributes.map(attr => attr.name);
        if (attributes) item.programs.push(`ジャンル／${_.join(attributes, '・')}`);
      }
    }

    item = Tags.addTagFrag(item);
    return item;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);

    //if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }

    //this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }

    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});

        // @ts-ignore TS2339
        if (_.isEmpty(this.item)) {
          // @ts-ignore TS2339
          this.context.history.replace(routes.epg.makePath());
          return;
        }

        // @ts-ignore TS2339
        this.item = EpgEvent.additionalData(this.item);

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
          loading: false,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState);
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
          loading: false,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  handleClick(e) {
    // @ts-ignore TS2339
    if (this.props.onEventClose) this.props.onEventClose(e);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item || this.state.loading) {
      return (
        <React.Fragment>
          <div className="event-container">
            <div className="event-wrapper">
              {/*
               // @ts-ignore TS2339 */}
              {this.props.onEventClose ? (
                <div className="event-header">
                  概要
                  <div className="close" onClick={this.handleClick}>
                    <i className="fa fa-remove"></i>
                  </div>
                </div>
              ) : null}

              <div className="loading"></div>
            </div>
          </div>
          <div className="clearfix"></div>
        </React.Fragment>
      );
    }

    // @ts-ignore TS2339
    const genres = this.item.genres.map(genre => {
      return (
        // @ts-expect-error TS2322
        <MainViewLink
          // @ts-ignore TS2322
          className={'inactive'}
          to={routes.genre}
          params={{ id: genre.id }}
          //query={{ mode: 'linear' }}
          key={`epg-genre=${genre.id}`}
        >
          {genre.name}
        </MainViewLink>
      );
    });
    // @ts-ignore TS2339
    const casts = this.item.casts.map((cast, i) => {
      return (
        <li key={`cast-${i}`}>
          {/*
           // @ts-ignore TS2322 */}
          <MainViewLink to={routes.person} params={{ id: cast.id }}>
            {cast.name}
          </MainViewLink>
          {cast.notice ? <span className="notice">({cast.notice})</span> : null}
        </li>
      );
    });
    // @ts-ignore TS2339
    const staffs = this.item.staffs.map((staff, i) => {
      return (
        <li key={`staff-${i}`}>
          {/*
           // @ts-ignore TS2322 */}
          <MainViewLink to={routes.person} params={{ id: staff.id }}>
            {staff.name}
          </MainViewLink>
          {staff.notice ? <span className="notice">({staff.notice})</span> : null}
        </li>
      );
    });
    // @ts-ignore TS2339
    const musics = this.item.musics.map((music, i) => {
      return (
        <li key={`music-${i}`}>
          {music.title}
          {music.performer ? <span className="notice">({music.performer})</span> : null}
        </li>
      );
    });
    // @ts-ignore TS2339
    const programs = this.item.programs.map((program, i) => {
      return <li key={`program-${i}`}>{program}</li>;
    });

    let watchProps;
    // @ts-ignore TS2339
    if (this.item.isOnAir) {
      // @ts-ignore TS2339
      watchProps = { params: { id: this.item.linearChannelMeta.metaId } };
      // @ts-ignore TS2339
    } else if (this.item.isCatchup) {
      // @ts-ignore TS2339
      watchProps = { params: { id: this.item.catchupMeta.metaId } };
    }

    return (
      <React.Fragment>
        <div className="event-container">
          <div className="event-wrapper">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.onEventClose ? (
              <div className="event-header">
                概要
                <div className="close" onClick={this.handleClick}>
                  <i className="fa fa-remove"></i>
                </div>
              </div>
            ) : null}

            <div className="event-scroll-wrapper">
              <div className="contents">
                {/*
                 // @ts-ignore TS2339 */}
                {this.props.onEventClose ? (
                  <div className="name">
                    {watchProps ? (
                      <MainViewLink to={routes.watchNow} {...watchProps}>
                        {/*
                         // @ts-ignore TS2339 */}
                        {this.item.name}
                      </MainViewLink>
                    ) : (
                      // @ts-ignore TS2339
                      this.item.name
                    )}
                  </div>
                ) : null}

                {/*
                 // @ts-ignore TS2339 */}
                {this.item.thumbnailUrl && thumbnailPublished(this.item.thumbnailStartAt, this.item.thumbnailEndAt) ? (
                  <div className="thumbnail-container">
                    {watchProps ? (
                      <MainViewLink to={routes.watchNow} {...watchProps}>
                        {/*
                         // @ts-ignore TS2322 */}
                        <BackgroundImage className={classnames('thumbnail', {})} url={this.item.thumbnailUrl} />
                        <div className="play-link"></div>
                      </MainViewLink>
                    ) : (
                      // @ts-ignore TS2322
                      <BackgroundImage className={classnames('thumbnail', {})} url={this.item.thumbnailUrl} />
                    )}
                  </div>
                ) : null}

                <div className={classnames('channel')}>
                  {/*
                   // @ts-ignore TS2322 */}
                  <BackgroundImage className="channel-logo" url={this.item.linearChannelMeta.secondThumbnailUrl} />
                </div>

                <div className="time">
                  {/*
                   // @ts-ignore TS2339 */}
                  {datetime(this.item.startAt, 'YYYY/MM/DD HH:mm:ss').format('M/D (dd) HH:mm')} -{' '}
                  {/*
                   // @ts-ignore TS2339 */}
                  {datetime(this.item.endAt, 'YYYY/MM/DD HH:mm:ss').format('HH:mm')}
                </div>

                {/*
                 // @ts-ignore TS2339 */}
                {this.item.isSuspend ? null : this.item.isMask ? (
                  <div className="description">この番組は同時配信 対象外です。</div>
                ) : (
                  <React.Fragment>
                    <div className="genre-tags clearfix">
                      <div className="genres">{genres}</div>
                      {/*
                       // @ts-ignore TS2339 */}
                      <Tags {...this.item} />
                    </div>

                    {/*
                     // @ts-ignore TS2339 */}
                    <div className="description">{reactNl2br(this.item.description)}</div>

                    {_.isEmpty(programs) ? null : (
                      <div className="programs">
                        <div className="label">作品データ</div>
                        <ul>{programs}</ul>
                      </div>
                    )}

                    {_.isEmpty(casts) ? null : (
                      <div className="casts">
                        <div className="label">出演</div>
                        <ul>{casts}</ul>
                      </div>
                    )}

                    {_.isEmpty(staffs) ? null : (
                      <div className="staffs">
                        <div className="label">スタッフ</div>
                        <ul>{staffs}</ul>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
