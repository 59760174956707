import datetime from '../libs/datetime';
import activeProfile, { isFree } from './activeProfile';

type UserStatus = '1' | '2' | '3';

/**
 * 既存のユーザーステータスから未ログイン、契約済み、未加入に分類する
 *
 * @param userStatus
 * @returns 1: 未ログイン　2:　未加入　3:　契約中
 */
const conversionUserStatus = (profile?: any): UserStatus => {
  if (!profile) return '1';
  if (isFree(profile)) return '2';
  else return '3';
};

export const isDisplayCommunicationArea = (models: any, targetUser?: UserStatus[], start?: string, end?: string) => {
  /**
   * コミュニケーションエリアが公開期間かどうかの判定
   * @param start
   * @param end
   * @returns
   */
  const isCommunicationAreaDisplayPeriod = (start?: string, end?: string) => {
    const now = datetime();
    const startAt = start ? datetime(new Date(start)) : null;
    const endAt = end ? datetime(new Date(end)) : null;

    if (!startAt && !endAt) {
      return true;
    } else if (!startAt && endAt) {
      return now.isBefore(endAt);
    } else if (startAt && !endAt) {
      return now.isAfter(startAt);
    } else if (startAt && endAt) {
      return now.isBetween(startAt, endAt, null, '[)');
    }
  };

  /**
   * コミュニケーションエリアを表示していいユーザーかどうかの判定
   * @param models
   * @param targetUser 表示したいユーザーの契約形態。　1: 未ログイン　2:　未加入　3:　契約中
   * @returns
   */
  const isDisplayCommunicationAreaUser = (models: any, targetUser?: UserStatus[]) => {
    const communicationUser = targetUser || [];
    const profile = activeProfile(models);
    const newUserStatus = conversionUserStatus(profile);
    return communicationUser.length === 0 || communicationUser.includes(newUserStatus);
  };

  return isCommunicationAreaDisplayPeriod(start, end) && isDisplayCommunicationAreaUser(models, targetUser);
};
