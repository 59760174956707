import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import url from 'url';

import HtmlSnippet from './HtmlSnippet';
import routes from '../routes';
import MainViewLink from './MainViewLink';
// @ts-ignore TS1192
import reactNl2br from '../../../common/reactNl2br';

import { CLICK_AREA, CUSTOM_EVENTS } from '../../../common/GtmApp';
import { CAMPAIGN_FLAG } from '../../../constants/flag';
import DeeplinkApp from '../../bluerose/components/deepLink/DeepLinkApp';
import activeProfile, { isFree } from 'src/utils/activeProfile';

export const IS_DEEP_LINK = 'isDeepLink';
const SIGNUP_PATH = '/static/join/';
class SpNavFooter extends React.Component {
  static getPaths = HtmlSnippet.getPaths;
  static getRootPath = HtmlSnippet.getRootPath;

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object.isRequired,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.rootRef = props.rootRef || React.createRef();
    // @ts-ignore TS2339
    this.htmlSnippet = null;
    // @ts-ignore TS2339
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    this.handleClose = this.handleClose.bind(this);
    this.handleAppRun = this.handleAppRun.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.onAppDownloadClick = this.onAppDownloadClick.bind(this);

    // this.snippetId = _.get(context, 'models.config.data.html_snippet_keys.sp_nav_footer');
    // this.snippetIdSwitchingConditions = _.get(context, 'models.config.data.html_snippet_keys.sp_nav_footer_switching_conditions');
    // const rootPath = this.constructor.getRootPath(context.models, {}, props);

    const browserInfo = context.getModelData('browserInfo');
    const profile = activeProfile(context.models);

    this.state = {
      isShow: isFree(profile),
      isDeeplink: _.get(browserInfo, 'isAndroid') || _.get(browserInfo, 'isIOS'), // Android, iOS以外の場合は非表示
      // fetchDataError: null,
      // generation: this.model.getVersion(rootPath),
    };

    // @ts-ignore TS2339
    this.isHide = false;
    // @ts-ignore TS2339
    this.isRental = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // if (this.isAllowedToFetchData()) {
    //   this.fetchData(this.props, this.context);
    // }
  }

  componentWillReceiveProps(nextProps) {
    // if (this.isAllowedToFetchData()) {
    //   this.isHide = false;
    //   this.isRental = false;
    //   if (nextProps.currentRouteHandler.route._regex === routes.watchNow._regex
    //     || nextProps.currentRouteHandler.route._regex === routes.title._regex
    //     || nextProps.currentRouteHandler.route._regex === routes.genre._regex)
    //   {
    //     this.isHide = nextProps.isHide;
    //     this.isRental = nextProps.isRental;
    //   }
    //   if (this.state.snippetId != this.getSnippetId(nextProps)) {
    //     if (this.state.dispose) this.state.dispose();
    //     this.fetchData(nextProps, this.context);
    //   }
    // }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props, context) {
    let snippetId = this.getSnippetId(props);
    if (!snippetId) {
      return;
    }
    const _props = _.assign({ snippetId: snippetId }, props);

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, _props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, _props);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;

    // 描画に影響するので先に更新しておく
    this.setState({ snippetId });

    evaluator
      .then(res => {
        const htmlSnippet = _.get(res.json, rootPath);
        if (_.get(htmlSnippet, 'content')) {
          // @ts-ignore TS2339
          this.htmlSnippet = htmlSnippet;
        } else {
          // @ts-ignore TS2339
          this.htmlSnippet = null;
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.pathEvaluator.getVersion(rootPath),
          snippetId: snippetId,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  fetchToken(props, context) {
    const authContext = context.getModelData('authContext');
    if (!authContext) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status != 200) {
            return reject();
          }
          try {
            let response = xhr.response;
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }
            return resolve(_.get(response, 'onetime_token'));
          } catch (e) {
            return reject(e);
          }
        }
      };
      // let tokenService = _.assign({}, context.getModelData('services', 'tokenManager'));
      // tokenService.pathname = _.join(_.concat(tokenService.path, 'onetime-token/publish'), '/');
      // xhr.open('POST', url.format(tokenService), false);
      // xhr.setRequestHeader('Content-Type', 'application/json');
      // xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      // xhr.setRequestHeader('X-Token-Id', authContext.id);
      xhr.open('GET', '/api/user/onetime-token', false);
      xhr.send();
    });
  }

  handleAppRun(e) {
    if (_.get(this.context, 'gtmApp')) {
      // @ts-expect-error TS2339
      this.context.gtmApp.pushDataLayerOnAppLaunchClick(CLICK_AREA.APP_LAUNCH.FOTTER);
    }

    // @ts-expect-error TS2339
    const authContext = this.context.getModelData('authContext');
    // @ts-expect-error TS2339
    const deeplinkApp = new DeeplinkApp({ routeHandler: this.context.routeHandler, authContext });
    deeplinkApp.launch(this.props);
  }

  handleLinkClick() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnSubscriptionClick(CLICK_AREA.SIGNUP.FOTTER);
    // const href = '/signup/mail'
    // const query = {};
    // const pathname = _.get(window, 'location.pathname', '');
    // const returnRoutes = [
    //   routes.watchNow,
    //   routes.content,
    //   routes.pv,
    //   routes.program,
    //   routes.title,
    //   routes.simulcast,
    //   routes.simulcastDetail,
    // ];
    // if (returnRoutes.some(route => route.match(pathname))) {
    //   query['return_to'] = pathname;
    // }
    // const queryStr = _.isEmpty(query) ? '' : `?${queryString.stringify(query)}`;
    // window.location.href = href + queryStr;
    // return false;
  }

  onAppDownloadClick() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnAppDownloadClick(CLICK_AREA.APP_DOWNLOAD.FOTTER);
  }

  render() {
    // @ts-ignore TS2339
    if (this.isHide) return null;

    // @ts-expect-error TS2339
    const config = this.context.getModelData('config');
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    if (!browserInfo.isIOS && !browserInfo.isAndroid) return null;

    let baseStyle;
    // @ts-ignore TS2339
    if (this.state.isShow || this.state.isDeeplink) {
      baseStyle = {
        opacity: 1,
        visibility: 'visible',
      };
    } else {
      baseStyle = {
        opacity: 0,
        visibility: 'hidden',
      };
    }

    let storeUrl;
    if (_.get(browserInfo, 'isIOS')) {
      storeUrl = _.get(config, 'store_urls.ios');
    } else if (_.get(browserInfo, 'isFireTablet')) {
      storeUrl = _.get(config, 'store_urls.amazon');
    } else if (_.get(browserInfo, 'isAndroid')) {
      storeUrl = _.get(config, 'store_urls.android');
    }

    const joinBtnText = _.get(config, ['sp_footer', 'label']) || 'ご加入はこちら';
    const caution = _.get(config, ['sp_footer', 'caution']);

    const campaignFlag = CAMPAIGN_FLAG;

    return (
      <div
        // @ts-ignore TS2339
        className={classnames('sp-nav-footer', { [IS_DEEP_LINK]: !this.state.isShow, campaignFlag: !!campaignFlag })}
        style={baseStyle}
        // @ts-ignore TS2339
        ref={this.rootRef}
      >
        {/*
         // @ts-ignore TS2339 */}
        {this.state.isShow && (_.get(browserInfo, 'isAndroid') || _.get(browserInfo, 'isIOS')) ? (
          <div
            id="floating_banner"
            className={classnames('sp-nav-footer__inner', { android: browserInfo.isAndroid, ios: browserInfo.isIOS })}
          >
            {/*
             // @ts-ignore TS2322 */}
            <MainViewLink href={SIGNUP_PATH} onClick={this.handleLinkClick} className={'btn btn-fill accent-color'}>
              {joinBtnText}
            </MainViewLink>
            {caution && <p className="sp-nav-footer__title">{reactNl2br(caution)}</p>}
          </div>
        ) : // @ts-ignore TS2339
        this.state.isDeeplink ? (
          <div className="sp-nav-footer__inner">
            <div className="sp-nav-footer__close">
              <div className="sp-nav-footer__close-btn" onClick={this.handleClose}>
                <i className="fa fa-remove"></i>
              </div>
            </div>
            <div className="footer-btn-area">
              <a
                key="launch"
                href="javascript:void(0)"
                className="btn btn-fill sub-bt-color btn-very-small"
                onClick={this.handleAppRun}
              >
                アプリを起動
              </a>
              <a
                key="download"
                className="btn btn-gray btn-very-small"
                href={storeUrl}
                target="_blank"
                onClick={this.onAppDownloadClick}
              >
                アプリをダウンロード
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  handleClose(e) {
    // @ts-ignore TS2339
    if (!this._isMounted) {
      return;
    }
    this.setState({ isShow: false, isDeeplink: false });
  }

  isAllowedToFetchData() {
    // Android, iOS以外の場合は非表示
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    const isSmaTab = _.get(browserInfo, 'isAndroid') || _.get(browserInfo, 'isIOS');

    return true;
    // if (isSmaTab && this.state.isShow) {
    //   return true;
    // } else if (this.state.isShow) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  getSnippetId(props = {}) {
    let snippetId;
    // @ts-ignore TS2339
    let currentPath = String(props.currentPath);
    // @ts-ignore TS2339
    let conds = this.snippetIdSwitchingConditions;
    if (currentPath && typeof conds == 'object') {
      let keys = _.keys(conds);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let val = conds[key];
        let reg = new RegExp(keys[i]);
        if (val && currentPath.match(reg)) {
          snippetId = val;
          break;
        }
      }
    }
    if (!snippetId) {
      // @ts-ignore TS2551
      snippetId = this.snippetId;
    }
    // レンタルの時は rental_ を付与する
    // @ts-ignore TS2339
    if (snippetId && this.isRental) {
      snippetId = 'rental_' + snippetId;
    }
    // ログインしている時は login_ を付与する
    // @ts-expect-error TS2339
    if (snippetId && _.get(this.context.getModelData('userInfo'), 'status') !== 'NON_REGISTERED_MEMBER') {
      snippetId = 'login_' + snippetId;
    }
    return snippetId;
  }
}

export default SpNavFooter;
