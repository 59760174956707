import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import MainViewLink from '../../MainViewLink';
import { UserInfo } from 'src/types/app/models';
import AppContext from 'src/types/context/App';
import SearchBox from '../common/SearchBox';
import { ACTIVE_MENU, GlobalMenuGenre, InfoData, MainMenu } from 'src/apps/common/components/GlobalMenu';
import Toggle from './elements/Toggle';
import LoginButton from '../common/LoginButton';
import SignupButton from '../common/SignupButton';
import MyListButton from '../common/MyListButton';
import InfoButton from '../common/InfoButton';
import WodLogo from '../common/WodLogo';
import routes from 'src/apps/common/routes';
import { RouteHandler } from 'src/types/app/Route';
import LinearChannelList from '../common/LinearChannelList';
import url from 'url';
import MyPage from '../common/MyPage';

type SideMenuProps = {
  globalMenuGenres: GlobalMenuGenre[];
  infoData: InfoData;
  mainMenus: MainMenu[];
  isJoinCanvas?: boolean;
  isAccountLayout?: boolean;
  hideLogoLink?: boolean;
  pathEvaluator?: any;
  model?: any;
  enableIncrementalSearch?: boolean;
  term?: string;
  onChangeSideMenuOpened: (bool: boolean) => void;
};

type SideMenuContext = {
  getModelData?: AppContext.GetModelData;
  routeHandler?: RouteHandler;
};

const SideMenu = (props: SideMenuProps, context: SideMenuContext) => {
  const [opened, setOpened] = useState(true);
  const [openList, setOpenList] = useState([]);
  const userInfo = useMemo(() => context.getModelData<UserInfo>('userInfo'), [context]);
  const model = useMemo(() => (props.pathEvaluator || props.model.pathEvaluator).batch(100), [props]);
  const onClickToggle = useCallback(() => {
    setOpened(true);
    props.onChangeSideMenuOpened(true);
  }, [props]);

  const onCloseMenu = useCallback(() => {
    setOpened(false);
    props.onChangeSideMenuOpened(false);
  }, [props]);

  const handleOpenMenuClick = (linkName: string) => {
    if (!linkName) {
      return;
    }
    if (openList.includes(linkName)) {
      setOpenList(prevOpenList => prevOpenList.filter(item => item !== linkName));
      return;
    }
    setOpenList(prevOpenList => [...prevOpenList, linkName]);
    return;
  };

  const renderOpenMenu = (linkName: string) => {
    switch (linkName) {
      case 'linear':
        return <LinearChannelList model={model} />;
      case 'myMenu':
        return <MyPage />;
      default:
        return null;
    }
  };

  return (
    <div className={classnames('sidemenu', { opened: opened, closed: !opened })}>
      <div className="sidemenu-content">
        <WodLogo
          txtLogo={!opened}
          isAccountLayout={props.isAccountLayout}
          hideLogoLink={props.hideLogoLink}
          isJoinCanvas={props.isJoinCanvas}
        />
        {opened ? (
          <>
            <div className="sidemenu-element">
              <ul className="info-menu" key={'infoComponent'}>
                <li>
                  <InfoButton infoData={props.infoData} />
                </li>
              </ul>
              <SearchBox
                // @ts-ignore TS2322
                model={props.model}
                pathEvaluator={props.pathEvaluator}
                enableIncrementalSearch={props.enableIncrementalSearch}
                term={props.term}
              />
              <ul className="menu-list">
                <div className="nav-element">
                  <div className="btnArea">
                    {typeof userInfo.userStatus !== 'number' ? (
                      <>
                        <LoginButton />
                        <SignupButton />
                      </>
                    ) : userInfo.userStatus === 0 || userInfo.userStatus === 4 ? (
                      <>
                        <MyListButton />
                        <SignupButton />
                      </>
                    ) : (
                      <MyListButton />
                    )}
                  </div>
                </div>
                <li>
                  <ul>
                    {_.map(props.mainMenus, link => {
                      if (link.hidden) {
                        return null;
                      } else if (link.dropdown) {
                        return (
                          <li key={`link-${link.name}`} onClick={() => handleOpenMenuClick(link.name)}>
                            {typeof userInfo.userStatus !== 'number' && link.name === 'myMenu' ? null : (
                              <>
                                <span className="accordion-header">{link.title}</span>
                                {openList.includes(link.name) && (
                                  <div className="openSidemenu-wrapper">
                                    <div className="linear-openSidemenu" key={'linear-openMenu'}>
                                      {renderOpenMenu(link.name)}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </li>
                        );
                      } else {
                        const urlObj = url.parse(link.url);
                        const isActive = context && context.routeHandler.path === urlObj.path;
                        return (
                          <li
                            className={classnames('sidemenu-list', { [ACTIVE_MENU]: isActive })}
                            key={`link-${link.name}`}
                          >
                            {/*
                             // @ts-expect-error TS2322 */}
                            <MainViewLink className="view-link" href={link.url} params={{}}>
                              {link.title}
                            </MainViewLink>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </li>
                {props.globalMenuGenres.length > 0 ? <hr className="hr-line" /> : null}
                <li>
                  <ul>
                    {_.map(props.globalMenuGenres, genre => {
                      const shouldCheckIsActive = _.keys((context && context.routeHandler.query) || {}).length > 0;
                      let linkProps = {
                        params: { id: genre.id },
                        to: routes.genre,
                        className: '',
                      };
                      switch (genre.type) {
                        case 'genre':
                          if (genre.refId) {
                            linkProps = {
                              to: routes.genreRef,
                              params: { id: genre.refId },
                              className: '',
                            };
                          }
                          break;
                        case 'person':
                          linkProps.to = routes.person;
                          break;
                        case 'league':
                          linkProps.to = routes.league;
                          break;
                        case 'team':
                          linkProps.to = routes.team;
                          break;
                        default:
                          linkProps.to = routes.attr;
                      }
                      if (
                        shouldCheckIsActive &&
                        context &&
                        linkProps.to.makePath(linkProps.params) === context.routeHandler.path
                      ) {
                        linkProps.className = ACTIVE_MENU;
                      }
                      //TODO:GA連携するのか？

                      return (
                        <li
                          className={classnames('sidemenu-list', {
                            [ACTIVE_MENU]:
                              context && linkProps.to.makePath(linkProps.params) === context.routeHandler.url,
                          })}
                        >
                          {/*
                           // @ts-expect-error TS2322 */}
                          <MainViewLink {...linkProps} key={`genre-${genre.id}`}>
                            {genre.name}
                          </MainViewLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="close-footer">
              <span className="close-button" onClick={onCloseMenu}>
                <i className="fa fa-remove"></i>
                メニューを閉じる
              </span>
            </div>
          </>
        ) : (
          <Toggle onClick={onClickToggle} open={opened} />
        )}
      </div>
    </div>
  );
};

SideMenu.contextTypes = {
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
};

export default SideMenu;
