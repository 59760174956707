import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import window from 'global/window';

import MainViewLink from '../../MainViewLink';
import SearchBox from '../common/SearchBox';
import routes from '../../../routes';
import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import { MENU_CATEGORY } from '../../../../../common/GtmApp';
import { ACTIVE_MENU, InfoData, OpenMenu } from 'src/apps/common/components/GlobalMenu';
import InfoButton from '../common/InfoButton';
import MyListButton from '../common/MyListButton';
import LoginButton from '../common/LoginButton';
import SignupButton from '../common/SignupButton';

type SecondaryNavigationProps = {
  openMenu: OpenMenu;
  existsNewInfo: boolean;
  infoData: InfoData[];
  handleMenuClick: (menu: OpenMenu, el: HTMLElement) => void;
};

type SecondaryNavigationState = {
  searchBoxFocused: boolean;
  showHeaderSearchInput: boolean;
};

class SecondaryNavigation extends Component<SecondaryNavigationProps, SecondaryNavigationState> {
  _isMounted?: boolean;
  searchKeywordRef: React.RefObject<MainViewLink>;

  static get propTypes() {
    return {
      handleSearchBoxFocus: PropTypes.func,
      model: PropTypes.object,
      pathEvaluator: PropTypes.object,
      term: PropTypes.string,
      showHamburgerButton: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.searchKeywordRef = React.createRef();

    this.handleSearchBoxFocus = this.handleSearchBoxFocus.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleSaerchClick = this.handleSaerchClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.pushDataLayerOnMenuClick = this.pushDataLayerOnMenuClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.checkMediaQuery = this.checkMediaQuery.bind(this);

    this.state = {
      searchBoxFocused: false,
      showHeaderSearchInput: true,
    };
  }
  handleResize() {
    this.checkMediaQuery();
  }
  checkMediaQuery() {
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');
    let screenSize;
    if (userInfo.status !== 'NON_REGISTERED_MEMBER' && userInfo.userStatus !== 0) {
      //ログイン後・契約済み
      screenSize = 1024;
    } else {
      //未ログイン・未契約
      screenSize = 1260;
    }
    if (window.matchMedia('screen and (min-width: ' + screenSize + 'px)').matches) {
      this.setState({ showHeaderSearchInput: true });
    } else {
      this.setState({ showHeaderSearchInput: false });
    }
  }
  componentDidMount() {
    this._isMounted = true;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.addEventListener('resize', this.handleResize);
      this.handleResize();
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      browserEvents.removeEventListener('resize', this.handleResize);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
  }

  handleSearchBoxFocus(searchBoxFocused) {
    // @ts-ignore TS2339
    if (this.props.handleSearchBoxFocus) {
      // @ts-ignore TS2339
      this.props.handleSearchBoxFocus(searchBoxFocused);
    }
    this.setState({ searchBoxFocused });
  }

  handleMenuClick(openMenu?: OpenMenu, target?: HTMLElement) {
    this.props.handleMenuClick(openMenu, target);
  }

  handleMouseEnter(openMenu) {
    // @ts-ignore TS2339
    this.props.handleMouseEnter(openMenu);
  }

  handleMouseLeave(e) {
    // @ts-ignore TS2339
    this.props.handleMouseLeave(e);
  }

  handleSaerchClick() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    this.pushDataLayerOnMenuClick(MENU_CATEGORY.SEARCH);
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      // @ts-ignore TS2554
      this.handleMenuClick();
    } else {
      // @ts-ignore TS2554
      this.handleMenuClick({ name: 'searchKeyword' });
    }
  }

  pushDataLayerOnMenuClick(menuCategory) {
    // @ts-expect-error TS2339
    if (!this.context.gtmApp) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnMenuClick(menuCategory);
  }

  render() {
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');

    let userComponent = null;
    let profileData;

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    // ログイン後のユーザーの場合
    if (userInfo.status !== 'NON_REGISTERED_MEMBER') {
      if (
        // @ts-ignore TS2339
        this.props.hideAccountLinks === false ||
        // @ts-ignore TS2339
        (!this.props.hideNavigation && this.props.hideAccountLinks !== true)
      ) {
        userComponent = (
          <div key={'userComponent'} className="nav-element">
            <div className="btnArea">
              <MyListButton />
              {(userInfo.userStatus == 0 || userInfo.userStatus == 4) &&
              !(browserInfo.isIOS || browserInfo.isAndroid) ? (
                <SignupButton />
              ) : null}
            </div>
          </div>
        );
      }
    } else if (
      // @ts-ignore TS2339
      this.props.hideAuthLinks === false ||
      // @ts-ignore TS2339
      (!this.props.hideNavigation && this.props.hideAuthLinks !== true)
    ) {
      userComponent = (
        <div key={'userComponent'} className="nav-element">
          <div className="btnArea">
            <LoginButton />
            {/*
             // @ts-ignore TS2339 */}
            {!!(browserInfo.isIOS || browserInfo.isAndroid) || this.props.showHamburgerButton ? null : <SignupButton />}
          </div>
        </div>
      );
    }

    let serachInput = (
      <SearchBox
        // @ts-ignore TS2322
        model={this.props.model}
        // @ts-ignore TS2339
        pathEvaluator={this.props.pathEvaluator}
        handleSearchBoxFocus={this.handleSearchBoxFocus}
        // @ts-ignore TS2339
        handleSearchInputFocus={this.props.handleSearchInputFocus}
        // @ts-ignore TS2339
        searchBoxFocused={true}
        // @ts-ignore TS2339
        enableIncrementalSearch={this.props.enableIncrementalSearch}
        // @ts-ignore TS2339
        showHeaderSearchInput={this.state.showHeaderSearchInput}
        // @ts-ignore TS2339
        term={this.props.term}
        // @ts-ignore TS2339
        ref={this.searchKeywordMenuRef}
        handleMenuClick={this.handleMenuClick}
        showHistory={false}
        showFilter={false}
      />
    );
    let searchComponent = (
      <div
        className="search-box"
        onMouseEnter={e => {
          this.handleMouseEnter({ name: 'searchKeyword' });
        }}
        onMouseLeave={this.handleMouseLeave}
        key={'searchComponent'}
      >
        {browserInfo.isAndroid || browserInfo.isIOS ? (
          // @ts-expect-error TS2322
          <MainViewLink
            to={routes.search}
            className={classnames('open-menu searchKeyword-open', {
              // @ts-ignore TS2339
              [ACTIVE_MENU]: this.props.openMenu == 'searchKeyword',
            })}
            id="searchTab"
            ref={this.searchKeywordRef}
            onClick={this.handleSaerchClick}
            tabIndex={0}
            aria-label="検索"
          >
            <span className="icon-search fa fa-search" />
          </MainViewLink>
        ) : // @ts-ignore TS2339
        this.state.showHeaderSearchInput ? (
          serachInput
        ) : (
          <button
            className={classnames('open-menu searchKeyword-open', {
              // @ts-ignore TS2339
              [ACTIVE_MENU]: this.props.openMenu == 'searchKeyword',
            })}
            id="searchTab"
            name="search"
            // @ts-ignore TS2339
            ref={this.searchKeywordRef}
            onClick={this.handleSaerchClick}
            tabIndex={0}
            aria-label="検索"
          >
            <span className="icon-search fa fa-search" />
          </button>
        )}
        {/*
         // @ts-ignore TS2339 */}
        {this.props.openMenu == 'searchKeyword' ? (
          <React.Fragment>
            {/*
             // @ts-ignore TS2339 */}
            <div className="openMenu-wrapper" style={this.props.menuWrapperStyle}>
              {serachInput}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );

    let infoComponent = (
      <ul className="info-menu" key="infoComponent">
        <li>
          <InfoButton infoData={this.props.infoData} active={this.props.openMenu == 'info'} />
        </li>
      </ul>
    );

    return (
      // @ts-ignore TS2339
      <div className={classnames('secondary-navigation', { 'search-focused': this.state.searchBoxFocused })}>
        {/*
         // @ts-ignore TS2339 */}
        {this.props.mainMenuLinks ? (
          // @ts-ignore TS2339
          <div className="nav-element mainmenu-element">{this.props.mainMenuLinks}</div>
        ) : null}
        <div className="nav-element icon-element">
          {searchComponent}
          {infoComponent}
        </div>
        {userComponent}
      </div>
    );
  }
}

export default SecondaryNavigation;
