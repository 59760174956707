import React from 'react';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';

const EventDetails = props => {
  const metaDetails = [];
  let lists = [];
  let label = [];
  const locals = {
    casts: '出演',
    staffs: 'スタッフ',
    translators: '翻訳者',
    // programs: '作品データ',
    musics: '楽曲',
    producedYear: '制作年',
    producedCountry: '制作国',
    recording: '収録地・収録日',
    genres: 'ジャンル',
    ritem: '年齢制限',
  };
  // この順番
  ['casts', 'staffs', 'translators', 'musics', 'producedYear', 'producedCountry', 'recording', 'genres', 'ritem'].map(
    key => {
      // numberだとisEmptyはtrueが返ってきてしまうので
      if (!props || props[key] == undefined || (typeof props[key] !== 'number' && _.isEmpty(props[key]))) return;

      // 制作年に0が入ってくることがあるので弾く
      if (key === 'producedYear' && props[key] == 0) return;

      // スタッフ
      if (key === 'staffs') {
        let values = props[key] || [];
        if (Object.prototype.toString.call(values) !== '[object Array]') {
          values = [values];
        }

        const notices = _.uniq(
          _.map(values, val => {
            if (!_.get(val, 'notice')) {
              // なければ一旦スタッフで
              _.set(val, 'notice', locals[key]);
            }
            return val['notice'];
          }),
        );

        _.forEach(notices, notice => {
          label.push(
            <div className="listLabel" key={`meta_details_${key}_label`}>
              {notice}
            </div>,
          );
          // 同じ項目
          const staffs = _.filter(values, val => _.get(val, 'notice') == notice);

          _.forEach(staffs, (staff, idx) => {
            if (!_.get(staff, 'id')) {
              lists.push(
                <li key={`meta_details_${metaDetails.length}_${lists.length}_${idx}`} className="wide-list">
                  {staff}
                </li>,
              );
            } else {
              lists.push(
                <li className="list-link" key={`meta_details_${metaDetails.length}_${lists.length}_${staff.id}`}>
                  {/*
                   // @ts-expect-error TS2322 */}
                  <MainViewLink
                    // @ts-ignore TS2322
                    tabIndex={props.open ? 0 : -1}
                    to={routes.person}
                    params={{ id: staff.id }}
                    query={{ type: 'ev' }}
                  >
                    {staff.name}
                  </MainViewLink>
                </li>,
              );
            }
          });
          if (!_.isEmpty(lists)) {
            metaDetails.push(
              <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
                {label}
                <ul>{lists}</ul>
              </div>,
            );
          }
          lists = [];
          label = [];
        });
        // ジャンル
      } else if (key === 'genres') {
        if (Object.prototype.toString.call(props.genres) === '[object Array]' && props.genres.length > 0) {
          label.push(
            <div key={`meta_details_studio_label`} className="listLabel">
              ジャンル
            </div>,
          );
          (props.genres || []).map((genre, __) => {
            let linkProps = {
              to: routes.genre,
              params: { id: genre.id },
              query: { type: 'ev' },
            };
            if (genre.refId) {
              linkProps = {
                to: routes.genreRef,
                params: { id: genre.refId },
                query: { type: 'ev' },
              };
            }
            lists.push(
              <li key={`meta_details_${metaDetails.length}_${lists.length}_${genre.id}`}>
                {/*
                 // @ts-ignore TS2322 */}
                <MainViewLink {...linkProps} tabIndex={props.open ? 0 : -1}>
                  {genre.name}
                </MainViewLink>
              </li>,
            );
          });
          if (!_.isEmpty(lists)) {
            metaDetails.push(
              <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
                {label}
                <ul>{lists}</ul>
              </div>,
            );
          }
          lists = [];
          label = [];
        }
        // そのほか
      } else {
        label.push(
          <div className="listLabel" key={`meta_details_${key}_label`}>
            {locals[key]}
          </div>,
        );
        let values = props[key] || [];
        if (Object.prototype.toString.call(values) !== '[object Array]') {
          values = [values];
        }
        values.map((val, index) => {
          if (typeof val === 'string' || typeof val === 'number') {
            // @ts-ignore TS2339
            lists.push(<li key={`meta_details_${metaDetails.length}_${lists.length}_${val.id}`}>{val}</li>);
          } else {
            if (key == 'musics') {
              let performer = val.performer;
              if (performer && performer.length === 0) performer = null;
              if (performer) performer = `(${performer})`;
              lists.push(
                <li key={`meta_details_${metaDetails.length}_${lists.length}_${val.id}`}>
                  {val.title}
                  <span>{performer}</span>
                </li>,
              );
            } else {
              let notice = val.notice;
              if (notice && notice.length === 0) notice = null;
              if (notice) notice = `(${notice})`;
              lists.push(
                <li key={`meta_details_${metaDetails.length}_${lists.length}_${val.id}`}>
                  {/*
                   // @ts-expect-error TS2322 */}
                  <MainViewLink
                    // @ts-ignore TS2322
                    tabIndex={props.open ? 0 : -1}
                    to={routes.person}
                    params={{ id: val.id }}
                    query={{ type: 'ev' }}
                  >
                    {val.name}
                  </MainViewLink>
                  <span>{notice}</span>
                </li>,
              );
            }
          }
        });
        if (!_.isEmpty(lists)) {
          metaDetails.push(
            <div
              className={classnames('meta-details-item', { 'col-2': key == 'musics' })}
              key={`meta_details_${metaDetails.length}`}
            >
              {label}
              <ul>{lists}</ul>
            </div>,
          );
        }
        lists = [];
        label = [];
      }
    },
  );

  if (!_.isEmpty(props.copyrights)) {
    metaDetails.push(
      <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
        <ul key={'details_item_copyright'}>
          {_.map(props.copyrights, (copyright, idx) => (
            <li className="copyright" key={`copyright_${idx}`}>
              {copyright}
            </li>
          ))}
        </ul>
      </div>,
    );
  }
  // if (lists.length > 0) {
  //   metaDetails.push((
  //     <div className="meta-details-item" key={`meta_details_${metaDetails.length}`}>
  //       <ul>
  //         {lists}
  //       </ul>
  //     </div>
  //   ));
  // }

  const _handleSliderMove = e => {
    if (props.onSliderMove) {
      props.onSliderMove(e);
    }
  };

  return <div className="meta-details">{metaDetails}</div>;
};

export default EventDetails;
