import React, { useMemo } from 'react';
import classnames from 'classnames';
import MainViewLink from '../../../MainViewLink';
import { getHomeRoute } from 'src/apps/common/routes';

type WodLogoProps = {
  txtLogo?: boolean;
  isJoinCanvas?: boolean;
  isAccountLayout?: boolean;
  hideLogoLink?: boolean;
};

const WodLogo = (props: WodLogoProps) => {
  const showNoTxtLogo = useMemo(() => props.txtLogo, [props.txtLogo]);
  const showMypageLogo = useMemo(() => props.isAccountLayout && !showNoTxtLogo, [props.isAccountLayout, showNoTxtLogo]);
  const showWlogo = useMemo(() => props.isJoinCanvas && !showNoTxtLogo && !showMypageLogo, [
    props.isJoinCanvas,
    showMypageLogo,
    showNoTxtLogo,
  ]);
  return (
    <div
      className={classnames('logo', {
        no_txt: showNoTxtLogo,
        mypage: showMypageLogo,
        wlogo: showWlogo,
      })}
    >
      {props.isAccountLayout ? (
        props.hideLogoLink ? (
          <div className="img">WOWOW</div>
        ) : (
          // @ts-expect-error TS2322
          <MainViewLink className="img" href="https://www.wowow.co.jp/">
            WOWOW
          </MainViewLink>
        )
      ) : props.isJoinCanvas ? (
        //未ログインユーザーとアカウントあり（契約なし）のユーザーにのみ出すキャンバス + トップのみ
        // @ts-expect-error TS2322
        <MainViewLink className="img" resolver={getHomeRoute}>
          WOWOW
        </MainViewLink>
      ) : (
        // @ts-expect-error TS2322
        <MainViewLink className="img" resolver={getHomeRoute}>
          WOWOWオンデマンド
        </MainViewLink>
      )}
    </div>
  );
};

export default WodLogo;
