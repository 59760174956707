import React from 'react';
import _ from 'src/domain/libs/util';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PropTypes from 'prop-types';

import routes from '../routes';

const JsonLd = (props, context) => {
  const data = [];

  // BreadcrumbList
  if (props.breadcrumbList) {
    const host = context.getModelData('hosts', 'host');
    // @ts-ignore TS2554
    const url = routes.home.makePath();
    // 2階層のパンクズだと意図した結果にならないので、1階層目にホームを入れることで3階層にする
    const home = { name: 'ホーム', item: host + url };
    const itemListElement = [home].concat(props.breadcrumbList.itemListElement);
    data.push({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElement.map((item, idx) => {
        return {
          '@type': 'ListItem',
          position: idx + 1,
          name: item.name,
          item: item.item,
        };
      }),
    });
  }

  // Article
  if (props.article) {
    data.push({
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: props.article.headline,
      image: props.article.image,
    });
  }

  // VideoObject
  if (props.videoObject) {
    const convertDate = function(date) {
      if (date) {
        // クローラのローカル時間の影響を受けないようにするため
        // toISOStringだとローカル時間の影響を受けてしまうので直接置換してISO形式に変換する
        // 2023/01/12 00:00:00 の / を - に ` ` を T に +09:00 を付与
        return date.replace(/\//g, '-').replace(' ', 'T') + '+09:00';
      }
      return '';
    };

    const convertDuration = function(duration) {
      if (duration) {
        const episodeRunTime = parseInt(duration, 10);
        const seconds = episodeRunTime % 60 || 0;
        const minutes = ((episodeRunTime - seconds) / 60) % 60 || 0;
        // @ts-ignore TS2345
        const hour = parseInt(episodeRunTime / 3600, 10) || 0;
        let val = 'PT';
        if (hour) val = `${val}${hour}H`;
        if (minutes) val = `${val}${minutes}M`;
        if (seconds) val = `${val}${seconds}S`;
        return val;
      }
    };

    const convertPublication = function(publication) {
      if (publication) {
        return {
          '@type': 'BroadcastEvent',
          isLiveBroadcast: true,
          startDate: convertDate(publication.startDate),
          endDate: convertDate(publication.endDate),
        };
      }
    };

    data.push({
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: props.videoObject.name,
      description: props.videoObject.description.replace(/\r?\n/g, ''),
      thumbnailUrl: props.videoObject.thumbnailUrl,
      uploadDate: convertDate(props.videoObject.uploadDate),
      duration: convertDuration(props.videoObject.duration),
      expires: convertDate(props.videoObject.expires),
      publication: convertPublication(props.videoObject.publication),
      url: props.videoObject.url,
      // regionsAllowed: 'JP',
    });
  }

  if (_.isEmpty(data)) return null;
  return (
    <HelmetProvider>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(data)}</script>
      </Helmet>
    </HelmetProvider>
  );
};

JsonLd.contextTypes = {
  getModelData: PropTypes.func,
};

export default JsonLd;
