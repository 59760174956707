import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import PresTrackedElement from '../../../../common/components/PresTrackedElement';
import Rating from '../../../../common/components/Rating';
import routes from '../../../../common/routes';
import MainViewLink from '../../../../common/components/MainViewLink';
import EpgEvent from './EpgEvent';
import EventInfo from './EventInfo';
import BackgroundImage from '../../../../common/components/BackgroundImage';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../../common/GtmApp';
import SetDate from '../SetDate';

export default class ChannelCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    path.push(['id', 'refId', 'name', 'thumbnailUrl']);
    let paths = [path];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      paths = paths.concat(EventInfo.getPaths(models, options, Object.assign({}, props, { id: props.titleId })));
    }
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.titleId) return;
    // @ts-ignore TS2339
    return ['meta', props.titleId];
  };

  static get propTypes() {
    return {
      size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']).isRequired,
      sliderItemId: PropTypes.string,
      getRowHasPopOpen: PropTypes.func,
      isDoubleWide: PropTypes.bool,
      itemTabbable: PropTypes.bool,
      model: PropTypes.object.isRequired,
      rankNum: PropTypes.number,
      rowNum: PropTypes.number,
      titleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      popType: PropTypes.string.isRequired,
    };
  }

  static get defaultProps() {
    return {
      size: '1x1',
      ellipsized: false,
      rankNum: 0,
      rowNum: 0,
      popType: 'none',
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.array,
      rowNum: PropTypes.number,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      fetchDataError: null,
      generation: -1,
    };
    // @ts-ignore TS2339
    this.titleCardRef = React.createRef();
    this._handleLinkClick = this._handleLinkClick.bind(this);
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) {
      // TODO: 読み込み中
      return null;
    }

    // @ts-ignore TS2339
    const onairEvent = this.item.onair;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    // @ts-ignore TS2339
    const key = `title-card-${this.props.rowNum}-${this.props.rankNum}`;
    // @ts-ignore TS2339
    const tabIndex = this.props.itemTabbable ? 0 : -1;

    const getImage = () => {
      if (!onairEvent) {
        // @ts-ignore TS2339
        return this.item.thumbnailUrl;
      }
      if (onairEvent.thumbnailUrl) {
        return onairEvent.thumbnailUrl;
      }
      // @ts-ignore TS2339
      return this.item.thumbnailUrl;
    };

    // @ts-ignore TS2339
    const canWatchLink = (onairEvent && onairEvent.isOnAir && this.item.id) || this.props.forceWatchLink;
    // @ts-ignore TS2339
    const titleArtwork = (
      <BackgroundImage
        // @ts-ignore TS2322
        className={classnames('artwork', {
          // @ts-ignore TS2339
          'double-wide': this.props.isDoubleWide === true,
        })}
        url={getImage()}
      />
    );

    let tag = null;
    let cardBadge;
    if (onairEvent && onairEvent.isSuspend) {
      tag = null;
    } else if (onairEvent && onairEvent.isMask) {
      tag = '同時配信 対象外';
    } else if (onairEvent && onairEvent.isFirst) {
      tag = '初回';
    }
    if (tag) {
      cardBadge = <div className="card-badge">{tag}</div>;
    }

    let title;
    if (!onairEvent) {
      title = '現在未配信';
    } else if (onairEvent.isSuspend) {
      title = onairEvent.shortName || onairEvent.name;
    } else {
      title = onairEvent.shortName || onairEvent.name;
    }

    const getLink = child => {
      if (browserInfo.isIOS || browserInfo.isAndroid) return <p>{child}</p>;
      if (canWatchLink) {
        // @ts-ignore TS2339
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
        return (
          // @ts-expect-error TS2322
          <MainViewLink
            to={routes.simulcast}
            params={{ channelName: simulcast.name }}
            tabIndex={-1}
            onClick={this._handleLinkClick}
          >
            {child}
          </MainViewLink>
        );
      } else if (onairEvent && onairEvent.uniqueId) {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          item => item.refId == onairEvent.linearChannelMeta.refId,
        );
        return (
          // @ts-expect-error TS2322
          <MainViewLink
            // @ts-ignore TS2322
            to={routes.simulcastDetail}
            params={{ channelName: simulcast.name, uniqueId: onairEvent.uniqueId }}
            tabIndex={-1}
            onClick={this._handleLinkClick}
          >
            {child}
          </MainViewLink>
        );
      }
      return <p>{child}</p>;
    };

    let content = (
      <React.Fragment>
        <div
          className={classnames('sliderRefocus', {
            // @ts-ignore TS2339
            hasPop: this.state.hasPop === true,
            lockup: true,
          })}
          // @ts-ignore TS2339
          aria-label={this.item.name}
          // @ts-ignore TS2339
          ref={this.titleCardRef}
          role={'link'}
          tabIndex={tabIndex}
          key={key}
          id={key}
          onClick={this.handleClick.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        >
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {cardBadge}
            {titleArtwork}
            <div className="video-preload-title">
              <div className="video-preload-title-label">{_.get(onairEvent, 'name')}</div>
            </div>
          </PresTrackedElement>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.props.size === '3x2' || this.props.size === '3x1' ? (
          <div className="title-detail-card">
            <div className="title-detail-card-info">
              <div className="title">{_.get(onairEvent, 'shortName')}</div>
              <div className="synopsis">{_.get(onairEvent, 'synopsis')}</div>
            </div>
          </div>
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        <div className={classnames('title-card-channel')}>{getLink(this.item.name)}</div>
        {onairEvent ? (
          <div className="title-card-time">
            {/*
             // @ts-ignore TS2322 */}
            <SetDate date={onairEvent.startAt} format={'short'} /> - <SetDate date={onairEvent.endAt} format={'time'} />
            {onairEvent && onairEvent.ritem ? <span>{`・${onairEvent.ritem}`}</span> : null}
          </div>
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        <div className={classnames('title-card-title', { ellipsized: this.props.ellipsized })}>{getLink(title)}</div>
        {/*
         // @ts-ignore TS2339 */}
        {onairEvent && this.props.showSynopsis ? <div className="synopsis">{onairEvent.synopsis}</div> : null}
      </React.Fragment>
    );
    if (browserInfo.isTouchDevice) {
      if (canWatchLink) {
        // @ts-ignore TS2339
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
        return (
          // @ts-expect-error TS2322
          <MainViewLink
            // @ts-ignore TS2322
            to={routes.simulcast}
            params={{ channelName: simulcast.name }}
            tabIndex={-1}
            // @ts-ignore TS2339
            className={classnames('card', 'title-card', 'event-card', `size-${this.props.size}`)}
          >
            {content}
          </MainViewLink>
        );
      } else if (onairEvent && onairEvent.uniqueId) {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          item => item.refId == onairEvent.linearChannelMeta.refId,
        );
        return (
          // @ts-expect-error TS2322
          <MainViewLink
            // @ts-ignore TS2322
            to={routes.simulcastDetail}
            params={{ channelName: simulcast.name, uniqueId: onairEvent.uniqueId }}
            tabIndex={-1}
            // @ts-ignore TS2339
            className={classnames('card', 'title-card', 'event-card', `size-${this.props.size}`)}
          >
            {content}
          </MainViewLink>
        );
      } else {
        return (
          <div
            // @ts-ignore TS2339
            className={classnames('card', 'title-card', 'event-card', `size-${this.props.size}`, {
              nohover: browserInfo.isTouchDevice,
            })}
          >
            {content}
          </div>
        );
      }
    } else {
      // @ts-ignore TS2339
      return <div className={classnames('card', 'title-card', 'event-card', `size-${this.props.size}`)}>{content}</div>;
    }
  }

  handleClickPlayButton(e) {
    e.stopPropagation();
    this.handleClick(e);
  }

  handleClick(e) {
    e.preventDefault();
    // @ts-ignore TS2339
    const onairEvent = _.get(this.item, 'onair');
    this.sendToGtm(onairEvent);
    // @ts-ignore TS2339
    if (this.item && onairEvent) {
      let to;
      let targetRegExp;
      if (_.get(onairEvent, 'isOnAir')) {
        // @ts-ignore TS2339
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
        // @ts-ignore TS2554
        to = routes.simulcast.makePath({ channelName: simulcast.name });
        targetRegExp = routes.simulcast._regex;
      } else {
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          item => item.refId == onairEvent.linearChannelMeta.refId,
        );
        // @ts-ignore TS2554
        to = routes.simulcastDetail.makePath({ channelName: simulcast.name, uniqueId: onairEvent.uniqueId });
        targetRegExp = routes.simulcastDetail._regex;
      }
      const routesMatchFlag =
        // @ts-expect-error TS2339
        !!this.context.routeHandlers &&
        // @ts-expect-error TS2339
        _.some(this.context.routeHandlers, handler => handler.route._regex === targetRegExp);
      if (routesMatchFlag) {
        // @ts-expect-error TS2339
        this.context.history.push(to);
      } else {
        window.location.href = to;
      }
    }
  }

  _handleLinkClick() {
    // @ts-ignore TS2339
    const onairEvent = _.get(this.item, 'onair');
    this.sendToGtm(onairEvent);
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  sendToGtm(eventMeta) {
    if (!_.get(this.context, 'gtmApp')) return;
    const item = eventMeta;
    const channel = _.get(item, 'linearChannelMeta');
    const content = {
      refId: _.get(item, 'uniqueId'),
      name: _.get(item, 'name'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { content, channel, attributes, genres, middleGenres, schemaId },
      { clickArea: CLICK_AREA.THUMBNAIL },
    );
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        this.item.onair = EpgEvent.additionalData(this.item.onair);
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }
}
