import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppContextProvider extends Component {

  static get propTypes() {
    return {
      appContext: PropTypes.object.isRequired,
      children: PropTypes.object
    }
  }

  static get childContextTypes() {
    return {
      ab: PropTypes.object,
      cookies: PropTypes.object,
      falcorModel: PropTypes.object,
      getModelData: PropTypes.func,
      getCookieDough: PropTypes.func,
      shiftFlashMessage: PropTypes.func,
      history: PropTypes.object,
      logger: PropTypes.object,
      models: PropTypes.object,
      getICUString: PropTypes.func,
      getI18nString: PropTypes.func,
      getI18nBundle: PropTypes.func,
      provideAppContextToElement: PropTypes.func,
      routeHandler: PropTypes.object,
      renderTracker: PropTypes.object,
      discoveryApp: PropTypes.object
    }
  }

  getChildContext() {
    // @ts-ignore TS2339
    const context = this.props.appContext;
    return {
      ab: context.getAB(),
      cookies: context.getCookieDough(),
      falcorModel: context.getFalcorModel(),
      getModelData: context.getModelData.bind(context),
      getCookieDough: context.getCookieDough.bind(context),
      shiftFlashMessage: context.shiftFlashMessage.bind(context),
      history: context.getHistory(),
      logger: context.getLogger(),
      models: context.getModels(),
      getICUString: context.getICUString.bind(context),
      getI18nString: context.getI18nString.bind(context),
      getI18nBundle: context.getI18nBundle.bind(context),
      provideAppContextToElement: context.provideAppContextToElement.bind(context),
      routeHandler: context.getRouteHandler(),
      renderTracker: context.getRenderTracker(),
      discoveryApp: context.getDiscoveryApp()
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return typeof nextProps.appContext.getState === 'function';
  }

  render() {
    // @ts-expect-error TS2339
    if (!this.props.children) return null;

    // @ts-ignore TS2769
    return React.cloneElement(this.props.children, this.props.appContext.getState());
  }
}

// @ts-ignore TS2339
AppContextProvider.displayName = function() {
  return 'AppContextProvider';
};

export default AppContextProvider;
