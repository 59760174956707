import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import LiveCard from 'src/apps/bluerose/components/browse/LiveCard';
import LoadingTitle from 'src/apps/bluerose/components/loader/LoadingTitle';
import { CardContextProvider } from 'src/apps/common/context/CardContext';
import Row from 'src/apps/bluerose/components/browse/Row';
import { getIsLiveOnAir } from 'src/apps/common/utils/liveUtil';

export default class LiveList extends Component {
  static get propTypes() {
    return {
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.array,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }
  handleSliderMove(e) {
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove(e);
    }
  }
  render() {
    // @ts-ignore TS2339
    if (!this.props.items || this.props.items.length === 0) {
      return null;
    }
    // @ts-ignore TS2339
    const items = this.props.items;
    const scheduleTitles = [];
    const titles = [];
    _.forEach(items, (item, index) => {
      if (item.cardInfo && (item.cardInfo.broadcastStartAt || item.cardInfo.broadcastEndAt)) {
        if (getIsLiveOnAir(item.cardInfo.broadcastStartAt, item.cardInfo.broadcastEndAt)) {
          //ライブ配信中のもの
          titles.push(
            <LiveCard
              // @ts-ignore TS2322
              popType={'none'}
              // @ts-ignore TS2339
              model={this.props.model}
              key={`title_${item.id}_${index}`}
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              titleId={item.id}
              itemData={item}
              // listCard={this.palette.viewType == 'list'}
              // isMediaOnly={_.get(this.palette, 'cardTitleDisplaySetting') === 'media_only' ? 'media-only' : null} // SpSliderのrenderChildrenメソッドで利用
              titleDisplaySetting={'default'}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
            />,
          );
        } else {
          //ライブ配信中じゃないライブ
          scheduleTitles.push(
            <LiveCard
              // @ts-ignore TS2322
              popType={'none'}
              // @ts-ignore TS2339
              model={this.props.model}
              key={`title_${item.id}_${index}`}
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              titleId={item.id}
              itemData={item}
              // listCard={this.palette.viewType == 'list'}
              // isMediaOnly={_.get(this.palette, 'cardTitleDisplaySetting') === 'media_only' ? 'media-only' : null} // SpSliderのrenderChildrenメソッドで利用
              titleDisplaySetting={'default'}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
            />,
          );
        }
      }
    });

    let totalItems, title, cardTitles;
    if (titles.length > 0) {
      title = 'ライブ配信中';
      totalItems = titles.length;
      cardTitles = titles;
    } else if (scheduleTitles.length > 0) {
      title = '今後の予定';
      totalItems = scheduleTitles.length;
      cardTitles = scheduleTitles;
    }
    return (
      <React.Fragment>
        <div className="live-menu-cont__title">{title}</div>
        <div className={'canvas-row'} data-list-context={'editorial'}>
          <CardContextProvider value={this.context}>
            <Row
              totalItems={totalItems}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              handleSliderMove={this.handleSliderMove.bind(this)}
              // @ts-ignore TS2322
              listContext={'editorial'}
              loadingComponent={<LoadingTitle />}
              enablePaginationIndicator={false}
              enablePeek={true}
              enablePushOut={false}
            >
              {cardTitles}
            </Row>
          </CardContextProvider>
        </div>
      </React.Fragment>
    );
  }
}
