import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import _ from 'src/domain/libs/util';
import notificationDataStore from 'src/utils/notificationDataStore';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import routes from 'src/apps/common/routes';
import { ACTIVE_MENU, InfoData as InfoType } from 'src/apps/common/components/GlobalMenu';
import { MENU_CATEGORY } from 'src/common/GtmApp';

type InfoButtonProps = {
  onClick?: (e: React.MouseEvent) => void;
  infoData: InfoType[];
  active?: boolean;
  className?: string;
};

const InfoButton = (props: InfoButtonProps, context) => {
  const [existsNewInfo, setExsistsNewInfo] = useState(false);
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      context.gtmApp.pushDataLayerOnMenuClick(MENU_CATEGORY.INFO);
      if (existsNewInfo) {
        // 最新の日時を抽出
        let newDate = _.max(
          _.map(props.infoData, link => {
            return link.validityStartAtTime;
          }),
        );
        // ない場合は現在日時
        if (!newDate) newDate = new Date().getTime();
        const profileData = _.get(context.getModelData('memberContext'), 'profileData');
        const id = _.get(profileData, 'active.id');
        notificationDataStore.set(newDate, id);
        setExsistsNewInfo(false);
      }
      if (props.onClick) {
        onClick(e);
      }
    },
    [context, existsNewInfo, props.infoData, props.onClick],
  );
  const checkExsitsNewInfo = useCallback(() => {
    // new バッジ
    // プロフィール毎にlocalStorageにもつ notification:12345
    // 未ログインの場合は notification のみ、最新の日時を抽出
    const newDate = _.max(_.map(props.infoData, link => link.validityStartAtTime));
    const profileData = _.get(context.getModelData('memberContext'), 'profileData');
    const id = _.get(profileData, 'active.id');
    setExsistsNewInfo(notificationDataStore.isNew(newDate, id));
  }, [context, props.infoData]);

  useEffect(() => {
    checkExsitsNewInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // @ts-expect-error TS2322
    <MainViewLink
      to={routes.info}
      className={classnames('open-menu info-menu-open', { [ACTIVE_MENU]: props.active }, props.className)}
      name="info"
      aria-label="お知らせ"
      role="button"
      onClick={onClick}
    >
      <span className={classnames('fa fa-bell-line', { new: existsNewInfo })}></span>
    </MainViewLink>
  );
};

InfoButton.contextTypes = {
  getModelData: PropTypes.func,
  gtmApp: PropTypes.object,
  routeHandler: PropTypes.object,
  models: PropTypes.object,
};

export default InfoButton;
